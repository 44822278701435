<template src="./templates/button.html"></template>
<script>
import helpers from '@/tools/helpers';

const default_values = {
    id: null,
    class: '',
    href: null,
};

export default {
    name: 'FormButton',
    props: {
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        type: {
            type: String,
            default() {
                return 'button';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    emits: ['click'],
    computed: {
        attributesPrep() {
            const attrs = {...default_values, ...helpers.deepCopy(this.attributes)};

            if (typeof attrs.class === 'string') {
                attrs.class = attrs.class.split(' ');
            }

            // Prepare classes
            attrs.class.push('btn');

            if (this.type === 'a' && (typeof attrs.href === 'undefined')) {
                attrs.href = 'javascript:void(0);';
            }

            if (!attrs.id) {
                attrs.id = `form-button-${Math.floor(Math.random() * 100000)}`;
            }

            if (this.disabled) {
                attrs.disabled = true;
                attrs.class.push('btn-gray');
                attrs.class.push('btn-nohover');
                attrs.class.push('text-gray');
                attrs.class.push('cursor-not-allowed');
            }

            attrs.class = attrs.class.join(' ');

            return attrs;
        },
        tag() {
            return this.type;
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        }
    }
};
</script>
