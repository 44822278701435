<FormInput
    v-model="filterValue"
    :attributes="{placeholder:'Поиск ТТХ'}"
    ref="features-search-form-input"
/>
<div v-if="productCategories.length > 1" class="p-2">
    <FormRadio
        v-model="showCommonFeatures"
        :value="1"
        label="Общие"
        name="productCategories"
        wrapper-class="inline"
    />
    <FormRadio
        v-for="(v) in productCategories"
        :label="v.title"
        name="productCategories"
        :value="v.value"
        v-model="category"
        wrapper-class="inline"
    />
</div>
