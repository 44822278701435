<template src="../../templates/partner-cart-contact-actions.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'ContactActions',
    components: {
        FormButton,
    },
    props: {
        actions: {
            type: Object,
            default() {
                return {};
            }
        },
        onAction: {
            type: Function,
            default() {
                return () => {};
            }
        }
    },
};
</script>
