<div class="tabs container">
    <div :class="'tabs-header row pr-1 pv-1' + headerClassPrep">
        <template v-for="(tab, num) in columns">
            <span class="col-auto m-0 mr-1 p-0">
            <slot v-if="$slots.tab" name="tab" :data="{tab, selected, num}" />
            <template v-else>
                <FormButton
                    :label="tab.label"
                    :attributes="{
                        class: ' ' + ((selectedTab===tab.id || (!selectedTab && num === 0)) ? 'btn-gray' : 'btn-white text-gray')
                    }"
                    @click="selectTab(tab.id)"
                    :style="'width:' + tab.width"
                />
            </template>
            </span>
        </template>
    </div>

    <div :class="'tabs-content row pr-1 pv-1' + contentClassPrep">
        <template v-for="(tab, num) in columns">
            <div class="col-auto p-0" v-if="selectedTab===tab.id || (!selectedTab && num === 0)">
                <slot v-if="tab.slot_name" :name="tab.slot_name" :data="data[tab.id]"></slot>
                <div v-else v-html="format(tab.id, data[tab.id].content)"></div>
            </div>
        </template>
    </div>
</div>
