import api from '../tools/api';

export const getPartnersApi = (value = '', field = '', externalParams = []) => api.request(
    'partners',
    'get-partners',
    {value, field, params: externalParams}
);

export const getPartnerApi = (
    options,
    successHandler = () => {},
    errorHandler = () => {}
    ) => api.request(
    'partners',
    'get-partner',
    options,
    {}
).then(successHandler).catch(errorHandler);

// Find
export const getAreasApi = (value = '', field = '') => api.request(
    'partners',
    'find-areas',
    {value, field}
);

// Really getter
export const getAreas = options => api.request(
    'partners',
    'get-areas',
	options
);

export const getPartnerTypes = options => api.request(
    'partners',
    'get-partner-types',
	options
);

export const getPartnerLegalTypes = options => api.request(
    'partners',
    'get-partner-legal-types',
	options
);

export const getContactApi = (
	id,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
    'partners',
    'get-partners-contact',
    {id},
	{}
).then(successHandler).catch(errorHandler);

export const getContactTypes = (
		successHandler = () => {}
	) => api
	.request(
    'partners',
    'get-contact-types',
    {},
	{},
	{
		resolve: successHandler,
	}
);


export const getAddresses = (
	partner_id,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'partners',
		'get-addresses',
		{partner_id},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getScoring = (
	partner_id,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'partners',
		'get-scoring',
		{partner_id},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getPartnerAddressTypes = (
		successHandler = () => {}
	) => api
	.request(
	'partners',
	'get-partner-address-types',
	{},
	{},
	{
		resolve: successHandler,
	}
);

export const getAddressTypes = (
		successHandler = () => {}
	) => api
	.request(
	'partners',
	'get-address-types',
	{},
	{},
	{
		resolve: successHandler,
	}
);

export const getContactPositions = (
		successHandler = () => {}
	) => api
	.request(
    'partners',
    'get-contact-positions',
    {},
	{},
	{
		resolve: successHandler,
	}
);

export const getScoringTranscription = (
		successHandler = () => {}
	) => api
	.request(
    'partners',
    'get-scoring-transcription',
    {},
	{},
	{
		resolve: successHandler,
	}
);

export const findPartners = (q, options) => api.request(
	'partners',
	'find-partners',
	{q, options}
);

export const getPartnerDetails = (
	id,
	successHandler = r => r,
	errorHandler = () => {}
) => api
	.request(
		'partners',
		'get-partner-details',
		{id},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const savePartner = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'partners',
		'save-partner',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getClassifier = (
	partnerId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'partners',
		'get-classifier',
		{partnerId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getClassifierItemTree = (
	successHandler = () => {},
	errorHandler = () => {}
) => api.request(
	'partners',
	'get-classifier-item-tree',
	{},
	{},
	{
		resolve: successHandler,
		reject: errorHandler,
	}
);
