<div>
    <div class="properties-filter row m-2">
        <Checkbox v-model="filters.positive" label="С положительной оценкой" />
        <Checkbox v-model="filters.probable" label="Вероятные" />
        <Checkbox v-model="filters.negative" label="С отрицательной оценкой" />
        <Checkbox v-model="filters.empty" label="Пустые вычисляемые" />
    </div>

    <VariableTree :filter-func="filterFunc" ref="varTree">
        <template v-slot:line="row">
            <div class="variable-actions">
                <div @click="viewDetails(row.data.id)" class="pointer">
                    <span :class="getProbabilityClass(properties[row.data.id], row.data.type)">
                        {{ getPropertyLabel(properties[row.data.id], row.data.type) }}
                    </span>
<!--                    {{properties[row.data.id]?.variable_value}}-->
                </div>
            </div>
        </template>
    </VariableTree>
</div>

<teleport to="body" v-if="showConditionsModal">
    <Modal
        @onOk="showConditionsModal = false"
        @onClose="showConditionsModal = false"
    >
        <div class="modal-section">
            <ConditionEditor
                :items="conditions"
                :condition-property-source="`PARTNER`"
                disabled
            >
                <template v-slot:extraPoolData="pool">
                    <div :class="`point-mark pool ${getPointClass(conditionsPoints.pools[pool.data.id]?.points)}`">
                        {{conditionsPoints.pools[pool.data.id]?.points}}
                    </div>
                </template>
                <template v-slot:extraConditionData="condition">
                    <div :class="`
                        point-mark
                        condition
                        ${getPointClass(conditionsPoints.conditions[condition.data.id]?.points?.value)}
                    `">
                        {{conditionsPoints.conditions[condition.data.id]?.points?.value}}
                    </div>
                </template>
            </ConditionEditor>
        </div>
        <div class="modal-section">
            <FormButton
                type="a"
                label="Редактировать свойство"
                @click="showEditVariableModal = true"
                :attributes="{class:['text-blue', 'btn-link', 'mt-2', 'no-bg' ,'pl-0']}"
            />
        </div>
    </Modal>
</teleport>

<teleport to="body" v-if="showEditVariableModal">
    <modal
        @onOk="closeEditVariableModal"
        @onClose="closeEditVariableModal"
    >
        <EditVariable
            ref="editVariable"
            :data="editVariableData"
            :on-save="saveVariable"
            :on-cancel="closeEditVariableModal"
        />
    </modal>
</teleport>

<teleport to="body" v-if="showConfirmModal">
    <Confirm
        v-model:show-modal="showConfirmModal"
        :title="confirmBts.title"
        :buttons="confirmBts.buttons"
    />
</teleport>
