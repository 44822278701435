<div>
    <div class="border-dashed mb-2 p-2">
        <Loader v-if="loading" />
        <template v-else>

            <template v-if="mode === 'display'">
                <Error v-if="!technologyPark.length" message="Мехдвор не заполнен." />
                <div v-else class="mb-2">
                    <div class="partner-title">Дата последнего обновления: {{ lastUpdateDate }}</div>
                </div>
            </template>

            <div v-if="mode === 'edit' || mode==='add'" class="mb-2">
                <TechnologyParkAdd
                    :technology-park="technologyPark"
                    @add="addItems"
                />
            </div>

            <div v-if="technologyPark.length" class="d-flex mb-2">
                <div class="pointer mr-2" @click="showHideAll(true)">
                    <div class="d-flex align-items-center">
                        <span class="mr-1">Развернуть все</span>
                        <i class="icon chevron chevron_rotate-down bg-black" />
                    </div>
                </div>
                <div class="pointer mr-1" @click="showHideAll(false)">
                    <div class="d-flex align-items-center">
                        <span class="mr-1">Свернуть все</span>
                        <i class="icon chevron chevron_rotate-top bg-black" />
                    </div>
                </div>
            </div>

            <TransitionGroup name="technology-park__list">
                <ExpansionItem
                    v-for="category in categories"
                    :key="category.id"
                    v-model="categoriesShowing[category.id]"
                    :label="getCategory(category.id)?.name"
                    :label-right="`${category.count} шт.`"
                    icon="folder"
                    class="mb-1"
                >
                    <TransitionGroup name="technology-park__list">
                        <ExpansionItem
                            v-for="modelLine in category.modelLines"
                            :key="modelLine.id"
                            v-model="modelLinesShowing[modelLine.id]"
                            :label="getModelLine(modelLine.id)?.name"
                            :label-right="`${modelLine.count} шт.`"
                            class="mt-1"
                            header-class="bg-light-green"
                            expand-icon="plus-two-ton"
                            expanded-icon="minus-two-ton"
                        >
                            <TransitionGroup name="technology-park__list">
                                <template v-for="(item, index) in modelLine.items" :key="index">
                                    <TechnologyParkItem
                                        v-if="mode === 'display'"
                                        class="mt-1"
                                        :additional-data="additionalData"
                                        :item="item"
                                        :mode="mode"
                                    />
                                    <TechnologyParkItem
                                        v-if="mode === 'edit' || mode==='add'"
                                        class="mt-1"
                                        :id="getItemSelector(item)"
                                        :additional-data="additionalData"
                                        v-model:item="modelLine.items[index]"
                                        v-model:componentErrors="componentErrors[`${modelLine.id}_${index}`]"
                                        :mode="mode"
                                        :technologyPark="technologyPark"
                                        @delete="deleteItem"
                                    />
                                </template>
                            </TransitionGroup>
                        </ExpansionItem>
                    </TransitionGroup>
                </ExpansionItem>
            </TransitionGroup>

            <div v-if="partnerId" class="mt-2">
                <TechnologyParkRecommended
                    :partner-id="partnerId"
                    :technology-park="technologyPark"
                    :mode="mode"
                    @add="addItems"
                />
            </div>

        </template>
    </div>
</div>
