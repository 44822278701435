<template src="./templates/loginform.html"></template>
<script>

import Input from '@f/Input';
import Button from '@f/Button';
import {getLeftMenu} from '@api/layout';
import {userLogin} from '@api/user';
import {defaultRoute} from '@/router/config';

export default {
    name: 'LoginForm',
    components: {
        Input,
        Button
    },
    data() {
        return {
            error: null,
        };
    },
    methods: {
        updateMenu(menu_items) {
            this.$store.dispatch('updateMenu', menu_items);
        },
        login(event) {

            event.preventDefault();
            userLogin(
                Object.fromEntries(new FormData(document.getElementById('login-form'))),
                response => {
                    this.$store.dispatch('updateUserKey', response);
                    window.localStorage.setItem('user-key', response);
                    this.$store.dispatch('initBranchList');
                    this.$router.push(this.$route.query.redirect || defaultRoute);
                    getLeftMenu().then(menuItems => {
                        this.updateMenu(menuItems);
                    });
                },
                (e) => {
                    this.error = 'Некорректный логин или пароль';
                    console.error(e);
                }
            );
        }
    },
};
</script>
