<template src="./templates/input.html"></template>
<script>

import Errors from '@c/Error';
import helpers from '@/tools/helpers';
import Help from '@c/Help';

const default_values = {
    id: null,
    type: 'text',
    class: '',
};

export default {
    name: 'FormInput',
    components: {
        Errors,
        Help,
    },
    props: {
        preloader: {
            type: Boolean,
            default() {
                return false;
            },
        },
        placeholder: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            },
        },
        label: {
            type: String,
            default() {
                return null;
            },
        },
        groupClass: {
            type: String,
            default() {
                return '';
            },
        },
        labelClass: {
            type: String,
            default() {
                return '';
            },
        },
        type: {
            type: String,
            default() {
                return null;
            },
        },
        step: {
            type: String,
            default() {
                return null;
            },
        },
        required: {
            type: Boolean,
            default() {
                return false;
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        maxlength: {
            type: Number,
            default() {
                return null;
            }
        },
        errors: {
            type: Array,
            default() {
                return [];
            },
        },
        helpText: {
            type: String,
            default() {
                return '';
            },
        },
    },
    emits: ['update:modelValue', 'update:errors'],
    data() {
        return {
            typeRules: {
                tel: {
                    pattern: '^[+]?[7]?[ \\(]*[0-9]{3}[\\) ]*[0-9]{3}[\\-]?[0-9]{2}[\\-]?[0-9]{2,3}$',
                    placeholder: '+7 (XXX) XXXXXXX',
                    autocomplete: false,
                    errorMsg: 'Требуемый формат телефона: +7 (XXX) XXX-XX-XX'
                },
                email: {
                    pattern: '^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$',
                    placeholder: 'aaaa@bbbb.ru',
                    autocomplete: false,
                    errorMsg: 'Введите корректный емейл'
                }
            },
        };
    },
    computed: {
        labelClassPrep() {
            const cl = `${(this.labelClass) ? `${this.labelClass} ` : ''}`;
            return `${cl}h6`;
        },
        attributesPrep() {

            let attrs = {
                ...default_values,
                ...this.attributes,
                ...(this.type !== null ? {type: this.type} : {}),
                ...(this.step !== null ? {step: this.step} : {}),
            };

            if (this.placeholder) {
                attrs = {
                    ...attrs,
                    ...{placeholder: this.placeholder}
                };
            }

            if (!attrs.type) {
                attrs.type = 'text';
            }
            if (this.required) {
                attrs.required = true;
            }

            if (typeof this.typeRules[attrs.type] !== 'undefined') {
                attrs = {
                    ...{
                        pattern: this.typeRules[attrs.type].pattern,
                        placeholder: this.typeRules[attrs.type].placeholder,
                        autocomplete: this.typeRules[attrs.type].autocomplete,
                    },
                    ...attrs,
                };
            }

            if (!attrs.id) {
                attrs.id = `form-input-${Math.floor(Math.random() * 100000)}`;
            }

            return attrs;
        },
    },
    watch: {
        type() {

            this.triggerInput();
        }
    },
    mounted() {
        this.triggerInput();
    },
    methods: {
        triggerInput() {
            const t = setTimeout(() => {
                clearTimeout(t);
                this.$refs.inputElement.dispatchEvent(new Event('input'));
            }, 30);
        },
        input(e) {

            const {value} = e.target;
            const errors = [];

            if (e.target.validity.patternMismatch) {
                if (typeof this.typeRules[this.attributesPrep.type] !== 'undefined') {
                    errors.push(
                        this.typeRules[this.attributesPrep.type].errorMsg
                    );
                }
            }

            if (this.required && value === '') {
                errors.push('Обязательное поле');
            }

            if (this.maxlength && value.length > this.maxlength) {
                const symbol = helpers.wordAfterNumberDeclension(
                    this.maxlength,
                    ['символ', 'символа', 'символов']
                );
                errors.push(`Можно ввести не более  ${this.maxlength} ${symbol}`);
            }

            this.$emit('update:errors', errors);
            this.$emit('update:modelValue', value);
        },
        setFocus() {
            this.$refs.inputElement.focus();
        },
    }
};
</script>
