<template src="../templates/edit-variable.html"></template>
<script>

import Modal from '@c/Modal';
import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import FormTextarea from '@f/Textarea';
import FormRadio from '@f/Radio';
import Tabs from '@c/Tabs';
import VariableTree from '@c/Scenarios/VariableTree';
import ConditionEditor from '@c/ConditionEditor';

import {
    getVariables
} from '@api/scenarios';

import helpers from '@/tools/helpers';
import {
    LIVETIME_TYPES,
    VALUE_TYPE_STRING,
    VALUE_TYPES,
    VARIABLE_TYPES,
    VARIABLE_TYPE_CONSTANT,
    VARIABLE_TYPE_FORMULA,
    VARIABLE_TYPE_CONDITIONS
} from '@page/VariableEditor/config';
import VariableTreeSelectDialog from '@c/Scenarios/VariableTreeSelectDialog';
import {watchModifyMixin} from '@/mixins/watchModifyMixin';
import EditValue from './edit-variable-value';
import Classifier from './datagenerators/classifier';

const defaultValues = {
    name: '',
    type: 'CONSTANT',
    description: '',
    livetime: null,
    livetime_type: 'DAYS',
    value_type: VALUE_TYPE_STRING,
    values: [],
    conditions: [],
    settings: {
        dataGenerators: {
            classifier: {
                status: 0,
                changeCategory: null,
            },
        },
    },
    pools: [],
};

export default {
    name: 'EditVariable',
    components: {
        Modal,
        Tabs,
        FormInput,
        FormTextarea,
        FormCheckbox,
        FormButton,
        FormSelect,
        FormRadio,
        EditValue,
        VariableTree,
        ConditionEditor,
        Classifier,
        VariableTreeSelectDialog,
    },
    mixins: [
        watchModifyMixin('editData', 'editDataInitial'),
    ],
    props: {
        id: {
            type: Number,
            default() { return null; }
        },
        data: {
            type: Object,
            default() {
                return helpers.deepCopy(defaultValues);
            }
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            editData: {
                ...helpers.deepCopy(defaultValues),
                ...this.data,
            },
            editDataInitial: {
                ...helpers.deepCopy(defaultValues),
                ...helpers.deepCopy(this.data),
            },
            showParentCategoryChangePopup: false,
            selectedTab: '',
            constants: Object.freeze({
                VALUE_TYPES,
                LIVETIME_TYPES,
                VARIABLE_TYPE_CONSTANT,
                VARIABLE_TYPE_CONDITIONS,
                VARIABLE_TYPE_FORMULA,
                VARIABLE_TYPES,
            }),

            selectedParentCategoryTitle: '',
            cacheCategories: {},
            additionalSettings: 0,
            errors: {
                editValueErrors: [],
            },
        };
    },
    computed: {
        validated() {
            return !!this.editData.name
                && this.editData.name.length >= 3
                && this.errors.editValueErrors.length === 0;
        },
    },
    watch: {
        'editData.parent_id'(parentId) {
            this.setParentCategoryTitle(parentId);
        },
        'editData.type'(type) {
            if (type !== this.constants.VARIABLE_TYPE_CONSTANT) {
                this.errors.editValueErrors = [];
            }
        },
    },
    created() {
        this.setParentCategoryTitle(this.editData.parent_id);
        this.selectedTab = this.constants.VARIABLE_TYPES[this.editData.type];
    },
    methods: {
        setParentCategoryTitle(parentId) {
            let title = 'Коренная';
            if (parseInt(`${parentId}`, 10) !== 0) {
                if (typeof this.cacheCategories[parentId] === 'undefined') {
                    getVariables(
                        {
                            id: parentId,
                            limit: 1,
                        },
                        r => {
                            this.cacheCategories[parentId] = r[parentId];
                            this.selectedParentCategoryTitle = this.cacheCategories[parentId].name;
                        }
                    );
                } else {
                    title = this.cacheCategories[parentId].name;
                }
            }
            this.selectedParentCategoryTitle = title;
        },
        changeTab(tabId) {
            this.selectedTab = tabId;

            const typeKey = Object.keys(this.constants.VARIABLE_TYPES).find(
                key => this.constants.VARIABLE_TYPES[key] === tabId
            );
            this.editData.type = typeKey;

            const ch = this.$refs.variableType;
            ch.selectTab(tabId);
        },
        save() {
            this.onSave(this.editData);
        },
    }
};
</script>
<style src="../styles/edit-variable.scss" lang="scss"></style>
