<component
    :is="tag"
    class="grid-cell"
    :width="width"
>
    <slot></slot>
    <Button v-if="linkButtons" v-for="question in questions" @click="callEvent(question, 'onLink', $event)"
            label="✎"></Button>
    <Button v-if="buttonEdit" @click="callEvent(id, 'onEdit', $event)" label="✎"></Button>
    <Button v-if="buttonRemove" @click="callEvent(id, 'onRemove', $event)" label="❌"></Button>
</component>
