<template src="../../templates/partner-cart.html"></template>
<script>

import {getVariablesPoints, getVariablesValues} from '@api/scenarios';
import Tabs from '@c/Tabs';
import helpers from '@/tools/helpers';
import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';
import {
    getContactApi,
    getPartnerDetails,
    getContactTypes,
    getContactPositions,
    savePartner,
    getPartnerAddressTypes,
    getAddressTypes,
    getAddresses,
    getScoring,
    getScoringTranscription,
    findPartners,
    getAreas,
    getClassifier
} from '@api/partners';
import {getTechnologyPark} from '@api/technology-park';

import Error from '@c/Error';
import Details from './details';
import Contacts from './contacts';
import Classifier from './classifier';
import Addresses from './addresses';
import Scoring from './scoring';
import PromptPartners from './prompt-partners';
import LegalDetails from './legal-details';
import TechnologyPark from './technology-park';
import Properties from './properties';
import Scenarios from './scenarios';

const errorsTemplate = {
    validation: {
        details: {},
        contacts: {},
        technologyPark: {},
    }
};

export default {
    name: 'PartnerCart',
    components: {
        FormInput,
        FormCheckbox,
        FormButton,
        Tabs,
        Details,
        Contacts,
        Classifier,
        Addresses,
        LegalDetails,
        Scoring,
        TechnologyPark,
        Error,
        PromptPartners,
        Properties,
        Scenarios,
    },
    props: {
        id: {
            type: [Number, String],
            default() {
                return null;
            }
        },
        globalMode: {
            type: String,
            default() {
                return 'display';
            }
        },
        /*
            Кэлбэки для вывода в карте контрагента.
            Вида: {client: {questionnaire: {title: 'Начать опрос', fn: (partner, additionalData = {})=>{}}}}
        */
        actions: {
            type: Object,
            default() {
                return {};
            }
        },
        onCancel: {
            type: Function,
            default() {
                return () => {};
            }
        },
    },
    emits: ['update:globalMode', 'update:id'],
    data() {
        return {
            details: {name: '', itn: ''},
            contacts: [],
            detailsForEdit: [],
            contactsForEdit: [],
            addressesForEdit: [],
            legalDetails: {
                kpp: null,
                okpo: null,
                okato: null,
                ogrn: null,
                nds: null,
            },
            legalDetailsForEdit: {},
            contactTypes: null,
            contactPositions: null,
            preloader: false,
            mode: 'display', // display | edit | add
            addresses: [],
            addressTypes: null,
            partnerAddressTypes: null,
            scoring: [],
            scoringTranscription: [],
            classifier: [],
            classifierForEdit: [],
            technologyPark: [],
            variablesValues: {},
            variablesPoints: {},
            technologyParkForEdit: [],
            validated: false,
            errors: {...errorsTemplate},
            promptPartners: [],
            displayPartnerPrompt: true,
            findHandler: helpers.debounceBuffer(
                async (q, options) => {
                    this.promptPartners = await findPartners(q, options);
                },
                500
            ),
        };
    },
    computed: {
        partner() {
            return this.details;
        },
    },
    watch: {
        id() {
            this.initPartner(this.id);
        },
        errors: {
            handler(nErrors) {
                if (nErrors.validation) {
                    this.validated = Object.values(nErrors.validation).filter(e => Object.values(e).length > 0).length === 0;
                }
            },
            deep: true
        },
        detailsForEdit: {
            handler() {
                this.updateFind();
            },
            deep: true
        },
        contactsForEdit: {
            handler() {
                this.updateFind();
            },
            deep: true
        }
    },
    async mounted() {
        this.mode = this.globalMode;
        this.getAuxiliaryData();
        await this.initPartner(this.id);
        if (this.mode === 'edit' || this.mode === 'add') {
            this.prepareDataForEdit();
        }
    },
    methods: {
        getAuxiliaryData() {
            getContactTypes(r => {
                this.contactTypes = r;
            });
            getAddressTypes(r => {
                this.addressTypes = r;
            });
            getPartnerAddressTypes(r => {
                this.partnerAddressTypes = r;
            });
            getContactPositions(r => {
                this.contactPositions = r;
            });
            getScoringTranscription(r => {
                this.scoringTranscription = r;
            });
        },
        async initPartner(id) {
            if (id) {
                await getPartnerDetails(
                    id,
                    r => {
                        this.details = r;
                        this.legalDetails = r.legalDetails;
                    }
                );
                 await getContactApi(
                    id,
                    r => {
                        this.contacts = r;
                    }
                );
                await getAddresses(
                    id,
                    r => {
                        this.addresses = r;
                    }
                );
                await getScoring(
                    id,
                    r => {
                        this.scoring = r;
                    }
                );
                await getTechnologyPark(
                    id,
                    r => {
                        this.technologyPark = r;
                    }
                );
                await getVariablesValues(
                    id,
                    async r => {
                        this.variablesValues = r;

                        await getVariablesPoints(
                            [this.id],
                            points => {
                                this.variablesPoints = points;
                            }
                        );
                    }
                );
            }
            await getClassifier(
                id,
                r => {
                  this.classifier = r;
                }
            );
        },
        async updateFind() {
            if (this.mode === 'add') {
                this.promptPartners = null;

                const query = [];
                if (this.detailsForEdit.itn) {
                    query.push(this.detailsForEdit.itn);
                }
                if (this.detailsForEdit.name) {
                    query.push(this.detailsForEdit.name);
                }
                if (this.detailsForEdit.area_id) {

                    const district = await getAreas({
                        id: this.detailsForEdit.area_id,
                        one: true
                    });
                    query.push(district.name);
                }

                if (this.contactsForEdit.length) {
                    this.contactsForEdit.forEach(contact => {

                        if (contact.last_name) {
                            query.push(contact.last_name);
                            if (contact.first_name) {
                                query.push(contact.first_name);
                            }
                        }

                        if (contact.contactDetails && contact.contactDetails.length > 0) {
                            contact.contactDetails.forEach(contactDetail => {
                                query.push(contactDetail.data);
                            });
                        }
                    });
                }

                if (query.length > 0) {
                    this.findHandler(query.join(' '), {allRegions: true, size: 5});
                }
            } else {
                this.promptPartners = null;
            }
        },
        callAction(fn, params) {
            fn(this.partner, params);
        },
        async savePartner(data, onSuccess, onError) {
            await savePartner(data, onSuccess, onError);
        },
        save() {

            if (this.preloader) {
                return;
            }

            this.errors = {...errorsTemplate};
            this.preloader = true;
            this.savePartner(
                {
                    details: this.detailsForEdit,
                    contacts: this.contactsForEdit,
                    classifier: this.classifierForEdit,
                    addresses: this.addressesForEdit,
                    legalDetails: this.legalDetailsForEdit,
                    technologyPark: this.technologyParkForEdit,
                },
                message => {
                    this.$emit('update:id', message.partner_id);
                    this.preloader = false;
                    this.changeMode('display');
                    this.contactsForEdit = [];
                    this.addressesForEdit = [];
                    this.detailsForEdit = {};
                    this.legalDetailsForEdit = {};
                    this.technologyParkForEdit = [];
                    this.initPartner(message.partner_id);
                },
                error_message => {
                    this.errors = {...errorsTemplate, ...error_message};
                    this.preloader = false;
                }
            );
        },
        enableEdit() {
            this.changeMode('edit');
            this.prepareDataForEdit();
        },
        prepareDataForEdit() {
            this.contactsForEdit = this.contacts.map(item => ({...item}));
            this.classifierForEdit = this.classifier.map(item => helpers.deepCopy(item));
            this.addressesForEdit = this.addresses.map(item => ({...item}));
            this.detailsForEdit = {...this.details};
            this.legalDetailsForEdit = {...this.legalDetails};
            this.technologyParkForEdit = this.technologyPark.map(item => ({...item}));
        },
        cancel() {
            this.contactsForEdit = [];
            this.classifierForEdit = [];
            this.addressesForEdit = [];
            this.detailsForEdit = {};
            this.legalDetailsForEdit = {};
            this.technologyParkForEdit = [];
            this.errors = {...errorsTemplate};
            this.onCancel();
            this.changeMode('display');
        },
        async selectPartner(id) {
            this.$emit('update:id', id);
            await this.initPartner(id);
            this.enableEdit();
        },
        changeMode(mode) {
            this.mode = mode;
            this.$emit('update:globalMode', mode);
        },
        validate() {
            this.validated = false;
        }
    }
};
</script>
