export const VALUE_TYPE_STRING = 'STRING';
export const VALUE_TYPE_DECIMAL = 'DECIMAL';
export const VALUE_TYPE_BOOL = 'BOOL';
export const VALUE_TYPES = [
    {
        title: 'Строка',
        value: VALUE_TYPE_STRING,
        inputType: 'text',
    },
    {
        title: 'Число',
        value: VALUE_TYPE_DECIMAL,
        inputType: 'number',
    },
    {
        title: 'Булево',
        value: VALUE_TYPE_BOOL,
        inputType: 'checkbox',
    },
];

export const LIVETIME_TYPES = [
    {
        title: 'минут',
        value: 'MINUTES',
    },
    {
        title: 'часов',
        value: 'HOURS',
    },
    {
        title: 'дней',
        value: 'DAYS',
    },
    {
        title: 'месяцев',
        value: 'MONTHS',
    },
    {
        title: 'лет',
        value: 'YEARS',
    },
];

export const VARIABLE_TYPE_CONSTANT = 'CONSTANT';
export const VARIABLE_TYPE_CONDITIONS = 'CONDITIONS';
export const VARIABLE_TYPE_FORMULA = 'FORMULA';
export const VARIABLE_TYPES = {
    [VARIABLE_TYPE_CONSTANT]: 'constantProps',
    [VARIABLE_TYPE_CONDITIONS]: 'dynamicProps',
    [VARIABLE_TYPE_FORMULA]: 'calcProps',
};
