<div>
	<template v-if="mode==='display'">
		<div class="h4 mb-1" v-if="address.type_id">
			{{partnerAddressTypes[address.type_id].name}}
		</div>

		<div class="border-dashed p-1 mb-2 mt-1" v-if="rootAreas">
			<div  class="row-col">
				<div class="col-12 partner-title address-title">
					Регион
				</div>
			</div>
			<div  class="row-col mb-1">
				<div  class="col-5">
					{{getRegionName(address.region_id, rootAreas)}}
				</div>
				<div  class="col-7" v-if="districtAreas[address.region_id]">
					{{getRegionName(address['district_id'], districtAreas[address.region_id])}}
				</div>
			</div>
		</div>

		<template v-for="field in fields">
			<template v-if="Array.isArray(field) && address[field[1]]">
				<div class="row-col mb-1">
					<div class="col-3 partner-title address-title">
						{{getType(address[field[0]])}}
					</div>
					<div  class="col-9">
						{{address[field[1]]}}
					</div>
				</div>
			</template>
			<template v-else-if="address[field]">
				<div class="row-col mb-1">
					<div class="col-3 partner-title address-title">
						{{titles[field]}}
					</div>
					<div  class="col-9">
						{{address[field]}}
					</div>
				</div>
			</template>
		</template>
	</template>
	<template v-else-if="mode==='edit' || mode==='add'">
		<div class="h4 mb-1">
			<Multiselect
				mode="single"
				v-model="address['type_id']"
				placeholder="Выбрать тип адреса"
				label="name"
				trackBy="name"
				valueProp="id"
				:searchable="false"
				:options='Object.values(partnerAddressTypes)'
			/>
		</div>

		<div class="border-dashed p-1 mb-2 mt-1" v-if="rootAreas">
			<div  class="row-col">
				<div class="col-12 partner-title address-title">
					Регион
				</div>
			</div>
			<div  class="row-col mb-1">
				<div  class="col-5">
					<Multiselect
						mode="single"
						v-model="address.region_id"
						placeholder="Регион / Область"
						label="name"
						trackBy="name"
						valueProp="id"
						:searchable="true"
						:options='rootAreas'
						@change="onChangeRootAddress"
					/>
				</div>
				<div  class="col-7">
					<Multiselect
						v-if="districtAreas[address.region_id]"
						mode="single"
						v-model="address['district_id']"
						placeholder="Район"
						label="name"
						trackBy="name"
						valueProp="id"
						:searchable="true"
						:options='districtAreas[address.region_id]'
					/>
				</div>
			</div>
		</div>

		<template v-for="field in fields">
			<template v-if="Array.isArray(field)">
				<template v-if="field[1] === 'city'">
					<AddressField
						placeholder="Выбрать тип города"
						v-model:code="address['city_code']"
						v-model:value="address['city']"
						:address-types="groupedAddressTypes.city"
					/>
				</template>
				<template v-else-if="field[1] === 'street'">
					<AddressField
						placeholder="Выбрать тип улицы"
						v-model:code="address['street_code']"
						v-model:value="address['street']"
						:address-types="groupedAddressTypes.street"
					/>
				</template>
				<template v-else-if="field[1] === 'house'">
					<AddressField
						placeholder="Выбрать тип дома"
						v-model:code="address['house_code']"
						v-model:value="address['house']"
						:address-types="groupedAddressTypes.house"
					/>
				</template>
				<template v-else-if="field[1] === 'building'">
					<AddressField
						placeholder="Выбрать тип строения"
						v-model:code="address['building_code']"
						v-model:value="address['building']"
						:address-types="groupedAddressTypes.building"
					/>
				</template>
				<template v-else-if="field[1] === 'apartment'">
					<AddressField
						placeholder="Выбрать тип помещения"
						v-model:code="address['apartment_code']"
						v-model:value="address['apartment']"
						:address-types="groupedAddressTypes.apartment"
					/>
				</template>
			</template>
			<template v-else>
				<div class="row-col mb-1">
					<div class="col-5 partner-title address-title">
						{{titles[field]}}
					</div>
					<div  class="col-7">
						<FormInput :disabled="disabledFields.includes(field)"
							v-model="address[field]"
						/>
					</div>
				</div>
			</template>
		</template>
	</template>
</div>