<div class="preloader" v-if="preloader"></div>
<div :class="'form-group' + groupClass" v-else>
	<label v-if="label" :class="labelClassPrep" :for="attributes.id">
        {{label}} <Help v-if="helpText.length > 0" :text="helpText" />
    </label>
	<input
        ref="inputElement"
		:disabled="disabled"
		v-bind="attributesPrep"
		:value="modelValue"
		@input="input"
	/>
</div>
