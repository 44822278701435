<template src="../../templates/partner-cart-scoring.html"></template>
<script>

import ScoringValue from './scoring-value';

export default {
    name: 'Scoring',
    components: {
        ScoringValue,
    },
    props: {
        scoring: {
            type: Array,
            default() {
                return null;
            }
        },
        scoringTranscription: {
            type: Array,
            default() {
                return null;
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
    },
    emits: ['update:addresses'],
    data() {
        return {
            rootAreas: null,
            districtAreas: {},
        };
    },
    computed: {
        isSource() {
            return this.scoring.source !== null;
        },
        isEstimateDetails() {
            return this.scoring.estimateDetails !== null;
        }
    },
    mounted() {},
    methods: {}
};
</script>
