<div>
	<div v-if="contacts && contacts.length" v-for="(contact, key) in contacts" class="border-dashed mb-2 p-2">
		<Contact
			:mode="mode"
			:contact-types="contactTypes"
			:contact-positions="contactPositions"
			v-model:contact="contacts[key]"
            v-model:errors="componentsErrors[key]"
			:on-delete="() => {deleteContact(key)}"
            :actions="actions"
            :on-action="onAction"
		/>
	</div>

	<template v-if="mode==='edit' || mode==='add'">
		<div class="border-dashed mb-2 p-2 text-gray partner-add-contact text-center" @click="addContact()">
			+ Добавить контактное лицо
		</div>
	</template>
</div>