<teleport to="body">
    <Modal
        v-if="showModal"
        @onOk="$emit('update:showModal', false)"
        @onClose="$emit('update:showModal', false)"
        :close-button="true"
        :buttons="[]"
    >
        <template v-slot:body>

            <div class="modal-section modal-section-header">
                <h3 class="h3">{{title}}</h3>
            </div>
            <slot />
            <form>
                <div class="modal-section">
                    <template v-for="b in buttons">
                        <FormButton
                            :label="b.label" :class="' mr-2 ' + ((b.class)?b.class:'')"
                            :disabled="b.hasOwnProperty('disabled') ? b.disabled : false"
                            @click.prevent="b.action"
                        />
                    </template>
                </div>
            </form>
        </template>
    </Modal>
</teleport>
