import helpers from '@/tools/helpers';

const User = {
    state: {
        key: null,
        info: null
    },
    mutations: {

        updateUserInfo(state, payload) {
            state.info = payload;
        },

        updateUserKey(state, payload) {
            state.key = payload;
        }
    },
    actions: {

        updateUserInfo({commit}, profile) {
            commit('updateUserInfo', profile);
        },

        updateUserKey({commit}, key) {
            commit('updateUserKey', key);
        }
    },
    getters: {
        userSurnameWithInitials(state) {
            if (!state.info) {
                return null;
            }
            return helpers.getSurnameWithInitials(
                state.info.surname,
                state.info.name,
                state.info.patronymic
            );
        }
    },
};

export {
    User
};
