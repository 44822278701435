<template src="./templates/editor.html"></template>
<script>

import {computed} from 'vue';
import helpers from '@/tools/helpers';
import {itemTemplate} from '@c/ConditionEditor/config';
import FormButton from '@f/Button';
import Tree from '@c/ConditionEditor/components/tree';
import Item from './components/item';
import './styles.scss';

export default {
    name: 'ConditionEditor',
    components: {
        FormButton,
        Item,
        Tree,
    },
    provide() {
        return {
            conditionPropertySource: computed(() => this.conditionPropertySource),
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        conditionPropertySource: {
            type: String,
            required: true,
            default() {
                return '';
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    emits: ['update:items'],

    data() {
        return {
            editedItems: [...(this.items || [])],
            editedItemsInitial: [...(this.items || [])],
        };
    },
    computed: {
    },
    watch: {
        editedItems: {
            handler(val) {
                if (JSON.stringify(val) !== JSON.stringify(this.editedItemsInitial)) {
                    this.$emit('update:items', [...val]);
                }
            },
            deep: true,
        },
    },
    methods: {
        initTree() {
            this.editedItems = [
                ...helpers.deepCopy([{...itemTemplate, tmpKey: helpers.randomKey()}]),
            ];
            this.editedItemsInitial = [...helpers.deepCopy(this.editedItems)];
        }
    },
};

</script>
