export const SCENARIO_DEFAULT_VALUES = {
    name: '',
    scenario_group_id: null,
    published: 0,
    removed: 0,
    productMachineCategories: [],
    productMachines: [],
};

export const getQuestionTemplate = () => ({
    scenarioId: null,
    type: 'QUESTION',
    details: {
        description_manager: '',
        name: '',
        published: 1
    },
    removed: 0,
    productSelectionConditions: [],
    answerSelectionConditions: [],
});
export const getAnswerTemplate = () => ({
    details: {},
    answerSelectionConditions: [],
    productSelectionConditions: [],
    removed: 0,
});

export const BENEFIT_TREE_ITEM_TYPE_BENEFIT = 'benefit';
export const getBenefitFilterTemplate = () => ({
    title: '',
    showHiddenBenefits: 1,
});

/**
 * Результаты расчетов рейтингования и обозначения
 * @type {{POINT_RESULT_YES: string, POINT_RESULT_NULL: string, POINT_RESULT_NO: string, POINT_RESULT_PROBABLY: string}}
 */
const pointResults = {
    POINT_RESULT_YES: 'Да',
    POINT_RESULT_NO: 'Нет',
    POINT_RESULT_PROBABLY: 'Вероятно',
    POINT_RESULT_NULL: 'Null',
};

/**
 * Получить обозначение результата рейтингования переменной из стора
 *
 * @returns {string|string|*}
 * @param value
 * @param valueType
 * @param variableType
 */
export const getVariablePointsResult = (value, valueType, variableType) => {
    switch (variableType) {
        case 'CONSTANT':
            switch (valueType) {
                case 'STRING':
                    return value === null ? 'POINT_RESULT_NULL' : 'POINT_RESULT_YES';
                case 'BOOL':
                    if (value === null) {
                        return 'POINT_RESULT_NULL';
                    }

                    return !!value ? 'POINT_RESULT_YES' : 'POINT_RESULT_NO';
                case 'INT':
                case 'DECIMAL':
                    return value === null ? 'POINT_RESULT_NULL' : 'POINT_RESULT_YES';
                default:
                    return value;
            }
        case 'CONDITIONS':
            switch (true) {
                case value === null:
                    return 'POINT_RESULT_NULL';
                case value >= 2:
                    return 'POINT_RESULT_YES';
                case value <= 0:
                    return 'POINT_RESULT_NO';
                default:
                    return 'POINT_RESULT_PROBABLY';
            }
        default:
            return value;
    }
};

/**
 * Получить человекопонятное значение результата рейтингования переменной из стора
 * @returns {*}
 * @param value
 * @param valueType
 * @param variableType
 */
export const getVariablePointLabel = (value, valueType, variableType) => {
    const result = getVariablePointsResult(value, valueType, variableType);

    if (variableType === 'CONSTANT' && valueType !== 'BOOL') {
        return value;
    }

    return pointResults[result] || result;
};
