<template src="../../templates/partner-cart-prompt-partners.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'PromptPartners',
    components: {
        FormButton,
    },
    props: {
        promptPartners: {
            type: Object,
            default() {
                return null;
            }
        },
        onSelectPartner: {
            type: Function,
            default() {
                return null;
            }
        }
    },
    methods: {
        filterPartners(ids) {
            return this.promptPartners.partners.filter(partner => ids.includes(partner.id));
        },
        getPartnerNameWithArea(partner) {
            if (partner.area === null) {
                return partner.name;
            }
            return `${partner.name} (${partner.area.name})`;
        },
        generateInfo(partner) {

            let content = `${this.getPartnerNameWithArea(partner)}<br/>`;
            content += partner.itn ? `ИНН: ${partner.itn}<br/>` : '';

            if (partner.contactInformationPartners && partner.contactInformationPartners.length > 0) {
                content += '<br />';
                partner.contactInformationPartners.forEach(contact => {
                    content += `${contact.name} (${contact.position})<br />`;
                    if (contact.contactDetails && contact.contactDetails.length > 0) {
                        contact.contactDetails.forEach(detail => {
                            content += `${this.promptPartners.contact_types[detail.contact_data_type_id]}: ${detail.data}<br />`;
                        });
                    }
                    content += '<br />';
                });
            }

            return content;
        }
    }
};
</script>
