<div id="select-partner-container" v-if="partner && partner.id">
	<div class="row">
        Выбран: <span class="select-partner-container__link" @click="openPartnerCard">{{partner.name}}</span> ({{partner.area}})<br />
	</div>
	<div class="row">
		<tippy
            v-if="partner.contacts"
            :key="'select-partner-notice-' + partner.id + '-' +  partner.selectedContact"
            allowHTML
            arrow
            arrowType="round"
            theme="light"
            placement="bottom"
		>
            <template #content>
                <template v-for="contact in partner.contacts">
                    <span :style="contact.id === partner.selectedContact ? 'color:#00c600;' : ''">
                        {{contact.name}}<br />
                    </span>
                </template>
            </template>
            
			<span>Контакты</span>
		</tippy>
		<FormButton type="a" label="Изменить" :attributes="{class:['btn-sm', 'no-bg']}" @click="() => {this.$router.push('/partner-list');}" />
		<FormButton type="a" label="Сбросить" :attributes="{class:['btn-sm', 'no-bg', 'show-on-hover']}" @click="clearPartner"/>
	</div>

    <teleport to="body" v-if="showPartnerCard">
        <Modal
            v-if="showPartnerCard"
            @onOk="closePartnerCard"
            @onClose="closePartnerCard"
        >
            <template #body>
                <PartnerCart v-model:id="partner.id" />
            </template>
        </Modal>
    </teleport>
</div>
