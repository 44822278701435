<div class="pb-2">
	<div>
		<template v-for="field in fields">
			<div class="mb-1" v-if="partner[field.key] && mode==='display'">
				<div class="partner-title">{{field.title}}</div>
				<div class="h5 p-1 ml-n1">{{partner[field.key]}}</div>
			</div>
			<div class="mb-1" v-else-if="mode==='edit' || mode==='add'">
				<div class="partner-title">{{field.title}} <template v-if="fieldsRequired.includes(field.key)">*</template></div>
				<FormInput
                    :type="field.type"
                    :required="fieldsRequired.includes(field.key)"
                    v-model:errors="detailsErrors[field.key]"
					v-model="partner[field.key]"
					:attributes="{class:['ml-n1'], disabled: disableFields.includes(field.key)}"
				/>
				<Error v-if="errors[field.key] && errors[field.key].length" :message="errors[field.key]" box-class="ml-n1 mb-2" />
			</div>
		</template>


		<template v-if="mode==='display'">
			<div class="mb-1" v-if="partner['type_id'] && partnerTypes">
				<div class="partner-title">Тип контрагента</div>
				<div class="h5 p-1 ml-n1">{{partnerTypes[partner['type_id']].name}}</div>
			</div>
			<div class="mb-1" v-if="partner['legal_type_id'] && partnerLegalTypes">
				<div class="partner-title">Статус</div>
				<div class="h5 p-1 ml-n1">{{partnerLegalTypes[partner['legal_type_id']].name}}</div>
			</div>
			<template v-if="partner['area_tree']">
				<div class="mb-1" v-if="partner['area_tree'][0]">
					<div class="partner-title">Область, край</div>
					<div class="h5 p-1 ml-n1">{{partner['area_tree'][0].name}}</div>
				</div>
				<div class="mb-1" v-if="partner['area_tree'][1]">
					<div class="partner-title">Район</div>
					<div class="h5 p-1 ml-n1">{{partner['area_tree'][1].name}}</div>
				</div>
			</template>
		</template>
		<template v-else-if="mode==='edit' || mode==='add'">
			<div class="mb-1" >
				<div class="partner-title">Тип контрагента *</div>
				<Multiselect class="ml-n1 bg-white"
					key="ptype"
					v-if="partnerTypes"
					mode="single"
					v-model="partner['type_id']"
					placeholder="Выбрать тип"
					label="name"
					trackBy="name"
					valueProp="id"
					:searchable=true
					:options='Object.values(partnerTypes)'
				/>
				<Error v-if="errors['type_id']" :message="errors['type_id']" box-class="ml-n1 mb-2" />
			</div>
			<div class="mb-1" >
				<div class="partner-title">Статус</div>
				<Multiselect class="ml-n1 bg-white"
					v-if="partnerLegalTypes"
					key="pstatus"
					mode="single"
					v-model="partner['legal_type_id']"
					placeholder="Выбрать статус"
					label="name"
					trackBy="name"
					valueProp="id"
					:searchable=true
					:options='Object.values(partnerLegalTypes)'
				/>
				<Error v-if="errors['legal_type_id']" :message="errors['legal_type_id']" box-class="ml-n1 mb-2" />
			</div>

			<div class="mb-1" >
				<div class="partner-title">Область, край *</div>
				<Multiselect class="ml-n1 bg-white"
				 	key="parea"
					v-if="rootAreas"
					mode="single"
					v-model="selectedRootArea"
					placeholder="Выбрать регион"
					label="name"
					trackBy="name"
					valueProp="id"
					:searchable=true
					:options='Object.values(rootAreas)'
				/>
			</div>
			<div class="mb-1">
				<div class="partner-title">Район *</div>
				<Multiselect class="ml-n1 bg-white"
				 	key="pdistrict"
					v-if="districtAreas"
					mode="single"
					v-model="selectedArea"
					placeholder="Выбрать район"
					label="name"
					trackBy="name"
					valueProp="id"
					:searchable=true
					:options='Object.values(districtAreas)'
				/>
                <Error v-if="errors['area_id']" :message="errors['area_id']" box-class="ml-n1 mb-2" />
			</div>
		</template>
	</div>
</div>
