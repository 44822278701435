<div class="report-btn--wrap">
    <a href="https://lbr.freshservice.com/support/catalog/items?category_id=51000130376" target="_blank" class="btn idea-btn">
        <span class="btn-inner">Есть идея</span>
    </a>
    <a href="https://lbr.freshservice.com/support/catalog/items?category_id=51000088743" target="_blank" class="btn report-btn">
        <span class="btn-inner">Сообщить о проблеме</span>
    </a>

    <teleport to="body">
        <Modal
                v-if="showModal"
                @onOk="showModal = false"
                @on-close="showModal = false"
                :buttons="[]"
        >
            <template v-slot:header>
                <h2 class="h1">Сообщить о проблеме</h2>
            </template>

            <template v-slot:body>
                <form>
                    <div class="modal-section">
                        <div v-if="sendProcess" class="preloader pv-5"></div>
                        <template v-else-if="errorMessage">
                            <div class="form-row">
                                <div class="col-12">
                                    <h3 class="text-red">Ошибка: {{ errorMessage }}</h3>
                                </div>
                            </div>
                            <div class="row-col">
                                <div class="col-12">
                                    <Button @click.prevent="sendReportAction"
                                            label='Отправить еще раз'
                                            :attributes="{ class: 'btn-green' }"/>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="successMessage">
                            <div class="form-row">
                                <div class="col-12 mb-3">
                                    <h3>{{ successMessage }}</h3>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="form-row">

                                <div class="col-12 mb-2">
                                    <FormSelect
                                            :preloader="!services.length"
                                            key="request_service_id"
                                            :attributes="{name: 'request_service_id'}"
                                            placeholder="Выбрать чат"
                                            :options="services"
                                            v-model="request_service_id"
                                            :errors="validationErrors.request_service_id"
                                    />
                                </div>

                                <div class="col-12">
                                    <FormTextarea
                                            label="Опишите проблему"
                                            :attributes="{name: 'description', cols: '80', rows: '3'}"
                                            v-model.trim="message"
                                            :errors="validationErrors.message"
                                    />
                                </div>
                            </div>
                            <div class="row-col">
                                <div class="col-12">
                                    <Button @click.prevent="sendReportAction"
                                            label='Отправить'
                                            :attributes="{ class: 'btn-green' }"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </form>
            </template>
        </Modal>
    </teleport>
</div>

