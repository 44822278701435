<modal @onClose="closeModal">
    <div class="pt-5 px-3 pb-3">
        <div class="row-col">
            <div class="col">
                <features-filter
                    v-model:filter="filter"
                    v-model:dataFilter="dataFilter"
                    :product-categories="productCategories"
                />
            </div>
        </div>
        <div class="row-col mt-3">
            <div class="col">
                <product-features-list
                    v-model:selected-item="variable"
                    v-model:selected-item-id="variableId"
                    :product-categories="productCategories"
                    :filter="filter"
                    :dataFilter="dataFilter"
                    @dblclick="closeModal"
                />
            </div>
        </div>
    </div>
</modal>
