export default class Filter {

	init(filter = {}) {
		this.saveFilter(filter);
	}

	setGroup(groupName, values = {}) {
		const filter = this.loadFilter();
		filter[groupName] = values;
		this.saveFilter(filter);
	}

	getGroup(groupName, defaultValue = null) {
		const filter = this.loadFilter();
		let result;
		if (typeof filter[groupName] === 'undefined') {
			result = (defaultValue === null) ? {} : defaultValue;
		} else {
			result = filter[groupName];
		}
		return result;
	}

	saveFilter(filter) {
		sessionStorage.setItem('CRM_filter', JSON.stringify(filter));
	}

	loadFilter() {
		let filter = sessionStorage.getItem('CRM_filter');
		if (!filter) {
			filter = '{}';
		}
		return JSON.parse(filter);
	}
};
