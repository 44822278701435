<template src="../../templates/properties.html"></template>
<script>
import {getVariables, saveVariable} from '@api/scenarios';
import Checkbox from '@f/Check';
import ConditionEditor from '@c/ConditionEditor';
import VariableTree from '@c/Scenarios/VariableTree';
import {getVariablePointLabel, getVariablePointsResult} from '@page/Scenario/config';
import Modal from '@c/Modal';
import EditVariable from '@page/VariableEditor/components/edit-variable';
import Confirm from '@c/Confirm';
import FormButton from '@f/Button';


export default {
    name: 'Properties',
    components: {
        Checkbox,
        ConditionEditor,
        VariableTree,
        Modal,
        EditVariable,
        Confirm,
        FormButton,
    },
    props: {
        properties: {
            type: Object,
            default() {
                return {};
            }
        },
        points: {
            type: Object,
            default() {
                return {};
            }
        },
        partnerId: {
            type: [Number, String],
            default() {
                return null;
            }
        },
    },
    data() {
        return {
            filters: {
                positive: 1,
                negative: 1,
                probable: 1,
                empty: 1,
            },
            conditions: [],
            conditionsPoints: [],
            showConditionsModal: false,
            showEditVariableModal: false,
            editVariableData: null,
            showConfirmModal: false,
            confirmBts: {
                title: '',
                buttons: []
            },
        };
    },
    computed: {

    },
    watch: {
        filters: {
            handler() {
                this.$refs.varTree.onUpdateTree();
            },
            deep: true,
        }
    },
    methods: {
        getPropertyLabel(variable, type) {
            return getVariablePointLabel(
                variable?.variable_value || null,
                variable?.variable_type || null,
                type
            );
        },
        getPointClass(points) {
            switch (true) {
                case points >= 2:
                    return 'green';
                case points <= 0:
                    return 'red';
                case points > 0 && points < 2:
                    return 'yellow';
                default:
                    return '';
            }
        },
        getProbabilityClass(variable, type) {
            const probability = getVariablePointsResult(
                variable?.variable_value || null,
                variable?.variable_type || null,
                type
            );

            switch (probability) {
                case 'POINT_RESULT_YES':
                    return 'parent-background-green';
                case 'POINT_RESULT_PROBABLY':
                    return 'parent-background-yellow';
                case 'POINT_RESULT_NO':
                    return 'parent-background-red';

                default: return '';
            }
        },
        filterFunc(item, i) {
            const rateResult = getVariablePointsResult(
                this.properties[item.id]?.variable_value || null,
                this.properties[item.id]?.variable_type || null,
                item.type
            );

            switch (true) {
                case !this.filters.positive && rateResult === 'POINT_RESULT_YES':
                    return false;
                case !this.filters.negative && rateResult === 'POINT_RESULT_NO':
                    return false;
                case !this.filters.empty && rateResult === 'POINT_RESULT_NULL':
                    return false;
                case !this.filters.probable && rateResult === 'POINT_RESULT_PROBABLY':
                    return false;
                default:
                    return true;
            }
        },
        viewDetails(variableId) {
            getVariables(
                {
                    id: variableId,
                    withConditions: true,
                },
                r => {
                    this.editVariableData = r[variableId];
                    this.conditions = r[variableId].pools;
                    this.conditionsPoints = this.points[variableId].details;
                    this.showConditionsModal = true;
                }
            );
        },
        saveVariable(data) {
            saveVariable(
                {...data},
                r => {
                    this.showConditionsModal = false;
                    this.closeEditVariableModal(false);
                }
            );
        },
        closeEditVariableModal(showConfirmDialog = true) {
            this.confirmBts = {
                title: 'Данные были изменены. Сохранить изменения?',
                buttons: [
                    {
                        label: 'Да',
                        class: 'btn-green',
                        action: () => {
                            this.showConfirmModal = false;
                            this.$refs.editVariable.save();
                        },
                    },
                    {
                        label: 'Нет',
                        class: 'btn-red',
                        action: () => {
                            this.showEditVariableModal = false;
                            this.showConfirmModal = false;
                        },
                    },
                    {
                        label: 'Отмена',
                        action: () => {
                            this.showConfirmModal = false;
                        },
                    },
                ]
            };

            if (this.$refs.editVariable.modified && showConfirmDialog) {
                this.showConfirmModal = true;
            } else {
                this.showEditVariableModal = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/properties.scss';
</style>

<style>
.expansion-item__header:has(.parent-background-green) {
    background: rgba(0,135,107,0.08);
}
.expansion-item__header:has(.parent-background-yellow) {
    background: rgba(233, 168, 0, 0.08);
}
.expansion-item__header:has(.parent-background-red) {
    background: rgba(235, 87, 87, 0.08);
}
</style>
