<template src="./templates/grid.html"></template>

<script>

import GridCell from './components/grid-cell';
import '../../layouts/default/styles/grid.scss';

export default {
    name: 'Grid',
    components: {
        GridCell
    },
    props: {
        columns: {
            type: Array,
            default() {
                return [
                    {
                        name: 'id',
                        hidden: true,
                    },
                    {
                        name: 'first',
                        label: 'First column',
                        width: '40%',
                    },
                    {
                        name: 'second',
                        label: 'Second column',
                        width: '30%',
                    },
                    {
                        name: 'third',
                        label: 'Third column',
                        width: '30%',
                    },
                    {
                        name: 'fourth',
                        label: 'Fourth column',
                        slot: true,
                        slot_name: 'some-slot-name',
                        width: 'minmax(150px, 3.33fr)',
                    },
                ];
            }
        },
        data: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        first: 'First column content',
                        second: 'Second column content',
                        third: 'Third column content',
                    },
                    {
                        id: 2,
                        first: 'First column content',
                        second: 'Second column content',
                        third: 'Third column content',
                    },
                    {
                        id: 3,
                        first: 'First column content',
                        second: 'Second column content',
                        third: 'Third column content',
                    },
                ];
            }
        },
        formatters: {
            type: Object,
            default() {
                return {
                    second: (value) => `<u>${value}</u>`
                };
            }
        },
        treeGrid: {
            type: Boolean,
            default: () => false,
        },
        treeGridChildrenField: {
            type: String,
            default: () => 'children',
        },
        treeGridExpandedField: {
            type: String,
            default: () => 'isExpanded',
        },
        idField: {
            type: String,
            default: () => 'id',
        },
        hideHeader: {
            type: Boolean,
            default: () => false,
        },
        onEdit: {
            type: Function,
        },
        onRemove: {
            type: Function,
        },
        onLink: {
            type: Function,
        },
    },
    data() {
        return {
            treeGridExpandedIds: [],
        };
    },
    computed: {
        visibleColumns() {
            return this.columns.filter(({hidden}) => !hidden);
        },
        columnsStyle() {
            // grid-template-columns:
            // minmax(150px, 3.33fr)
            // minmax(50px, 1fr)
            // minmax(50px, 1fr)
            // minmax(100px, 1fr)

            return this.visibleColumns.reduce(
                (style, column) => `${style} ${column.width}`,
                'grid-template-columns:'
            );
        },
        preparedData() {
            if (this.treeGrid) {
                return this.treeToFlatArray(this.data);
            }

            return this.data;
        },
    },
    methods: {
        format(format_type, value) {
            let result = value;

            if (typeof this.formatters[format_type] !== 'undefined') {
                result = this.formatters[format_type](result);
            }

            return result;
        },
        /**
         * В случае если пропс treeGrid === true, переданное дерево в data
         * преобразуем в обычный массив. К каждому объекту добавляются свойства:
         *  - level - уровень вложенности
         *  - parentId - идентификатор родителя
         *  - hasChildren - имеет ли потомков (true/false)
         *  - allParents - массив идентификаторов предков в порядке вложенности
         */
        treeToFlatArray(tree, level = 0, parentId = null, allParents = []) {
            const result = [];
            tree.forEach(item => {
                const newItem = {
                    ...item,
                    level: level,
                    parentId: parentId,
                    hasChildren: item[this.treeGridChildrenField].length > 0,
                    allParents: [],
                };
                if (parentId !== null) {
                    newItem.allParents = [...allParents, parentId];
                }
                delete newItem[this.treeGridChildrenField];
                result.push(newItem);

                if (newItem[this.treeGridExpandedField]) {
                    this.treeGridExpandedIds.push(newItem[this.idField]);
                }

                this.treeToFlatArray(
                    item[this.treeGridChildrenField],
                    level + 1,
                    item[this.idField],
                    newItem.allParents
                ).forEach(child => result.push(child));

            });

            return result;
        },
        isRowVisible(row) {
            if (!this.treeGrid) {
                return true;
            }

            if (row.parentId !== null) {
                // если любой из предков не раскрыт, то строку не показываем
                for (let i = 0; i < row.allParents.length; i++) {
                    if (!this.treeGridExpandedIds.includes(row.allParents[i])) {
                        return false;
                    }
                }
            }

            return true;
        },
        expandCollapse(row, columnIndex) {
            if (!this.treeGrid || !row.hasChildren || columnIndex !== 0) {
                return;
            }
            const index = this.treeGridExpandedIds.findIndex(
                item => item === row[this.idField]
            );
            if (index !== -1) {
                // свернуть
                this.treeGridExpandedIds.splice(index, 1);
            } else {
                // развернуть
                this.treeGridExpandedIds.push(row[this.idField]);
            }
        }
    }
};
</script>

<!--<style src="../../layouts/default/styles/grid.scss"></style>-->
