<template src="./templates/datagenerator-classifier.html"></template>
<script>

import {
    getClassifierItemTree
} from '@api/partners';

import Tree from '@c/Tree';
import FormCheckbox from '@f/Check';
import FormInput from '@f/Input';

export default {
    name: 'ClassifierDataGen',
    components: {
        Tree,
        FormCheckbox,
        FormInput,
    },
    props: {
        settings: {
            type: Object,
            default() {
                return {
                    status: 0,
                    changeCategory: null
                };
            }
        },
    },
    data() {
        return {
            itemsTree: null,
            filter: '',
        };
    },
    created() {
        getClassifierItemTree(r => {
            this.itemsTree = r;
        });
    }
};

</script>
