<div id="layout" class="layout">
	<input id="input--layout--sidebar-left--minimize" type="checkbox" style="position:absolute;">

	<div v-if="userKey && user_info" class="layout--sidebar-left">
		<div class="row logo">
			<a href="/"><span class="lbr-logo h5">LBR.CRM</span></a>
			<label for="input--layout--sidebar-left--minimize" class="input--layout--sidebar-left--minimize pointer"><span class="icon chevrons"></span></label>
		</div>

		<div class="main-nav">

			<List v-if="menu_items" :params="{'class':'menu--sidebar menu--nav','type':'checkbox','isEditor':false,'labelClass':''}" :items="menu_items" />
		</div>

        <ReportForm v-if="userKey && user_info && !currentPathLogin"></ReportForm>

		<div class="block-user abs-bottom">
			<div class="account">
				<div class="name h6">{{user_info.name + ' ' + user_info.surname}}</div>
				<div class="position">{{user_info.position ? user_info.position :''}}</div>
			</div>
            <FormButton label="Выйти" @click="logout"></FormButton>

			<List :params="{'class':'menu--sidebar menu-user','type':'checkbox','isEditor':false}" :items="user_menu_items" />
		</div>
	</div>

	<div class="layout--content" v-if="currentPathLogin || (userKey && user_info)">
		<router-view />
	</div>

	<SelectPartner />

</div>
