<div>
    <div class="legal">
        <div class="border-dashed mb-2 p-2 estimate-title">
            Рейтинг:
            <span v-if="scoring.estimate === 0" class="estimation estimation-color-green">Зеленый</span>
            <span v-else-if="scoring.estimate === 1" class="estimation estimation-color-yellow">Желтый</span>
            <span v-else-if="scoring.estimate === 2" class="estimation estimation-color-red">Красный</span>
            <span v-else class="estimation estimation-color-gray">Не определен</span>
        </div>

        <template v-if="isSource">
            <div class="border-dashed mb-2 p-2">
                <div class="h4 mb-1">
                    Исходные данные
                </div>

                <div class="border-dashed mb-2 p-2">
                    <ScoringValue
                        v-for="(item, key) in scoring.source"
                        :name="scoringTranscription[key]"
                        :value="item"
                        :code="key"
                        :type="typeof item"
                    ></ScoringValue>
                </div>
            </div>
        </template>

        <template v-if="isEstimateDetails" class="border-dashed mb-2 p-2">
            <div class="border-dashed mb-2 p-2">
                <div class="h4 mb-1">
                    Оценки
                </div>


                <div class="border-dashed mb-2 p-2">
                    <ScoringValue
                        v-for="(item, key) in scoring.estimateDetails"
                        :name="scoringTranscription[key]"
                        :value="item"
                        :code="key"
                        :format-value="false"
                        :type="typeof item"
                    ></ScoringValue>
                </div>
            </div>
        </template>
    </div>
</div>
