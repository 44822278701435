<template src="../templates/condition.html"></template>
<script>

import helpers from '@/tools/helpers';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import {catalogs} from '@c/ConditionEditor/config';
import ConditionProperty from '@c/ConditionEditor/components/condition-property';
import ConditionValue from './condition-value';

export default {
    name: 'Condition',
    components: {
        FormButton,
        FormInput,
        FormSelect,
        ConditionValue,
        ConditionProperty,
    },
    props: {
        condition: {
            type: Object,
            default() {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    emits: ['update:condition'],

    data() {
        return {
            catalogs: {...catalogs},
            conditionEdited: {...helpers.deepCopy(this.condition)},
        };
    },
    computed: {
        comparisonOperators() {
            switch (this.conditionEdited.property.type) {
                case 'BOOL':
                    return catalogs.comparison_operators_bool;
                case 'STRING':
                case 'LIST_STRING':
                    return catalogs.comparison_operators_string;
                case 'SETOFNUMBER':
                    return catalogs.comparison_operators_setofnumber;
                default:
                    return catalogs.comparison_operators;
            }
        },
    },
    watch: {
        'conditionEdited.property': {
            handler(val, oldVal) {
                const comparedItem = val.key.split('-');
                const defaultValue = val.options?.length ? [val.options[0].value] : [];

                this.conditionEdited.value_type = val.type;
                this.conditionEdited.condition_type = 'EQUAL';
                this.conditionEdited.value = val.type === 'BOOL' ? ['1'] : defaultValue;
                this.conditionEdited.compared_item = comparedItem.shift();
                this.conditionEdited.compared_id = comparedItem.shift();

                this.$emit('update:condition', this.conditionEdited);
            },
            deep: true,
        },
        'conditionEdited.value': {
            handler(val) {
                this.conditionEdited.value_type = this.conditionEdited.property.type;
                this.conditionEdited.value = val;
                this.$emit('update:condition', this.conditionEdited);
            },
            deep: true,
        },
        'conditionEdited.points': {
            handler(val) {
                this.conditionEdited.points = val;
                this.$emit('update:condition', this.conditionEdited);
            },
            deep: true,
        },
        'conditionEdited.condition_type': {
            handler(val) {
                this.conditionEdited.condition_type = val;
                this.$emit('update:condition', this.conditionEdited);
            },
            deep: true,
        },
        'conditionEdited.value_type': {
            handler(val) {
                this.conditionEdited.value_type = val;
                this.$emit('update:condition', this.conditionEdited);
            },
            deep: true,
        },
    },
};

</script>
<style>
    input.points-input::-webkit-outer-spin-button,
    input.points-input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input.points-input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
</style>
