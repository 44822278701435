<modal @onClose="closeModal">
    <div class="pt-5 px-3 pb-3">
        <div class="row-col">
            <div class="col">
                <VariableTreeFilter
                    v-model:filter="filter"
                    v-model:show-removed="showRemoved"
                />
            </div>
        </div>
        <div class="row-col mt-3">
            <div class="col">
                <variable-tree
                    v-model:selected-category="variable"
                    v-model:selected-category-id="variableId"
                    :filter-text="filter"
                    :show-removed="showRemoved"
                    @dblclick="closeModal"
                />
            </div>
        </div>
    </div>
</modal>
