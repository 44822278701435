const Cyborg = {
    state: {
        instruments: null
    },
    mutations: {
        initInstruments(state, instruments) {
            state.instruments = instruments;
        },
    },
    actions: {
        initInstruments({commit}, instruments) {
            commit('initInstruments', instruments);
        },
    }
};

export {
    Cyborg
};
