// eslint-disable-next-line import/no-cycle
import {getBranches} from '@api/layout';

const Catalogs = {
    actions: {
        initBranchList: context => {
            if (context.state.branches === null) {
                context.commit('initBranchList', []);

                getBranches().then(response => {
                    context.commit('initBranchList', response);
                });
            }
        }
    },
    mutations: {
        initBranchList: (state, branches) => {
            state.branches = branches;
        }
    },
    state: {
        branches: null,
    }
};

export {
    Catalogs
};
