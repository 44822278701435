<div>

    <expansion-item v-if="partnersDataStatuses" label="Статус данных контрагента" class="mb-2">
        <div v-if="partnersDataStatusUpdateError">
            {{partnersDataStatusUpdateError}}
        </div>
        <div v-else v-for="(partnersDataStatus, k) in partnersDataStatuses" class="mb-1 p-1">
            {{dataType[k]?dataType[k]:k}}<br />
            {{dataStatuses[partnersDataStatus.status]?dataStatuses[partnersDataStatus.status]:partnersDataStatus.status}}
            {{formatDate(partnersDataStatus.last_update)}}
        </div>
        <form-button label="Пересчитать"
            type="a"
            class="btn btn-red text-center m-1"
            @click="updatePartnerData()"
        />
    </expansion-item>

    <div class="row mt-1" v-if="scenarioRatings && productRatings && ratingsFromStore && productDetails">
        <div v-for="scenarioData in scenarios" class="mb-1">
            <expansion-item
                v-model="treeStatuses[scenarioData.scenario.id]"
                :label="scenarioData.scenario.name"
                :label-right=
                    "'' + scenarioData.points
                    + ((ratingsFromStore[scenarioData.scenario.id])? ` (${ratingsFromStore[scenarioData.scenario.id].points})` : 'null')"
                :header-class="(scenarioData.scenario.published === '1') ? 'bg-light-green' : ''"
            >
                <tabs
                    v-if="treeStatuses[scenarioData.scenario.id] === true"
                    class="bg-white"
                    :columns="rulesRulesTypeTabs"
                    selected="productRatings"
                    :data="{
                        productRatings: scenarioData.scenario.id,
                        scenarioCalculationResult: scenarioData.scenario.id,
                    }"
                >
                    <template #productRatings="{ data: scenarioId }">
                        <template v-if="productRatings">
                                 <div v-for="productRating in productRatings[scenarioData.scenario.id]" class="mb-1">
                                    <expansion-item
                                        v-if="scenarioQuestions[scenarioData.scenario.id]
                                                && productCalculationDetails[scenarioData.scenario.id]
                                                && productCalculationDetails[scenarioData.scenario.id][productRating.product_id]"
                                        :label="productDetails[productRating.product_id].name"
                                        :label-right="'' + productRating.points.toFixed(2)"
                                        v-model="calculationDetailsTreeStatuses[scenarioData.scenario.id][productRating.product_id]"
                                    >
                                        <div v-if="scenarioQuestions[scenarioData.scenario.id]
                                                && productCalculationDetails[scenarioData.scenario.id]
                                                && productCalculationDetails[scenarioData.scenario.id][productRating.product_id]
                                                && calculationDetailsTreeStatuses[scenarioData.scenario.id]
                                                && calculationDetailsTreeStatuses[scenarioData.scenario.id][productRating.product_id]
                                            "
                                        >
                                            <div v-for="rules in scenarioQuestions[scenarioData.scenario.id]" class="m-1">
                                                <expansion-item :label="rules.details.name" header-class="bg-light-green">
                                                    <div class="m-1">
                                                        <div v-for="rule in rules.answers" class="scenario__rule-line">
                                                            <expansion-item
                                                                :header-class="(
                                                                    rule.answerSelectionConditions.length > 0
                                                                    && productCalculationDetails[scenarioData.scenario.id][productRating.product_id].ruleRatesDetails[rule.id].points) > 0
                                                                    ? 'bg-light-green'
                                                                    : ''"
                                                                :label="rule.details.name"
                                                                :label-right="`${calculateSumProductPoints(
                                                                    rule.productSelectionConditions,
                                                                    productCalculationDetails[scenarioData.scenario.id][productRating.product_id].details[rule.id]
                                                                )}`"
                                                            >
                                                                <tabs
                                                                    class="bg-white"
                                                                    :columns="productScenarioTabColumns"
                                                                    selected="productSelection"
                                                                    :data="{
                                                                        ruleSelection: productCalculationDetails[scenarioData.scenario.id][productRating.product_id].ruleRatesDetails[rule.id],
                                                                        productSelection: productCalculationDetails[scenarioData.scenario.id][productRating.product_id].details[rule.id],
                                                                    }"
                                                                >
                                                                    <template #ruleSelection="{ data }">
                                                                        <ConditionEditor
                                                                            v-if="rule.answerSelectionConditions"
                                                                            :items="rule.answerSelectionConditions"
                                                                            condition-property-source="PARTNER"
                                                                            disabled
                                                                        >
                                                                            <template v-slot:extraPoolData="pool">
                                                                                <div
                                                                                    v-if="data.details.pools[pool.data.id]"
                                                                                    :class="`point-mark pool ${getPointClass(data.details.pools[pool.data.id]?.points)}`"
                                                                                >
                                                                                    {{data.details.pools[pool.data.id]?.points}}
                                                                                </div>
                                                                                <div v-else>
                                                                                    Нет
                                                                                </div>
                                                                            </template>
                                                                            <template v-slot:extraConditionData="condition">
                                                                                <div :class="`
                                                                                    point-mark
                                                                                    condition
                                                                                    ${getPointClass(data.details.conditions[condition.data.id]?.points?.value)}
                                                                                `">
                                                                                    {{data.details.conditions[condition.data.id]?.points?.value}}
                                                                                </div>
                                                                            </template>
                                                                        </ConditionEditor>
                                                                    </template>
                                                                    <template #productSelection="{ data: details }">
                                                                        <ConditionEditor
                                                                            v-if="typeof details !== 'undefined' && details && rule.productSelectionConditions"
                                                                            :items="rule.productSelectionConditions"
                                                                            condition-property-source="PARTNER"
                                                                            disabled
                                                                        >
                                                                            <template #extraPoolData="pool">
                                                                                <div
                                                                                    :class="`point-mark pool ${getPointClass(details.pools[pool.data.id]?.points)}`"
                                                                                >
                                                                                    {{details.pools[pool.data.id]?.points}}
                                                                                </div>
                                                                            </template>
                                                                            <template #extraConditionData="condition">
                                                                                <div
                                                                                    :class="`
                                                                                        point-mark
                                                                                        condition
                                                                                        ${getPointClass(details.conditions[condition.data.id]?.points?.value)}
                                                                                    `"
                                                                                >
                                                                                    {{details.conditions[condition.data.id]?.points?.value}}
                                                                                </div>
                                                                            </template>
                                                                        </ConditionEditor>
                                                                    </template>
                                                                </tabs>
                                                            </expansion-item>
                                                        </div>
                                                    </div>
                                                </expansion-item>
                                            </div>
                                        </div>
                                        <div v-else class="pw-1">
                                            <span class="point-mark pool yellow">Строим дерево проблем...</span>
                                        </div>
                                     </expansion-item>
                                 </div>
                        </template>
                    </template>
                    <template #scenarioCalculationResult="{ data: scenarioId }">
                        <ConditionEditor
                            v-if="scenarioConditions[scenarioId]"
                            :items="scenarioConditions[scenarioId]"
                            condition-property-source="PARTNER"
                            disabled
                        >
                            <template v-slot:extraPoolData="pool">
                                <div
                                    :class="`point-mark pool ${getPointClass(scenarioData.details.pools[pool.data.id]?.points)}`"
                                >
                                    {{scenarioData.details.pools[pool.data.id]?.points}}
                                </div>
                            </template>
                            <template v-slot:extraConditionData="condition">
                                <div :class="`
                                    point-mark
                                    condition
                                    ${getPointClass(scenarioData.details.conditions[condition.data.id]?.points?.value)}
                                `">

                                    {{scenarioData.details.conditions[condition.data.id]?.points?.value}}
                                </div>
                            </template>
                        </ConditionEditor>
                    </template>
                </tabs>
            </expansion-item>
        </div>
    </div>
    <div v-else>
            <grid
                hide-header
                :columns="[{slot_name: 'first', width: '15%'}, {slot_name: 'second'}]"
                :data="initStatuses"
            >
                <template #first="{ data: item }">
                    {{item.title}}
                </template>
                <template #second="{ data: item }">
                    <span :class="'point-mark pool ' + item.color">{{item.status}}</span>
                </template>
            </grid>

    </div>
</div>
