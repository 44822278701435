<template src="../templates/features-filter.html"></template>
<script>

import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import FormRadio from '@f/Radio';
import {getFeatureFilterTemplate} from '@c/Scenarios/ProductFeaturesList/config';

export default {
    name: 'FeaturesFilter',
    components: {
        FormInput,
        FormCheckbox,
        FormRadio,
    },
    props: {
        filter: {
            type: String,
            default() {
                return '';
            },
            required: true,
        },
        dataFilter: {
            type: Object,
            default: getFeatureFilterTemplate(),
            required: false,
        },
        productCategories: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
    },
    emits: ['update:filter', 'update:dataFilter'],
    data() {
        return {
            filterValue: this.filter,
            category: this.dataFilter?.categoryIds,
            showCommonFeatures: 0,
        };
    },
    watch: {
        filterValue() {
            this.$emit('update:filter', this.filterValue);
        },
        category() {
            if (!Array.isArray(this.category)) {
                this.showCommonFeatures = 0;
            }
            this.updateFilter();
        },
        showCommonFeatures() {
            if (this.showCommonFeatures === 1) {
                this.category = this.productCategories.map(c => c.value);
            }
            this.updateFilter();
        },
    },
    mounted() {
        this.$refs['features-search-form-input'].setFocus();
    },
    methods: {
        updateFilter() {
            this.$emit('update:dataFilter', {
                categoryIds: Array.isArray(this.category) ? this.category : [this.category],
                showCommonFeatures: this.showCommonFeatures,
            });
        },
    },
};
</script>
