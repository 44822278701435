<div>
    <div class="row-col mb-2">
        <div class="col-6 pr-0">
            <Multiselect
                mode="single"
                v-model="categoryId"
                placeholder="Тип техники"
                label="name"
                trackBy="name"
                valueProp="id"
                searchable
                :loading="loading && !categoryId"
                :options="categories"
            />
        </div>
        <div class="col-6">
            <Multiselect
                mode="single"
                v-model="manufacturerId"
                placeholder="Производитель"
                label="name"
                trackBy="name"
                valueProp="id"
                searchable
                :loading="loading && !manufacturerId"
                :options="manufacturers"
            />
        </div>
    </div>

    <div class="row-col">
        <div class="col-6 pr-0">
            <Multiselect
                mode="single"
                v-model="modelLineId"
                placeholder="Модельный ряд"
                label="name"
                trackBy="name"
                valueProp="id"
                searchable
                :disabled="!categoryId && !manufacturerId && !modelLineId"
                :loading="loading && !modelLineId"
                :options="modelLines"
            />
        </div>

        <div class="col-3 pr-0">
            <Multiselect
                mode="single"
                v-model="modelId"
                placeholder="Модель"
                label="name"
                trackBy="name"
                valueProp="id"
                searchable
                :disabled="!modelLineId"
                :loading="loading && !modelId"
                :options="models"
            />
        </div>

        <div class="col-3">
            <FormButton
                :disabled="loading || modelLineId === null || errors.length > 0"
                :label="`+ Добавить`"
                :attributes="{'class': ['btn-sm', 'btn-green', 'w-100']}"
                @click="add()"
            />
        </div>
    </div>

    <template v-for="error in errors">
        <Error v-if="error.length" :message="error" />
    </template>
</div>
