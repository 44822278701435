export const watchModifyMixin = (dataPropertyName, initialDataPropertyName) => ({
    data() {
        return {
            modified: false,
            modifyWatcherAdded: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.addModifyWatcher();
        }, 500);
    },
    methods: {
        addModifyWatcher() {
            if (this.modifyWatcherAdded) {
                return;
            }
            const unwatch = this.$watch(
                dataPropertyName,
                () => {
                    if (JSON.stringify(this[dataPropertyName]) !== JSON.stringify(this[initialDataPropertyName])) {
                        this.modified = true;
                        if (unwatch) {
                            unwatch();
                            this.modifyWatcherAdded = false;
                        }
                    }
                },
                {
                    deep: true,
                }
            );
            this.modifyWatcherAdded = true;
        },
    },
});
