const Preloaders = {
    state: {},
    mutations: {
        updatePreloader(state, {name, status}) {
            state[name] = status;
        },
    },
    actions: {
        updatePreloader({commit}, {name, status}) {
            commit('updatePreloader', {name, status});
        },
    }
};

export {
    Preloaders
};
