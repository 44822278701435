<template src="../../templates/partner-cart-legal-details.html"></template>
<script>

import Error from '@c/Error';
import FormInput from '@f/Input';
import FormRadio from '@f/Radio';

export default {
    name: 'LegalDetails',
    components: {
        Error,
        FormInput,
        FormRadio,
    },
    props: {
        legalDetails: {
            type: Object,
            default() {
                return null;
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['update:errors'],
    data() {
        return {
            fields: [
                {
                    type: 'number',
                    key: 'kpp',
                    title: 'КПП',
                    maxlength: 12,
                },
                {
                    type: 'number',
                    key: 'okpo',
                    title: 'ОКПО',
                    maxlength: 12,
                },
                {
                    type: 'number',
                    key: 'okato',
                    title: 'ОКАТО',
                    maxlength: 12,
                },
                {
                    type: 'number',
                    key: 'ogrn',
                    title: 'ОГРН',
                    maxlength: 15,
                },
            ],
            fieldsRequired: [],
            detailsErrors: {},
            ndsTypes: {
                0: {id: 0, name: 'Без НДС'},
                1: {id: 1, name: 'С НДС'},
            }
        };
    },
    watch: {
        detailsErrors: {
            handler(v) {
                const newErrors = {};
                Object.keys(v).forEach(
                    k => {
                        if (v[k].length) {
                            newErrors[k] = [...v[k]];
                        }
                    }
                );
                this.$emit('update:errors', newErrors);
            },
            deep: true,
        }
    },
    mounted() {
        this.fields.forEach(f => { this.detailsErrors[f.key] = []; });
    },
};
</script>
