<template src="./templates/list.html"></template>

<script>

import Icon from './icon';
import Chevron from './chevron';

const default_values = {
    isChevron: false,
    isEditor: false,
    isSub: false,
    type: 'radio',
    class: '',
    labelClass: ''
};

const default_fields_of_list_item = {
    icon: null,
    wrapperClass: '',
    iconRight: null,
    url: null,
    children: [],
    attributes: []
};

export default {
    name: 'List',
    components: {
        Icon,
        Chevron
    },
    props: {
        action: {
            type: String,
            default() {
                return '';
            }
        },
        params: {
            type: Object,
            default() {
                return default_values;
            }
        },
        items: {
            type: [Object, Array],
            default() {
                return [];
            }
        },
        listItemAttributes: {
            type: Object,
            default() {
                return {};
            }
        },
        iconItemAttributes: {
            type: Object,
            default() {
                return {};
            }
        },
        customActions: {
            type: Array,
            default() {
                return [];
            },
        },

    },
    emits: ['click', 'icon-click', 'delete'],
    computed: {
        uniq_key() {
            return Math.floor(Math.random() * 100);
        },
        paramsPrep() {
            return {...default_values, ...this.params};
        },

        itemsPrep() {

            const prep_items = [];

            for (let i = 0; i < this.items.length; i++) {
                prep_items.push({...default_fields_of_list_item, ...this.items[i]});
            }

            return prep_items;
        },
    },
    methods: {
        getSubListClass(children) {
            const route = this.$route.path;
            const activeLink = children.map((item) => item.url === route);
            return (activeLink.includes(true)) ? '' : 'list--sub';
        },
        handleClick(id, action) {
            this.$emit('click', id, action);
        },
        formatUrl(itemUrl) {
            let result = itemUrl;

            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            const queryParams = params.toString();
            if (queryParams) {
                result += `?${queryParams}`;
            }

            return result;
        },
    }
};
</script>
