<template src="../../templates/partner-cart-contact-field.html"></template>
<script>

import FormInput from '@f/Input';
import FormButton from '@f/Button';
import Multiselect from '@vueform/multiselect';
import Error from '@c/Error';
import '@vueform/multiselect/themes/default.css';

export default {
    name: 'ContactField',
    components: {
        FormInput,
        FormButton,
        Multiselect,
        Error,
    },
    props: {
        detail: {
            type: Object,
            default() {
                return {
                    data: ''
                };
            }
        },
        contactTypes: {
            type: Object,
            default() {
                return null;
            }
        },
        onDelete: {
            type: Function,
            default() {
                return () => {};
            }
        },
        errors: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    emits: ['update:errors', 'update:detail'],
    data() {
        return {
            contactDataType: this.detail.contact_data_type_id,
            componentErrors: {
                contact_data: [],
                contact_data_type_id: [],
            },
        };
    },
    watch: {
        componentErrors: {
            deep: true,
            handler(v) {
                const newErrors = {};
                Object.keys(v).forEach(
                    k => {
                        if (v[k].length) {
                            newErrors[k] = [...v[k]];
                        }
                    }
                );
                this.$emit('update:errors', newErrors);
            }
        },
        contactDataType() {
            this.$emit('update:detail', {...this.detail, ...{contact_data_type_id: this.contactDataType}});
            this.validateContactDataType();
        },
    },
    mounted() {
        this.validateContactDataType();
    },
    methods: {
        validateContactDataType() {
            this.componentErrors.contact_data_type_id = [];
            if (!this.contactDataType) {
                this.componentErrors.contact_data_type_id.push('Выберите тип контактных данных');
            }
        },
    },
};
</script>
