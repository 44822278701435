<div v-if="message" :class="'border-dashed border-red mw-1 p-2 position-relative ' + boxClass">
	<template v-if="Array.isArray(message)">
		<template v-for="msg in message">
			<div v-html="msg" />
		</template>
	</template>
	<div v-else v-html="message" />
    <slot></slot>
    <Button
        v-if="onClose"
        :label="`<span class='icon close bg-80 m-0'></span>`"
        :attributes="{
            class: ['btn-sm', 'btn-white', 'p-1', 'position-absolute'],
            style: 'top:0; right:0'
        }"
        @click="onClose"
    />
</div>