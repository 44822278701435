import {createApp} from 'vue';
import VueTippy from 'vue-tippy';
import './layouts/default/styles/tippy.scss';
import App from './layouts/default';
import router from './router';
import store from './store';
import tools from './tools';

window.app = createApp(App)
        .use(router)
        .use(store)
        .use(tools)
        .use(VueTippy, {
            directive: 'tippy', // => v-tippy
            component: 'tippy', // => <tippy/>
        });

// TODO: удалить после обновления на vuejs 3.3
// https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
window.app.config.unwrapInjectedRef = true;

window.app.mount('#app');
