<template src="./templates/report-form.html"></template>

<script>
import Modal from '@c/Modal';

import './report-form.scss';

import Button from '@f/Button';
import FormSelect from '@f/Select';
import FormTextarea from '@f/Textarea';

import {
    getRequestServices,
    sendReportAction
} from '@api/report';

export default {
    name: 'ReportForm',
    components: {
        Button,
        FormSelect,
        FormTextarea,
        Modal,
    },
    data() {
        return {
            showModal: false,
            sendProcess: false,
            errorMessage: '',
            successMessage: '',
            request_service_id: null,
            services: [],
            message: '',

            validationErrors: {
                request_service_id: [],
                message: [],
            },
        };
    },
    async created() {
        await this.initRequestServices();
    },
    methods: {
        showModalHandler() {
            this.showModal = true;
        },
        async sendReportAction() {
            this.checkValidation();

            if (this.validationErrors.request_service_id.length) return;
            if (this.validationErrors.message.length) return;

            this.sendProcess = true;

            await sendReportAction({
                request_service_id: this.request_service_id,
                message: this.message,
                link: window.location.href,
            }).then((response) => {
                console.log(response);
                this.sendProcess = false;
                this.successMessage = response;

                setTimeout(() => {
                    this.showModal = false;
                    this.clearForm();
                }, 1500);

            }).catch((error) => {
                this.sendProcess = false;
                this.errorMessage = error;
            });
        },
        async initRequestServices() {
            this.services = await getRequestServices();
        },
        clearForm() {
            this.successMessage = '';
            this.errorMessage = '';
            this.message = '';
            this.request_service_id = null;
        },
        checkValidation() {
            this.validationErrors.request_service_id = !this.request_service_id ? [{
                condition: 'error',
                message: 'Выберите чат'
            }] : [];

            this.validationErrors.message = !this.message ? [{
                condition: 'error',
                message: 'Опишите проблему'
            }] : [];
        },
    }
};
</script>

