<div v-if="itemsTree">
    <FormCheckbox v-model="settings.status" label="Классификатор" />
    <template v-if="settings.status">
        <FormInput v-model="filter" placeholder="Поиск..." class="mt-2" />
        <div class="mt-2">
            <Tree
                v-model="settings.changeCategory"
                :nodes="itemsTree"
                :filter="filter"
                nodeKey="itemId"
            />
        </div>
    </template>
</div>
