<div class="preloader" v-if="preloader"></div>
<div :class="`form-group ${groupClass}`" v-else>
    <label v-if="label" :class="labelClassPrep" :for="attributesPrep.id">
        {{ label }} <Help v-if="helpText.length > 0" :text="helpText" />
    </label>
    <select :class="validBorder" v-bind="attributesPrep" @change="change">
        <option value="" :disabled="disableFirst">{{ placeholder }}</option>
        <option
            v-if="options"
            v-for="(option, index) in preparedOptions"
            :key="index"
            :value="option.value"
            :selected="selectedOption(option)"
            :style="option.mark ? `color: blue;` : ''"
        >
            {{ option.title }}
        </option>
    </select>
    <p class="error-message-list" v-if="errors.length">
        <ul v-for="error in errors">
            <li :class="error.condition">{{ error.message }}</li>
        </ul>
    </p>
</div>
