<div class="preloader" v-if="preloader"></div>
<div :class="'form-group' + groupClass" v-else>
	<label v-if="label" :class="labelClassPrep" :for="attributes.id">
        {{label}} <Help v-if="helpText.length > 0" :text="helpText" />
    </label>
    <textarea
        v-bind="attributesPrep"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value); resize($event)"
        ref="txarea"
    ></textarea>
    <p class="error-message-list" v-if="errors.length">
        <ul v-for="error in errors">
            <li :class="error.condition">{{ error.message }}</li>
        </ul>
    </p>
</div>
