<template src="../../templates/partner-cart-address-field.html"></template>
<script>

import FormInput from '@f/Input';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

export default {
    name: 'AddressField',
    components: {
        FormInput,
        Multiselect,
    },
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        },
        value: {
            type: String,
            default() {
                return '';
            }
        },
        placeholder: {
            type: String,
            default() {
                return 'Выбрать тип адреса';
            }
        },

        addressTypes: {
            type: Object,
            default() {
                return null;
            }
        }
    },
    emits: ['update:code', 'update:value'],
    data() {
        return {
            defaultCode: '',
            defaultValue: '',
        };
    },
    mounted() {
        this.defaultCode = this.code;
        this.defaultValue = this.value;
    },
    methods: {
        updateCode(code) {
            this.$emit('update:code', code);
        },
        updateValue() {
            this.$emit('update:value', this.defaultValue);
        },
    }
};
</script>
