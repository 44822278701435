<template src="../../templates/partner-cart-address.html"></template>
<script>

import FormButton from '@f/Button';
import FormInput from '@f/Input';
import Multiselect from '@vueform/multiselect';
import AddressField from './address-field';


export default {
    name: 'Address',
    components: {
        FormButton,
        FormInput,
        Multiselect,
        AddressField,
    },
    props: {
        address: {
            type: Object,
            default() {
                return {};
            }
        },
        addressTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        partnerAddressTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
        rootAreas: {
            type: Array,
            default() {
                return null;
            }
        },
        districtAreas: {
            type: Object,
            default() {
                return null;
            }
        },
        onDelete: {
            type: Function,
            default() {
                return () => {};
            }
        },
        onChangeRootAddress: {
            type: Function,
            default() {
                return () => {};
            }
        }
    },
    emits: ['update:address'],
    data() {
        return {
            fields: [
                'country',
                'zip',
                ['city_code', 'city'],
                ['street_code', 'street'],
                ['house_code', 'house'],
                ['building_code', 'building'],
                ['apartment_code', 'apartment'],
                'code'
            ],
            disabledFields: ['code'],
            titles: {
                country: 'Страна',
                zip: 'Почтовый индекс',
                lat_lon: 'Координаты',
                code: 'Код в 1С',
            }
        };
    },
    computed: {
        groupedAddressTypes() {
            const result = {};
            const items = Object.values(this.addressTypes);

            for (let i = 0; i < items.length; i++) {
                if (typeof result[items[i].type] === 'undefined') {
                    result[items[i].type] = [];
                }
                result[items[i].type].push(items[i]);
            }

            return result;
        }
    },
    methods: {
        getType(code) {
            let type = '';
            if (code && typeof this.addressTypes[code] !== 'undefined') {
                type = this.addressTypes[code].name;
            }
            return type;
        },
        getRegionName(id, regions) {
            if (!id || !regions || regions.length === 0) {
                return '';
            }
            const regs = regions.filter(r => r.id === `${id}`);
            return (regs.length) ? regs[0].name : null;
        },
        addContactField() {
            const {contact} = this;
            if (!contact.contactDetails) {
                contact.contactDetails = [];
            }
            contact.contactDetails.push({key: contact.contactDetails.length});
            this.$emit('update:address', contact);
        },
        deleteContactField(key) {
            const {contact} = this;
            contact.contactDetails = contact.contactDetails.filter((value, index) => index !== key);
            this.$emit('update:address', contact);
        },
    }
};
</script>
