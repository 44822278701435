<template src="../../templates/partner-cart-technology-park-estimated.html"></template>

<script>
import Grid from '@c/Grid';
import FormCheckbox from '@c/Form/Check';
import {getManufacturers, getProductMachineCategories} from '@api/technology-park';
import FormButton from '@f/Button';
import TechnologyParkClassificationBadge from './technology-park-classification-badge';

export default {
    name: 'TechnologyParkEstimated',
    components: {
        Grid,
        FormCheckbox,
        FormButton,
        TechnologyParkClassificationBadge,
    },
    props: {
        mode: { // display | edit | add
            type: String,
            required: true,
            default() {
                return 'display';
            }
        },
        modelLines: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    emits: ['add', 'update:additionalData'],
    data() {
        return {
            manufacturers: [],
            categories: [],
            selectedModelLines: {},
            selectAll: false,
        };
    },
    computed: {
        modelLinesData() {
            const result = [];
            this.modelLines.forEach(modelLine => {
                const category = this.getCategory(modelLine.categoryId);
                const manufacturer = this.getManufacturer(modelLine.manufacturerId);
                if (category && manufacturer) {
                    result.push({
                        categoryId: category.id,
                        categoryName: category.name,
                        modelLineId: modelLine.id,
                        modelLineName: modelLine.name,
                        manufacturerId: manufacturer.id,
                        manufacturerName: manufacturer.name,
                        classificationByProfit: modelLine.classificationByProfit,
                    });
                }
            });

            return result;
        },
        selectedModelLineIds() {
            const ids = [];
            Object.keys(this.selectedModelLines).forEach(key => {
                if (this.selectedModelLines[key]) {
                    ids.push(key);
                }
            });

            return ids;
        },
    },
    watch: {
        selectAll() {
            this.modelLines.forEach(modelLine => {
                this.selectedModelLines[modelLine.id] = this.selectAll;
            });
        },
    },
    async mounted() {
        const categoryIds = Array.from(this.modelLines, modelLine => modelLine.categoryId);
        const manufacturerIds = Array.from(this.modelLines, modelLine => modelLine.manufacturerId);

        const promises = [
            getManufacturers(
                null,
                null,
                manufacturerIds,
                response => {
                    this.manufacturers = response;
                }
            ),
            getProductMachineCategories(
                null,
                null,
                categoryIds,
                response => {
                    this.categories = response;
                }
            )
        ];
        await Promise.all(promises);
    },
    methods: {
        getModelLine(id) {
            return this.modelLines.find(modelLine => modelLine.id === id);
        },
        getManufacturer(id) {
            return this.manufacturers.find(manufacturer => manufacturer.id === id);
        },
        getCategory(id) {
            return this.categories.find(category => category.id === id);
        },
        add() {
            if (!this.selectedModelLineIds.length) {
                return;
            }

            const additionalData = {
                categories: this.categories,
                manufacturers: this.manufacturers,
                modelLines: this.modelLines,
                models: [],
            };

            const newItems = [];
            this.selectedModelLineIds.forEach(modelLineId => {
                newItems.push(
                    this.getNewItem(
                        this.getModelLine(Number(modelLineId))
                    )
                );
            });

            this.selectedModelLines = {};
            this.$emit('add', {newItems, additionalData});
        },
        getNewItem(modelLine) {
            return {
                id: null,
                count: 1,
                modelId: null,
                modelLineId: modelLine.id,
                manufacturerId: modelLine.manufacturerId,
                categoryId: modelLine.categoryId,
                serialNumber: null,
                year: null,
                note: 'Перенесено из расчетного парка техники',
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/technology-park.scss';
</style>
