<div  class="row-col mb-1" v-if="addressTypes">
	<div class="col-5 partner-title address-title">
		<Multiselect
			mode="single"
			v-model="defaultCode"
			:placeholder="placeholder"
			label="name"
			trackBy="name"
			valueProp="code"
			:searchable="false"
			:options='addressTypes'
			@change="updateCode"
		/>
	</div>
	<div  class="col-7">
		<FormInput v-model="defaultValue" @input="updateValue" />
	</div>
</div>