<div>
	<template v-if="mode==='display'">
		<div class="h4 mb-1">
			{{contact.last_name}} {{contact.first_name}} {{contact.middle_name}}
		</div>

		<div v-if="contactPositions && contact['position_id']" class="row-col mb-1">
			<div class="col-3 partner-title">
				Должность
			</div>
			<div  class="col-9">
				{{contactPositions[contact['position_id']].name}}
			</div>
		</div>
	</template>
	<template v-else-if="mode==='edit' || mode==='add'">

		<div class="row-col mb-2">
			<div class="col-3">
				ФИО
			</div>
			<div class="col-3">
				<FormInput
                    placeholder="Фамилия"
                    v-model="contact.last_name"
				/>
			</div>
			<div class="col-3">
				<FormInput
                    placeholder="Имя"
                    v-model="contact.first_name"
				/>
			</div>
			<div class="col-2">
				<FormInput
                    placeholder="Отчество"
                    v-model="contact.middle_name"
				/>
			</div>
			<div class="col-1">
				<FormButton
					type="a"
					:attributes="{class:['btn-red', 'm-auto', 'p-1', 'text-center']}"
					@click="onDelete"
				>
					<template v-slot:center>
						<span class="icon delete red m-auto"></span>
					</template>
				</FormButton>
			</div>
		</div>

		<div class="row-col mb-2" v-if="contactPositions">
			<div class="col-3">
				Должность
			</div>
			<div class="col-8">
				<Multiselect
					mode="single"
					v-model="contact['position_id']"
					placeholder="Выбрать должность"
					label="name"
					trackBy="name"
					valueProp="id"
					:searchable=true
					:options='Object.values(contactPositions)'
				/>
			</div>
		</div>
	</template>
 
	<div v-if="contactTypes" v-for="(detail, key) in contact.contactDetails" class="row-col mb-1">
		<template v-if="mode==='display'">
			<div class="col-3 partner-title">
				{{contactTypes[detail.contact_data_type_id].name}}
			</div>
			<div  class="col-9">
				{{detail.data}}
			</div>
		</template>
		<template v-else-if="mode==='edit' || mode==='add'">
			<ContactField
				:key="key"
				:contact-types="contactTypes"
				v-model:detail="contact.contactDetails[key]"
				:on-delete="()=>{deleteContactField(key);}"
                v-model:errors="componentsErrors[key]"
			/>
		</template>
	</div>

	<FormButton
			v-if="mode==='edit' || mode==='add'"
			label="+ Добавить поле"
			type="a"
			:attributes="{class:['text-blue', 'btn-link', 'mt-2', 'no-bg' ,'pl-0']}"
			@click="addContactField"
	/>
    
    <ContactActions v-if="mode==='display'" :actions="actions" :on-action="onActionHandler" />
</div>