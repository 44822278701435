<template src="../../templates/scenarios.html"></template>
<script>
import {
    getScenariosRates,
    calculateScenariosRates,
    getScenarioConditions,
    calculateProductsRates,
    getQuestions,
    getPartnersDataStatuses,
    updatePartnersScenarioData
} from '@api/scenarios';
import {
    getProductMachines
} from '@api/product';

import Checkbox from '@f/Check';
import ConditionEditor from '@c/ConditionEditor';
import VariableTree from '@c/Scenarios/VariableTree';
import Modal from '@c/Modal';
import EditVariable from '@page/VariableEditor/components/edit-variable';
import Confirm from '@c/Confirm';
import FormButton from '@f/Button';
import Tabs from '@c/Tabs';
import Grid from '@c/Grid';
import ExpansionItem from '@c/ExpansionItem';


export default {
    name: 'Scenarios',
    components: {
        ExpansionItem,
        Grid,
        Tabs,
        Checkbox,
        ConditionEditor,
        VariableTree,
        Modal,
        EditVariable,
        Confirm,
        FormButton,
    },
    props: {
        partnerId: {
            type: [Number, String],
            default() {
                return null;
            }
        },
    },
    data() {
        return {
            scenarioRatings: null,
            ratingsFromStore: null,
            scenarioConditions: [],
            scenarioQuestions: {},
            productRatings: null,
            actualProductIds: [],
            productDetails: null,
            actualScenarioIds: [],
            treeStatuses: {},
            calculationDetailsTreeStatuses: {},
            scenarioRules: {},
            partnersDataStatuses: null,
            partnersDataStatusUpdateError: null,
            rulesRulesTypeTabs: [
                {
                    id: 'productRatings',
                    slot_name: 'productRatings',
                    label: 'Рейтинги товаров',
                    width: 'minmax(100px, 3.33fr)',
                },
                {
                    id: 'scenarioCalculationResult',
                    slot_name: 'scenarioCalculationResult',
                    label: 'Рейтинг сценария',
                    width: 'minmax(120px, 3.33fr)',
                }
            ],
            productScenarioTabColumns: [
                {
                    id: 'productSelection',
                    slot_name: 'productSelection',
                    label: 'Рейтингование по ТТХ',
                    width: 'minmax(120px, 3.33fr)',
                },
                {
                    id: 'ruleSelection',
                    slot_name: 'ruleSelection',
                    label: 'Предусловие',
                    width: 'minmax(100px, 3.33fr)',
                },
            ],
            productCalculationDetails: {},
            dataType: {
                CORE_MANAGER: 'Общее обновление',
                Classifier: 'Импорт из классификатора WEB',
                PartnerType: 'Вычисление типа контрагента',
                DynamicValues: 'Динамические свойства',
                ScenarioRates: 'Рейтинг сценариев',
                ProductRates: 'Рейтинг Товаров',
            },
            dataStatuses: {
                DONE: 'Выполнено',
                PROCESSING: 'В процессе подготовки',
                IN_QUEUE: 'В очереди на вычисление',
                QUEUE_PROCESSING: 'Обновляется',
            },
        };
    },
    computed: {
        initStatuses() {
            const checkVariables = {
                scenarioRatings: 'Рассчет рейтингов сценариев:',
                ratingsFromStore: 'Рейтинги сценариев:',
                scenarioConditions: 'Детализация сценариев:',
                productRatings: 'Рассчет рейтингов товаров:',
                productDetails: 'Детализация товаров:',
            };
            const result = [];
            Object.keys(checkVariables).forEach(item => {
                result.push({
                    id: item,
                    title: checkVariables[item],
                    status: !!this[item] ? 'Загружено' : 'В процессе...',
                    color: !!this[item] ? 'green' : 'yellow',
                });
            });
            return result;
        },
        scenarios() {
            if (!this.scenarioRatings) { return null; }
            const result = this.scenarioRatings;
            return result.sort((a, b) => {
                if (a.scenario.published === b.scenario.published) {
                    return 0;
                }
                return (a.scenario.published < b.scenario.published) ? 1 : -1;
            });
        },
    },
    watch: {
        treeStatuses: {
            handler(value) {
                Object.keys(value).forEach(id => {
                    if (this.treeStatuses[id] === true) {
                        if (typeof this.scenarioConditions[id] === 'undefined') {
                            this.updateScenarioConditionsInfo(id);
                        }
                        if (typeof this.productCalculationDetails[id] === 'undefined') {
                            calculateProductsRates(
                                this.partnerId,
                                {
                                    scenarioIds: [id],
                                    calculateDetails: true,
                                },
                                r => {
                                    const productRatings = r[this.partnerId];
                                    this.productCalculationDetails[id] = {};
                                    this.calculationDetailsTreeStatuses[id] = {};
                                    Object.values(productRatings[id]).forEach(productCalculationDetails => {
                                        this.productCalculationDetails[id][productCalculationDetails.product_id] = productCalculationDetails;
                                        this.calculationDetailsTreeStatuses[id][productCalculationDetails.product_id] = false;
                                    });
                                }
                            );
                        }
                    }
                });
            },
            deep: true,
        },
        productRatings(productRatings) {
            const ratings = Object.values(productRatings);
            const ids = {};
            ratings.forEach(item => {
                item.forEach(point => {
                    ids[point.product_id] = point.product_id;
                });
            });
            this.actualProductIds = Object.values(ids);
        },
        actualProductIds(actualProductIds) {
            if (actualProductIds.length > 0) {
                getProductMachines({id: actualProductIds}, r => {
                    const result = {};
                    r.forEach(item => {
                        result[item.id] = {
                            id: item.id,
                            name: item.name,
                            model_id: item.model_id,
                            external_id: item.external_id,
                        };
                    });
                    this.productDetails = result;
                });
            }
        },
    },
    mounted() {
        this.initPartner(this.partnerId);
    },
    methods: {
        initPartner(partnerId) {
            this.scenarioRatings = null;

            getPartnersDataStatuses([partnerId], r => {
                if (typeof r[partnerId] !== 'undefined') {
                    this.partnersDataStatuses = r[partnerId];
                }
            });

            calculateProductsRates(partnerId, {}, r => {
                this.productRatings = r[partnerId];
            });

            calculateScenariosRates(partnerId, r => {
                this.scenarioRatings = r[partnerId];
                const scenariosIds = [];
                this.scenarioRatings.forEach(item => {
                    scenariosIds.push(item.scenario.id);
                    this.treeStatuses[item.scenario.id] = false;
                });
                this.actualScenarioIds = scenariosIds;
            });
            getScenariosRates(partnerId, r => {
                this.ratingsFromStore = r[partnerId];
            });
        },
        getPointClass(points) {
            switch (true) {
                case points >= 2:
                    return 'green';
                case points <= 0:
                    return 'red';
                case points > 0 && points < 2:
                    return 'yellow';
                default:
                    return '';
            }
        },
        async updateScenarioConditionsInfo(scenarioId) {
            await getQuestions(scenarioId, {
                questionFilter: {removed: 0},
                answerFilter: {removed: 0},
            }, r => {
                this.scenarioQuestions[scenarioId] = r;
            });
            await getScenarioConditions(scenarioId, r => {
                this.scenarioConditions[scenarioId] = r;
            });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
                day: 'numeric',
                month: 'numeric',
                year: '2-digit'
            };
            return `${date.toLocaleString('ru', options)} ${date.toLocaleTimeString('ru')}`;
        },
        calculateSumProductPoints(conditions, calculateDetails) {
            let sum = 0;

            if (conditions && calculateDetails) {
                Object.values(conditions).forEach(pool => {
                    if (
                        calculateDetails
                        && typeof calculateDetails.pools !== 'undefined'
                        && typeof calculateDetails.pools[pool.id] !== 'undefined'
                    ) {
                        sum += calculateDetails.pools[pool.id].points;
                    }
                });
            }
            return sum;
        },
        updatePartnerData() {

            this.partnersDataStatuses = null;

            updatePartnersScenarioData([this.partnerId], r => {
                if (r === 'done') {
                    this.initPartner(this.partnerId);
                } else {
                    this.partnersDataStatusUpdateError = r;
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
@import '../../styles/properties.scss';

.tree__node-item .condition {

}
</style>
