<template src="./templates/confirm.html"></template>
<script>

import Modal from '@c/Modal';
import FormButton from '@f/Button';

export default {
    name: 'Confirm',
    components: {
        Modal,
        FormButton,
    },
    props: {
        showModal: {
            type: Boolean,
            default() {
                return true;
            }
        },
        title: {
            type: String,
            default() {
                return 'Удалить элемент?';
            }
        },
        buttons: {
            type: Array,
            default() {
                return [
                    {
                        label: 'Да',
                        class: 'btn-red',
                        action: () => {}
                    },
                    {
                        label: 'Нет',
                        class: 'btn-green',
                        action: () => {}
                    },
                    {
                        label: 'Отмена',
                        action: () => {}
                    },
                ];
            }
        },
    },
    emits: ['update:showModal'],
    data() {
        return {};
    },
    methods: {
    }
};
</script>

