import api from '../tools/api';

export const getUserInfo = (
    params,
    successHandler = (e) => {},
    errorHandler = (e) => {}
) => api.request(
    'user',
    'get-user-info',
    {...params}
).then(successHandler).catch(errorHandler);

export const userLogin = (
    params,
    successHandler = (e) => {},
    errorHandler = (e) => {}
) => api.request(
    'user',
    'login',
    {...params}
).then(successHandler).catch(errorHandler);

export const getUserOffers = (user_id, page_number) => api.request(
    'user',
    'get-user-offers',
    {user_id, page_number}
);

export const getCountUserOffers = (user_id) => api.request(
    'user',
    'get-count-user-offers',
    {user_id}
);

export const updateQuestionnaireResult = (params) => api.request(
    'user',
    'update-questionnaire-result',
    {...params}
);

export const getManagersList = () => api.request(
    'user',
    'get-managers-list'
);
