<div class="tabs-content row pr-1 pv-1" xmlns="http://www.w3.org/1999/html"><!--v-if-->
    <div class="col-auto p-0">
        <div>
            <div class="border-dashed mb-2 p-2">
                <div>
                    <!--Поле Работа с НДС-->
                    <template v-if="mode==='display'">
                        <div v-if="legalDetails.nds !== null" class="row-col mb-1">
                            <div class="col-3 partner-title">Работа с НДС</div>
                            <div class="col-9">{{ndsTypes[legalDetails['nds']].name}}</div>
                        </div>
                    </template>
                    <template v-else-if="mode==='edit' || mode==='add'">
                        <div class="row-col mb-1">
                            <div class="col-3 partner-title">Работа с НДС</div>
                            <div class="col-5">
                                <template v-for="ndsType in ndsTypes">
                                    <FormRadio
                                        :label="ndsType.name"
                                        @click="legalDetails.nds = ndsType.id"
                                        :attributes="{checked: (legalDetails.nds === ndsType.id)}"
                                        wrapper-class="inline"
                                    />
                                </template>
                                <Error v-if="errors['nds']" :message="errors['nds']" box-class="ml-n1 mb-2" />
                            </div>
                        </div>
                    </template>
                    <!--Остальные поля: КПП, ОКПО и т.д.-->
                    <template v-for="field in fields">
                        <div class="row-col mb-1" v-if="legalDetails[field.key] && mode==='display'">
                            <div class="col-3 partner-title">{{field.title}}</div>
                            <div class="col-9">{{legalDetails[field.key]}}</div>
                        </div>
                        <div class="row-col mb-1" v-else-if="mode==='edit' || mode==='add'">
                            <div class="col-3 partner-title">{{field.title}} <template v-if="fieldsRequired.includes(field.key)">*</template></div>
                            <div class="col-5">
                                <FormInput
                                    :type="field.type"
                                    :required="fieldsRequired.includes(field.key)"
                                    v-model:errors="detailsErrors[field.key]"
                                    v-model="legalDetails[field.key]"
                                    :maxlength=field.maxlength
                                />
                                <Error v-if="errors[field.key] && errors[field.key].length" :message="errors[field.key]" box-class="ml-n1 mb-2" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>
