<template src="../templates/grid-cell.html"></template>

<script>
import Button from '@f/Button';

export default {
    name: 'GridCell',
    components: {
        Button
    },
    props: {
        id: {
            type: [String, Number],
            default: () => 0,
        },
        questions: {
            type: Array,
            default: () => [],
        },
        tag: {
            type: String,
            default() {
                return 'td';
            }
        },
        width: {
            type: String,
            default: () => '30%',
        },
        buttonEdit: {
            type: Boolean,
            default: () => false,
        },
        linkButtons: {
            type: Boolean,
            default: () => false,
        },
        addButtons: {
            type: Boolean,
            default: () => false,
        },
        buttonRemove: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['onEdit', 'onRemove', 'onLink'],
    methods: {
        callEvent(id, action, e) {
            e.stopPropagation();
            this.$emit(action, id);
        }
    }
};
</script>
