<li class="tree__node-item">
    <div
        class="tree__level row"
    >
        <div class="tree__level-btns">
            <ToggleButton
                v-model="editedItem.operator"
                :options="[
                    {
                        label: 'И',
                        value: 'AND',
                    },
                    {
                        label: 'Или',
                        value: 'OR',
                    },
                ]"
                :disabled="disabled"
            />


            <slot
                name="actions"
                :data="{addChildItem, removeHandler, item}"
            ></slot>

            <slot name="extraPoolData" :data="item"></slot>
        </div>

        <AddLine
            v-if="!editedItem.conditions.length && !disabled"
            @click="addItemCondition(editedItem.conditions.length - 1)"
        />

    </div>
    <ul class="tree__node-group" v-if="editedItem.pools.length || editedItem.conditions.length">
        <Item
            v-for="(childItem, childItemIndex) in editedItem.pools"
            v-model:item="editedItem.pools[childItemIndex]"
            :item-index="childItemIndex"
            :removeHandler="removeItem.bind(childItemIndex)"
            :key="childItem.id || childItem.tmpKey"
            :disabled="disabled"
        >
            <template v-slot:actions="itemData">
                <slot name="actions" :data="itemData.data"></slot>
            </template>
            <template v-slot:extraPoolData="poolData">
                <slot name="extraPoolData" :data="poolData.data"></slot>
            </template>
            <template v-slot:extraConditionData="itemData">
                <slot name="extraConditionData" :data="itemData.data"></slot>
            </template>
        </Item>
        <Condition
            v-for="(itemCondition, itemConditionIndex) in editedItem.conditions"
            v-model:condition="editedItem.conditions[itemConditionIndex]"
            :key="itemCondition.id || itemCondition.tmpKey"
            :disabled="disabled"
        >
            <template v-if="!disabled" v-slot:addSlot>
                <AddLine @click="addItemCondition(itemConditionIndex)" />
            </template>

            <FormButton
                v-if="!disabled"
                :attributes="{class: ['btn-red', 'p-1', 'text-center']}"
                @click.prevent="removeItemCondition(itemConditionIndex)"
                :style="`margin: 24px 0 0 0;`"
            >
                <template v-slot:center>
                    <span class="icon delete red m-auto"></span>
                </template>
            </FormButton>

            <slot name="extraConditionData" :data="itemCondition"></slot>
        </Condition>
    </ul>
</li>
