<ExpansionItem
        v-model="isExpanded"
        :label="node[labelKey]"
        :header-class="{
            'tree-item': true,
            'tree-item__selected': isSelected(node),
        }"
        :class="{'ml-3': level > 0}"
        :expand-icon="hasChildren(node) ? expandIcon : ''"
        :expanded-icon="hasChildren(node) ? expandedIcon : ''"
        :level="level"
        @header-click="select(node)"
>
    <template v-slot:label>
        <slot name="label" :data="node"></slot>
    </template>
    
    <TreeItem
        v-for="childNode in node[childrenKey]"
        :key="childNode[nodeKey]"
        :node="childNode"
        :node-key="nodeKey"
        :label-key="labelKey"
        :children-key="childrenKey"
        :level="level + 1"
        :selected="selected"
        :expanded="expanded"
        :expand-icon="expandIcon"
        :expanded-icon="expandedIcon"
        @select="select"
    >
        <template v-slot:label="row">
            <slot name="label" :data="row.data"></slot>
        </template>
    </TreeItem>
</ExpansionItem>
