<template src="../../templates/partner-cart-contacts.html"></template>
<script>

import Contact from './contact';

export default {
    name: 'Contacts',
    components: {
        Contact,
    },
    props: {
        contacts: {
            type: Array,
            default() {
                return [];
            }
        },
        contactTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        contactPositions: {
            type: Object,
            default() {
                return {};
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        actions: {
            type: Object,
            default() {
                return {};
            }
        },
        onAction: {
            type: Function,
            default() {
                return () => {};
            }
        }
    },
    emits: ['update:contacts', 'update:errors'],
    data() {
        return {
            componentsErrors: {}
        };
    },
    watch: {
        componentsErrors: {
            deep: true,
            handler(v) {
                this.$emit('update:errors', this.prepErrors(v));
            }
        }
    },
    methods: {
        addContact() {
            const {contacts} = this;
            contacts.push({});
            this.$emit('update:contacts', contacts);
        },
        deleteContact(key) {
            let {contacts} = this;
            contacts = contacts.filter((value, index) => {
                if (index === key) {
                    delete this.componentsErrors[key];
                    return false;
                }
                return true;
            });
            this.$emit('update:contacts', contacts);
        },
        prepErrors(v) {
            const errors = {};
            Object.keys(v).forEach(k => {
                if (Object.values(v[k]).length > 0) {
                    errors[k] = v[k];
                }
            });
            
            return errors;
        }
    },
};
</script>
