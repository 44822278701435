<form id="login-form" action="#" class="row c mt-4 flex-direction-column">
	<div class="row-col">
		<div class="col-2">
			<Input
					label="Email / Логин 1С"
					:attributes="{name:'email',type:'email',placeholder:'supermail@gmail.com'}"
			/>
		</div>
	</div>
	<div class="row-col mt-1">
		<div class="col-2">
			<Input
					label="Пароль"
					:attributes="{name:'password',type:'password',placeholder:'Введите пароль'}"
			/>
		</div>
	</div>
   <div v-if="error">
      <p class="error-block" style="color: red">Не верный Email  или пароль</p>
   </div>
	<div class="row-col mt-2">
		<div class="col-2">
			<Button label="Авторизоваться" :attributes="{type:'submit',class:'btn-blue'}" @click="login" />
		</div>
	</div>
</form>
