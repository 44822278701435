<template src="../../templates/partner-cart-technology-park.html"></template>

<script>
import helpers from '@/tools/helpers';
import ExpansionItem from '@c/ExpansionItem';
import Error from '@c/Error';
import FormButton from '@f/Button';
import {nextTick} from 'vue';
import {
    getManufacturers, getModelLines, getModels, getProductMachineCategories
} from '@api/technology-park';
import Loader from '@c/Loader';
import TechnologyParkItem from './technology-park-item';
import TechnologyParkAdd from './technology-park-add';
import TechnologyParkRecommended from './technology-park-recommended';

export default {
    name: 'TechnologyPark',
    components: {
        Error,
        ExpansionItem,
        FormButton,
        TechnologyParkItem,
        TechnologyParkAdd,
        TechnologyParkRecommended,
        Loader,
    },
    props: {
        mode: { // display | edit | add
            type: String,
            required: true,
            default() {
                return 'display';
            }
        },
        partnerId: {
            type: [Number, String],
            required: true,
            default() {
                return null;
            }
        },
        technologyPark: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['update:technologyPark', 'update:errors'],
    data() {
        return {
            loading: false,
            componentErrors: {},
            additionalData: {
                categories: [],
                manufacturers: [],
                modelLines: [],
                models: [],
            },
            categoriesShowing: {},
            modelLinesShowing: {},
        };
    },
    computed: {
        lastUpdateDate() {
            const lastDate = Math.max(
                ...this.technologyPark.map(item => item.updatedAt)
            );

            return lastDate ? helpers.unixTimestampToLocaleDate(lastDate) : null;
        },
        categories() {
            const categories = [];
            this.technologyPark.forEach(item => {
                let foundCategory = categories.find(cat => cat.id === item.categoryId);
                if (foundCategory === undefined) {
                    const len = categories.push({
                        id: item.categoryId,
                        count: Number(item.count),
                        modelLines: [],
                    });
                    foundCategory = categories[len - 1];
                } else {
                    foundCategory.count += Number(item.count);
                }

                const foundModelLine = foundCategory.modelLines.find(
                    modelLine => modelLine.id === item.modelLineId
                );
                if (foundModelLine === undefined) {
                    foundCategory.modelLines.push({
                        id: item.modelLineId,
                        count: Number(item.count),
                        items: [item],
                    });
                } else {
                    foundModelLine.count += Number(item.count);
                    foundModelLine.items = [...foundModelLine.items, item];
                }
            });

            categories.sort((a, b) => {
                const categoryA = this.getCategory(a.id);
                const categoryB = this.getCategory(b.id);
                if (categoryA === undefined) {
                    return 1;
                }
                if (categoryB === undefined) {
                    return -1;
                }
                const nameA = categoryA.name.toUpperCase();
                const nameB = categoryB.name.toUpperCase();
                return nameA.localeCompare(nameB);
            });

            return categories;
        },
    },
    watch: {
        componentErrors: {
            handler(newValue) {
                const errors = {};
                Object.keys(newValue).forEach(key => {
                    if (newValue[key].length > 0) {
                        errors[key] = newValue[key];
                    }
                });
                this.$emit('update:errors', errors);
            },
            deep: true,
        },
        technologyPark: {
            handler() {
                this.getAdditionalData();
                this.technologyPark.forEach(item => {
                    if (!Object.hasOwn(this.categoriesShowing, item.categoryId)) {
                        this.categoriesShowing[item.categoryId] = false;
                    }
                    if (!Object.hasOwn(this.modelLinesShowing, item.modelLineId)) {
                        this.modelLinesShowing[item.modelLineId] = true;
                    }
                });
            },
            immediate: true,
        },
    },
    methods: {
        getAdditionalData() {
            const {
                categoryIds,
                manufacturerIds,
                modelLineIds,
                modelIds
            } = this.getIdsForFilter();

            const promises = [];

            if (categoryIds.length) {
                promises.push(
                    getProductMachineCategories(null, null, categoryIds)
                        .then(response => {
                            this.additionalData.categories = [...this.additionalData.categories, ...response];
                        })
                );
            }
            if (manufacturerIds.length) {
                promises.push(
                    getManufacturers(null, null, manufacturerIds)
                        .then(response => {
                            this.additionalData.manufacturers = [...this.additionalData.manufacturers, ...response];
                        })
                );
            }
            if (modelLineIds.length) {
                promises.push(
                    getModelLines(null, null, modelLineIds)
                        .then(response => {
                            this.additionalData.modelLines = [...this.additionalData.modelLines, ...response];
                        })
                );
            }
            if (modelIds.length > 0) {
                promises.push(
                    getModels(null, modelIds)
                        .then(response => {
                            this.additionalData.models = [...this.additionalData.models, ...response];
                        })
                );
            }

            if (promises.length) {
                this.loading = true;
                Promise.all(promises)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        getIdsForFilter() {
            const categoryIds = Array.from(
                this.technologyPark.filter(
                    item => !this.additionalData.categories.find(category => category.id === item.categoryId)
                ),
                item => item.categoryId
            );
            const manufacturerIds = Array.from(
                this.technologyPark.filter(
                    item => !this.additionalData.manufacturers.find(manufacturer => manufacturer.id === item.manufacturerId)
                ),
                item => item.manufacturerId
            );
            const modelLineIds = Array.from(
                this.technologyPark.filter(
                    item => !this.additionalData.modelLines.find(modelLine => modelLine.id === item.modelLineId)
                ),
                item => item.modelLineId
            );
            const modelIds = Array.from(
                this.technologyPark.filter(
                    item => item.modelId !== null && !this.additionalData.models.find(model => model.id === item.modelId)
                ),
                item => item.modelId
            );

            return {
                categoryIds,
                manufacturerIds,
                modelLineIds,
                modelIds
            };
        },
        getCategory(id) {
            return this.additionalData.categories.find(category => category.id === id);
        },
        getModelLine(id) {
            return this.additionalData.modelLines.find(modelLine => modelLine.id === id);
        },
        addItems({newItems, additionalData}) {
            this.updateAdditionalData(additionalData);

            const park = this.technologyPark.map(item => ({...item}));
            newItems.forEach(newItem => {
                park.push(newItem);
                this.categoriesShowing[newItem.categoryId] = true;
                this.categoriesShowing[newItem.modelLineId] = true;
            });
            this.$emit('update:technologyPark', park);
            this.scrollToItem(newItems[0]);
        },
        updateAdditionalData(data) {
            Object.keys(data).forEach(key => {
                data[key].forEach(item => {
                    if (this.idNotExistInArray(this.additionalData[key], item.id)) {
                        this.additionalData[key].push(item);
                    }
                });
            });
        },
        idNotExistInArray(array, id) {
            return array.find(item => id === item.id) === undefined;
        },
        deleteItem(itemToDelete) {
            const filterCallback = item => !(
                item.id === itemToDelete.id
                    && item.modelId === itemToDelete.modelId
                    && item.modelLineId === itemToDelete.modelLineId
                    && Number(item.year) === Number(itemToDelete.year)
                    && String(item.serialNumber) === String(itemToDelete.serialNumber)
            );

            const park = this.technologyPark
                .filter(filterCallback)
                .map(item => ({...item}));

            this.$emit('update:technologyPark', park);
        },
        showHideAll(show) {
            Object.keys(this.categoriesShowing).forEach(key => {
                this.categoriesShowing[key] = show;
            });
            Object.keys(this.modelLinesShowing).forEach(key => {
                this.modelLinesShowing[key] = show;
            });
        },
        async scrollToItem(item) {
            await nextTick();
            const element = document.getElementById(this.getItemSelector(item));
            if (element !== null) {
                element.scrollIntoView({block: 'center', behavior: 'smooth'});
            }
        },
        getItemSelector(item) {
            return `${item.modelLineId}${item.modelId}${item.year}${item.serialNumber}`;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../styles/technology-park';
</style>
