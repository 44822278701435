<template src="./templates/select-partner.html"></template>
<script>

import './styles/select-partner.scss';
import Filter from '@/entities/filter';
import eventBus from '@/entities/eventBus';
import FormButton from '@f/Button';
import filterHelper from '@/entities/filter_helper';
import Modal from '@c/Modal';
import PartnerCart from '@page/PartnerList/components/partner-cart';

export default {
    name: 'SelectPartner',
    components: {
        FormButton,
        Modal,
        PartnerCart,
    },
    data() {
        return {
            partner: null,
            showPartnerCard: false,
            get globalFilter() {
                return new Filter();
            },
        };
    },
    created() {
        const p = this.globalFilter.getGroup('partner') || {};
        if (
            JSON.stringify(p) !== JSON.stringify(this.partner)
        ) {
            this.partner = p;
        }

        eventBus.$on('event-partner-selected', partner => {
            this.partner = partner;
        });
    },
    beforeUnmount() {
        eventBus.$off('event-partner-selected');
    },
    methods: {
        clearPartner() {
            filterHelper.removeSelectedPartner();
        },
        openPartnerCard() {
            this.showPartnerCard = true;
        },
        closePartnerCard() {
            this.showPartnerCard = false;
        },
    }
};
</script>
