<template src="../../templates/partner-cart-technology-park-item.html"></template>

<script>
import {mapGetters} from 'vuex';
import FormButton from '@f/Button';
import FormInput from '@f/Input';
import Icon from '@c/List/icon';
import helpers from '@/tools/helpers';
import Error from '@c/Error';
import TechnologyParkClassificationBadge from './technology-park-classification-badge';

export default {
    name: 'TechnologyParkItem',
    components: {
        FormButton,
        FormInput,
        Icon,
        Error,
        TechnologyParkClassificationBadge,
    },
    props: {
        mode: { // display | edit | add
            type: String,
            required: true,
            default() {
                return 'display';
            }
        },
        item: {
            type: Object,
            required: true,
        },
        technologyPark: {
            type: Array,
            default() {
                return [];
            }
        },
        additionalData: {
            type: Object,
            default() {
                return {
                    categories: [],
                    manufacturers: [],
                    modelLines: [],
                    models: [],
                };
            },
            required: true,
        },
        componentErrors: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    emits: ['delete', 'update:componentErrors'],
    data() {
        return {
            constants: Object.freeze({
                A: 'A',
                B: 'B',
            }),
        };
    },
    computed: {
        ...mapGetters([
            'userSurnameWithInitials',
        ]),
        category() {
            return this.additionalData.categories.find(category => category.id === this.item.categoryId);
        },
        manufacturer() {
            return this.additionalData.manufacturers.find(manufacturer => manufacturer.id === this.item.manufacturerId);
        },
        modelLine() {
            return this.additionalData.modelLines.find(modelLine => modelLine.id === this.item.modelLineId);
        },
        model() {
            return this.additionalData.models.find(model => model.id === this.item.modelId);
        },
        image() {
            let path = '';
            if (this.model?.image) {
                path = this.model?.image;
            } else if (this.modelLine?.image) {
                path = this.modelLine?.image;
            }

            return path ? `${process.env.VUE_APP_HOST_URL}${path}` : null;
        },
        pathToPartsCatalog() {
            if (!this.modelLine?.path) {
                return null;
            }

            return `${process.env.VUE_APP_HOST_URL}/${this.modelLine?.path}`;
        },
        lastUpdateDate() {
            return this.item?.updatedAt
                ? helpers.unixTimestampToLocaleDate(this.item.updatedAt)
                : (new Date()).toLocaleDateString();
        },
        isNewItem() {
            return !this.item?.id;
        },
    },
    watch: {
        item: {
            handler() {
                this.validate();
            },
            deep: true,
        },
        technologyPark() {
            this.validate();
        }
    },
    methods: {
        validate() {
            if (this.mode === 'display') {
                return;
            }

            const componentErrors = [];

            if (!this.item.count || parseInt(this.item.count, 10) === 0) {
                componentErrors.push('Укажите количество техники.');
            }

            if (this.item.year) {
                const year = parseInt(this.item.year, 10);
                const yearNow = (new Date()).getFullYear();
                if (year < 1950 || year > yearNow) {
                    componentErrors.push('Введите корректный год выпуска.');
                }
            }

            if (!this.item?.id) {
                const entries = this.technologyPark.filter(
                    item => item.modelLineId === this.item.modelLineId
                        && item.modelId === this.item.modelId
                        && Number(item.year) === Number(this.item.year)
                        && String(item.serialNumber) === String(this.item.serialNumber)
                );
                if (entries.length > 1) {
                    componentErrors.push('Уже есть модель с таким серийным номером и годом!');
                }
            }

            this.$emit('update:componentErrors', componentErrors);
        },
        deleteItem() {
            this.$emit('update:componentErrors', []);
            this.$emit('delete', this.item);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../styles/technology-park.scss';
</style>
