<template src="./templates/modal-container.html"></template>

<script>

    import Button from '@f/Button';
    import '../../layouts/default/styles/modal.scss';

    export default {
        name: 'ModalContainer',
        components: {
            Button
        },
        props: {
            closeButton: {
                type: Boolean,
                default() {
                    return true;
                }
            },
            buttons: {
                type: Array,
                default() {
                    return [];
                }
            },
            templateButtons: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        emits: ['onClose', 'onOk'],
        methods: {
            callEvent(action, e) {
                e.stopPropagation();
                this.$emit(action);
            }
        }
    };
</script>

