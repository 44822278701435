<template src="./templates/layout.html"></template>

<script>

import FormButton from '@f/Button';
import List from '@c/List';
import Modal from '@c/Modal';
import Login from '@page/Login/components/Login';
import ReportForm from '@c/ReportForm';
import {Catalogs} from '@store/catalogs';
import {defaultRoute} from '@/router/config';
import filterHelper from '@/entities/filter_helper';
import eventBus from '@/entities/eventBus';

// TODO: move styles inside crm project
import '../../../../../app/assets/src/crm/style/styles.scss';
import './styles/cursor.scss';
import './styles/backgrounds.scss';
import './styles/btn.scss';
import './styles/block.scss';
import './styles/input.scss';
import './styles/text.scss';
import './styles/font.scss';
import {getLeftMenu} from '@api/layout';
import {getUserInfo} from '@api/user';
import {LeftMenuStore} from './stores/leftmenu';
import SelectPartner from './SelectPartner';


export default {
    name: 'App',
    components: {
        List,
        Login,
        Modal,
        FormButton,
        ReportForm,
        SelectPartner,
    },
    data() {
        return {
            showModal: false,
            user_menu_items: [
                // {
                // 	id: '',
                // 	icon: 'user',
                // 	title: 'Профиль',
                // 	name: '',
                // 	description: '',
                // 	url: '#to_my_profile',
                // 	children: [],
                // 	options: [],
                // 	priority: 0,
                // 	parser: {}
                // },
                // {
                // 	id: '',
                // 	icon: 'settings',
                // 	title: 'Настройки',
                // 	name: '',
                // 	description: '',
                // 	url: '#to_my_settings',
                // 	children: [],
                // 	options: [],
                // 	priority: 0,
                // 	parser: {}
                // },
                // {
                // 	id: '',
                // 	icon: 'log-out',
                // 	title: 'Выйти',
                // 	name: '',
                // 	description: '',
                // 	url: '#exit',
                // 	children: [],
                // 	options: [],
                // 	priority: 0,
                // 	parser: {}
                // },
            ]
        };
    },

    computed: {
        currentPathLogin() {
            return this.$route.path === '/login';
        },
        userKey() {
            if (this.$store.state.User.key) {
                getUserInfo(
                    {key: this.$store.state.User.key},
                    response => {
                        this.$store.dispatch('updateUserInfo', response);
                    },
                    e => {
                        console.error(e);
                        window.localStorage.removeItem('user-key');
                        this.$store.dispatch('updateUserKey', null);
                    }
                );
            }

            return this.$store.state.User.key;
        },
        user_info() {
            return this.$store.state.User.info;
        },

        menu_items() {
            return this.$store.state.LeftMenu.menu_items;
        }
    },

    async created() {

        const userKey = window.localStorage.getItem('user-key');

        if (userKey) {
            // если есть юзер, и мы в корне или на логине, редиректим на дефолтный роут
            if (['/', '/login'].includes(window.location.pathname)) {
                await this.$router.push(defaultRoute);
            }
            this.$store.dispatch('updateUserKey', userKey);
            this.updateMenu(await getLeftMenu());

            this.$store.dispatch('initBranchList');
        } else if (
            (
                !this.$store.state.User.info
                || typeof this.$store.state.User.info.id === 'undefined'
            )
            && !['/', '/login'].includes(window.location.pathname)
        ) {
            await this.$router.push({name: 'Login', query: {redirect: window.location.pathname}});
        }

        eventBus.$on('logout', () => {
            filterHelper.removeSelectedPartner();
            this.$router.go();
        });
    },
    beforeUnmount() {
        eventBus.$off('logout');
    },

    async beforeCreate() {
        this.$store.registerModule('Catalogs', Catalogs);
        this.$store.registerModule('LeftMenu', LeftMenuStore);
    },

    update() {

    },
    methods: {
        logout() {
            window.localStorage.removeItem('user-key');
            this.$store.dispatch('updateUserKey', null);
            eventBus.$emit('logout');
        },
        updateMenu(menu_items) {
            this.$store.dispatch('updateMenu', menu_items);
        }
    }
};
</script>

<!--<style src="./styles/styles.css"></style>-->
