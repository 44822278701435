<template src="../templates/tree-item.html"></template>

<script>
import ExpansionItem from '@c/ExpansionItem';

export default {
    name: 'TreeItem',
    components: {
        ExpansionItem,
    },
    props: {
        // Идентификатор выбранной строки
        selected: {
            type: [Number, String],
            default: null,
        },
        // Строка дерева
        node: {
            type: Object,
            default() {
                return {};
            },
            required: true,
        },
        // В каком свойстве хранится уникальный идентификатор строки дерева (эмитится в selected при выборе)
        nodeKey: {
            type: String,
            default: 'id',
        },
        // В каком свойстве хранится label для отображения
        labelKey: {
            type: String,
            default: 'label',
        },
        // В каком свойстве хранится список детей
        childrenKey: {
            type: String,
            default: 'children',
        },
        // Уровень вложенности строки
        level: {
            type: Number,
            default: 0,
        },
        // Строка свёрнута или развернута
        expanded: {
            type: Boolean,
            default: false,
        },
        // Иконка для свёрнутой строки дерева
        expandIcon: {
            type: String,
            default: 'plus-two-ton',
        },
        // Иконка для развернутой строки дерева
        expandedIcon: {
            type: String,
            default: 'minus-two-ton',
        },
    },
    emits: ['select'],
    data() {
        return {
            isExpanded: this.expanded,
        };
    },
    watch: {
        expanded() {
            this.isExpanded = this.expanded;
        }
    },
    mounted() {
        const childrenSelected = nodes => {
            let result = false;
            nodes.some(node => {
                if (this.isSelected(node)) {
                    result = true;
                } else if (this.hasChildren(node)) {
                    result = childrenSelected(node[this.childrenKey]);
                }
                return result;
            });
            return result;
        };

        if (this.hasChildren(this.node)) {
            this.isExpanded = childrenSelected(this.node[this.childrenKey]);
        }
    },
    methods: {
        select(node) {
            this.$emit('select', node);
        },
        isSelected(node) {
            return node[this.nodeKey] === this.selected;
        },
        hasChildren(node) {
            return Boolean(node?.[this.childrenKey]?.length);
        },
    },
};
</script>

<style lang="scss">
@import '../styles/tree-item';
</style>
