<div
    v-if="type === constants.VALUE_TYPE_DECIMAL || type === constants.VALUE_TYPE_STRING"
    class="edit-variable-value-container"
>
    <div class="row-col"><label class="h6">Значения</label></div>

    <div class="row-col">
        <div class="col ph-0">
            <add-line class="mw-1" @click="add(-1)" />
            <template v-for="(variable, key) in editValues">
                <div class="row-col pt-2 pb-2">
                    <div class="col-5">
                        <form-input
                            v-model="variable.value"
                            :type="inputType"
                            required
                            class="mb-1"
                            placeholder="Значение"
                        />
                    </div>
                    <div class="col-6">
                        <form-input class="mb-1" v-model="variable.name"  placeholder="Подсказка (необязательно)" />
                    </div>
                    <div class="col-1">
                        <form-button
                            type="a"
                            class="btn btn-red p-1 text-center"
                            @click="remove(key)"
                        >
                            <template v-slot:center>
                                <span class="icon bg-80 delete red m-auto"></span>
                            </template>
                        </form-button>
                    </div>
                </div>
                <add-line class="mb-1" @click="add(key)" />
            </template>
        </div>
    </div>

    <div v-if="errors.length" class="row-col">
        <div class="col ph-0">
            <Error v-for="(error, key) in errors" :key="key" :message="error" />
        </div>
    </div>
</div>
