<template v-if="contactTypes">
	<div class="col-3">
		<Multiselect
				mode="single"
				key="dContactTypesKey"
				v-model="contactDataType"
				placeholder="Выбрать тип"
				label="name"
				trackBy="name"
				valueProp="id"
				:searchable="false"
				:options='Object.values(contactTypes)'
		/>
	</div>
	<div  class="col-8">
		<FormInput
			:type="detail.contact_data_type_id ? contactTypes[detail.contact_data_type_id].type : 'text'"
			v-model="detail.data"
            v-model:errors="componentErrors.contact_data"
            :required="true"
		/>
        <template v-for="error in componentErrors">
            <Error v-if="error.length" :message="error" />
        </template>
	</div>
	<div  class="col-1">
		<FormButton
			type="a"
			:attributes="{class:['btn-red', 'm-auto', 'p-1', 'text-center']}"
			@click="onDelete"
		>
			<template v-slot:center>
				<span class="icon delete red m-auto"></span>
			</template>
		</FormButton>
	</div>

</template>
