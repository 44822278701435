<transition name="modal">
    <div class="modal-mask">
        <div class="modal-wrapper" @click.self.prevent="callEvent('on-close', $event)">
            <div class="modal-container">
                <div class="modal-close" v-if="closeButton">
                    <Button
                        :label="`<span class='icon close bg-80 m-0'></span>`"
                        :attributes="{
                            'class': ['btn-sm', 'btn-white', 'p-1']
                        }"
                        @click="callEvent('on-close', $event)"
                    />
                </div>
                <div class="modal-header ph-3 pv-3" v-if="$slots.header">
                    <slot name="header"></slot>
                </div>

                <div class="modal-body">
                    <slot v-if="$slots.body" name="body" />
                    <slot v-else />
                </div>

                <div class="modal-footer ph-3 pv-3" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>

                <div class="modal-buttons row-col" :class="templateButtons.class">
                    <div class="col">
                        <template v-for="btn in buttons">
                            <Button :label="btn.label" :attributes="{class:[btn.class]}" @click="callEvent(btn.event, $event)" />
                        </template>
                    </div>
                    <slot name="buttons" v-if="$slots.buttons"></slot>
                </div>
            </div>
        </div>
    </div>
</transition>
