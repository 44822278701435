<template src="./templates/tree.html"></template>

<script>
import TreeItem from '@c/Tree/components/tree-item';

export default {
    name: 'Tree',
    components: {
        TreeItem,
    },
    props: {
        // Идентификатор выбранной строки
        modelValue: {
            type: [Number, String],
            default: null,
        },
        // Дерево
        nodes: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: 'Root1',
                        children: [
                            {
                                id: 3,
                                name: 'Chil11',
                                children: [],
                            },
                            {
                                id: 4,
                                name: 'Chil12',
                                children: [
                                    {
                                        id: 5,
                                        name: 'Chil31',
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 2,
                        name: 'Root2',
                        children: [],
                    },
                    {
                        id: 3,
                        name: 'Root3',
                        children: [],
                    },
                ];
            },
        },
        // В каком свойстве хранится уникальный идентификатор строки дерева (эмитится в modelValue при выборе)
        nodeKey: {
            type: String,
            default: 'id',
        },
        // В каком свойстве хранится label для отображения
        labelKey: {
            type: String,
            default: 'name',
        },
        // В каком свойстве хранится список детей
        childrenKey: {
            type: String,
            default: 'children',
        },
        // Строка для фильтрации дерева
        filter: {
            type: String,
            default: '',
        },
        // Метод для фильтрации дерева: (node, filter) => Boolean
        filterMethod: {
            type: Function,
            default: null,
        },
        // Иконка для свёрнутой строки дерева
        expandIcon: {
            type: String,
            default: 'plus-two-ton',
        },
        // Иконка для развернутой строки дерева
        expandedIcon: {
            type: String,
            default: 'minus-two-ton',
        },
    },
    emits: ['update:modelValue'],
    computed: {
        filteredNodes() {
            if (this.filter.length || this.filterMethod !== null) {
                return this.getFilteredNodes();
            }
            return this.nodes;
        },
    },
    methods: {
        onSelect(node) {
            this.$emit('update:modelValue', node[this.nodeKey]);
        },
        getFilter() {
            if (this.filterMethod !== null) {
                return this.filterMethod;
            }
            return (node, filter) => (node[this.labelKey].toLowerCase().includes(filter.toLowerCase()));
        },
        getFilteredNodes() {
            const callback = this.getFilter();
            const getChildren = (result, node) => {
                let children = [];
                if (node?.[this.childrenKey]) {
                    children = node[this.childrenKey].reduce(getChildren, []);
                }
                if (callback(node, this.filter) || children.length) {
                    result.push({...node, children});
                }
                return result;
            };
            return this.nodes.reduce(getChildren, []);
        },
    },
};
</script>
