export default class Response {

	static get STATUS_SUCCESS() { return 'success'; }

	static get STATUS_ERROR() { return 'error'; }

	uuid = null

	status = null

	error_id = null

	data = {}

	constructor(props) {

		Object.keys(props).forEach(
			(key) => {
				this[key] = props[key];
			}
		);
	}
};
