<div class="technology-park-estimated">
    <template v-if="mode === 'edit' || mode === 'add'">
        <div class="row-col mb-2">
            <div class="col-7">
                <div class="technology-park-estimated__select-all">
                    <FormCheckbox v-model="selectAll" label="Выделить все" />
                </div>
            </div>
            <div class="col-5">
                <FormButton
                    :disabled="!selectedModelLineIds.length"
                    label="+ Добавить в основной"
                    :attributes="{'class': ['btn-sm', 'btn-green', 'w-100']}"
                    @click="add()"
                />
            </div>
        </div>

        <hr class="silver" />
    </template>

    <Grid
        hide-header
        :columns="[
            {
                name: 'selected',
                label: '',
                slot: true,
                slot_name: 'selected',
                width: '1fr',
                hidden: mode === 'display',
            },
            {
                name: 'categoryName',
                label: 'Тип техники',
                width: '2fr',
            },
            {
                name: 'manufacturerName',
                label: 'Производитель',
                width: '2fr',
            },
            {
                name: 'modelLineName',
                label: 'Модельный ряд',
                width: '2fr',
            },
            {
                name: 'classification',
                label: '',
                slot: true,
                slot_name: 'classification',
                width: '1fr',
            },
        ]"
        :data="modelLinesData"
    >
        <template v-slot:selected="row">
            <FormCheckbox v-model="selectedModelLines[row.data.modelLineId]"/>
        </template>

        <template v-slot:classification="row">
            <TechnologyParkClassificationBadge
                :classification="row.data.classificationByProfit"
                container-class="technology-park__classification_square"
            />
        </template>
    </Grid>
</div>
