<template>
    <div
        v-if="classification"
        v-tippy
        content="Классификация модельного ряда по доходу"
        class="technology-park__classification"
        :class="{
            [containerClass]: true,
            'technology-park__classification_bg-green': classification === constants.A,
            'technology-park__classification_bg-yellow': classification === constants.B,
            'technology-park__classification_bg-red': ![constants.A, constants.B].includes(classification),
        }"
    >
        {{ classification }}
    </div>
</template>

<script>
export default {
    name: 'TechnologyParkClassificationBadge',
    props: {
        classification: {
            type: String,
            required: true,
        },
        containerClass: {
            type: String,
            default: () => 'technology-park__classification_rectangle',
        },
    },
    data() {
        return {
            constants: Object.freeze({
                A: 'A',
                B: 'B',
            }),
        };
    }
};
</script>

<style scoped>
@import '../../styles/technology-park.scss';
</style>
