<template src="./templates/icon.html"></template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            default() {
                return null;
            }
        },
        wrapperClass: {
            type: String,
            default() {
                return '';
            }
        },
        params: {
            type: Object,
            default() {
                return {};
            }
        },
        attributes: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['click'],
    computed: {
        iconProcessed() {

            let real_icon = this.icon;

            if (!this.icon) {
                if (!this.params.isSub && this.params.isEditor) {
                    real_icon = 'folder-and-plus';
                } else if (!this.params.isSub) {
                    real_icon = 'folder';
                } else if (this.params.isSub && this.params.isEditor) {
                    real_icon = 'none';
                } else {
                    real_icon = 'none';
                }
            }

            return real_icon;
        }
    },
    methods: {
        handleIconClick(event) {
            this.$emit('click', event);
        }
    }
};
</script>
