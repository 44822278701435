<div>
    <template v-if="formatValue">
        <div class="row-col mb-1">
            <div class="col-8 partner-title">
                {{ name }}
            </div>
            <div class="col-4">
                {{ getFormattedValue }}
            </div>
        </div>
    </template>

    <template v-else >
        <div class="row-col mb-1">
            <div class="col-8 partner-title">
                {{ name }}
            </div>
            <div class="col-4">
                {{ value }}
            </div>
        </div>
    </template>
</div>
