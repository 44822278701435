import api from '../tools/api';

export const getCategories = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-categories',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getVariables = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-variables',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getVariablesValues = (
	itemId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-variables-values',
        {item_id: itemId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getVariablesPoints = (
	partnerIds,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-variables-points',
        {partner_ids: partnerIds},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getScenariosRates = (
	partnerIds,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-scenarios-rates',
        {partnerIds},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);
export const calculateScenariosRates = (
	partnerIds,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'calculate-scenarios-rates',
        {partnerIds},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);
export const calculateProductsRates = (
	partnerIds,
    requestOptions = {},
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'calculate-products-rates',
        {partnerIds, options: requestOptions},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const saveVariable = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-variable',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const removeVariable = (
	variableId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'remove-variable',
		{id: variableId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const restoreVariable = (
	variableId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'restore-variable',
		{id: variableId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const saveCategory = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-variable-category',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const calculate = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'calculate',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
);

export const getScenarioGroups = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-scenario-groups',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const saveScenarioGroup = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-scenario-group',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const removeScenarioGroup = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'remove-scenario-group',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const restoreScenarioGroup = (
	scenarioGroupId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'restore-scenario-group',
		{id: scenarioGroupId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getScenariosTree = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-scenarios-tree',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getScenarios = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-scenarios',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const saveScenario = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-scenario',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const removeScenario = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'remove-scenario',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const restoreScenario = (
	scenarioId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'restore-scenario',
		{id: scenarioId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getQuestions = (
	scenarioId,
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-questions',
		{scenarioId, filter},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getRules = (
	filter,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-rules',
		filter,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);


export const saveQuestion = (
	data,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-question',
		data,
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const removeQuestion = (
	questionId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'remove-question',
		{id: questionId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const restoreQuestion = (
	questionId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'restore-question',
		{id: questionId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getBenefitsTree = (
	scenarioId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-benefits-tree',
		{scenarioId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const saveBenefitsTree = (
	scenarioId,
	benefitsTree,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-benefits-tree',
		{scenarioId, benefitsTree},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getBenefitConditions = (
	scenarioId,
	modelId,
	benefitId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-benefit-conditions',
		{
			scenario_id: scenarioId,
			model_id: modelId,
			benefit_id: benefitId,
		},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const saveBenefitConditions = (
	scenarioId,
	modelId,
	benefitId,
	conditions,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-benefit-conditions',
		{
			scenario_id: scenarioId,
			model_id: modelId,
			benefit_id: benefitId,
			conditions,
		},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getScenarioConditions = (
	scenarioId,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-scenario-conditions',
		{scenarioId},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const getPartnersDataStatuses = (
	partnerIds,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'get-partners-data-statuses',
		{partnerIds},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const updatePartnersScenarioData = (
	partnerIds,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'update-partners-scenario-data',
		{partnerIds},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);

export const saveScenarioConditions = (
	scenarioId,
	conditions,
	successHandler = () => {},
	errorHandler = () => {}
) => api
	.request(
		'scenarios',
		'save-scenario-conditions',
		{scenarioId, conditions},
		{},
		{
			resolve: successHandler,
			reject: errorHandler,
		}
	);
