<template src="./templates/features-list.html"></template>
<script>

import {getProductCategoryFeatures} from '@api/product';
import Tree from '@c/Tree';
import {getFeatureFilterTemplate} from '@c/Scenarios/ProductFeaturesList/config';

export default {
    name: 'ProductFeaturesList',
    components: {
        Tree,
    },
    props: {
        selectedItemId: {
            type: [Number, String],
            default: null,
        },
        selectedItem: {
            type: Object,
            default: null,
        },
        productCategories: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
        filter: {
            type: String,
            default: '',
        },
        dataFilter: {
            type: Object,
            default: getFeatureFilterTemplate(),
        },
    },
    emits: ['update:selectedItem', 'update:selectedItemId'],
    data() {
        return {
            items: [],
            itemsList: [],
            selectedItemIdChanged: null,
        };
    },
    computed: {
        filteredItemsList() {
            return this.itemsList.filter(item => {
                const {categoryIds, showCommonFeatures} = this.dataFilter;
                const isCommonFeature = item.related_categories.length === this.productCategories.length;

                // Если всего одна группа товаров, или выбрано несколько и не стоит флаг "Общие",
                // то показываем все характеристики
                if (this.productCategories.length === 1 || (categoryIds.length > 1 && !showCommonFeatures)) {
                    return true;
                }

                // Если стоит флаг "Общие", то показываем характеристики, которые есть у всех товаров
                if (showCommonFeatures) {
                    return isCommonFeature;
                }

                // В остальных случаях показываем характеристики с отбором по группам товаров, "Общие" - не показываем
                return !isCommonFeature && item.related_categories.some(category => categoryIds.includes(category));
            });
        },
    },
    watch: {
        selectedItemIdChanged() {
            this.$emit('update:selectedItemId', this.selectedItemIdChanged);

            const category = Object.values(this.items).filter(
                item => parseInt(item.id, 10) === parseInt(this.selectedItemIdChanged, 10)
            )[0];
            this.$emit('update:selectedItem', category);
        },
    },
    created() {
        this.onUpdateTree();
    },
    methods: {
        initComponent(callback = () => {
        }) {
            this.items = [];

            getProductCategoryFeatures(
                {category_id: this.productCategories.map(c => c.value)},
                r => {
                    this.items = r;
                    callback();
                }
            );
        },
        buildTree(parentId = 0) {

            const values = Object.values(this.items)
                .filter(item => parseInt(item.parent_id, 10) === parseInt(`${parentId}`, 10))
                .sort(this.compare);

            values.forEach((item, i) => {
                values[i].children = this.buildTree(item.id);
            });

            return values;
        },
        compare(a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        },
        onUpdateTree() {
            this.initComponent(() => {
                this.itemsList = this.buildTree();

                if (this.selectedItemId) {
                    this.selectedItemIdChanged = this.selectedItemId;
                } else if (this.selectedItem && this.selectedItem.id) {
                    this.selectedItemIdChanged = this.selectedItem.id;
                }
            });
        }
    }
};
</script>
