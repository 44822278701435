export const conditionTemplate = {
    id: null,
    property: {
        id: null,
        title: null,
        type: 'STRING',
        options: null,
    },
    compared_item: null,
    compared_id: null,
    condition_type: 'EQUAL',
    value_type: 'STRING',
    value: [],
    points: null,
};

export const itemTemplate = {
    id: null,
    operator: 'AND',
    conditions: [/*{...conditionTemplate}*/],
    pools: [/* чайлды того же формата, что и родитель (AND, OR). нужны для формирования бесконечного дерева */],
};

export const catalogs = {
    comparison_operators: [

        {title: '= (Равно)', value: 'EQUAL'},
        {title: '≠ (Не равно)', value: 'NOT_EQUAL'},
        {title: '> (Больше)', value: 'MORE'},
        {title: '≥ (Больше либо равно)', value: 'EQUAL_MORE'},
        {title: '< (Меньше)', value: 'LESS'},
        {title: '≤ (Меньше либо равно)', value: 'EQUAL_LESS'},
        {title: '∈ (Входит в список)', value: 'IN'},
        {title: '∉ (Не входит в список)', value: 'NOT_IN'},
        {title: '≥ и ≤ (В диапазоне)', value: 'BETWEEN'},
        {title: '≤ и ≥ (Не в диапазоне)', value: 'NOT_BETWEEN'},
        {title: 'Ø (Является пустым)', value: 'EMPTY_VALUE'},
        // {title: '☠ (Не Применяется)', value: 'NOT_APPLICABLE'},

    ],
    comparison_operators_string: [
        {title: '= (Равно)', value: 'EQUAL'},
        {title: '≠ (Не равно)', value: 'NOT_EQUAL'},
        {title: '∈ (Входит в список)', value: 'IN'},
        {title: '∉ (Не входит в список)', value: 'NOT_IN'},
        {title: 'Ø (Является пустым)', value: 'EMPTY_VALUE'},
    ],
    comparison_operators_setofnumber: [
        {title: '= (Равно)', value: 'EQUAL'},
        {title: '≠ (Не равно)', value: 'NOT_EQUAL'},
        {title: '∈ (Входит в список)', value: 'IN'},
        {title: '∉ (Не входит в список)', value: 'NOT_IN'},
    ],
    comparison_operators_bool: [
        {title: '= (Равно)', value: 'EQUAL'},
        {title: '≠ (Не равно)', value: 'NOT_EQUAL'},
        {title: 'Ø (Является пустым)', value: 'EMPTY_VALUE'},
    ],
    data_types: [
        'INT',
        'DECIMAL',
        'STRING',
        'BOOL',
        'DATE',
        'LIST_INT',
        'LIST_STRING',
        'LIST_DECIMAL',
        'LIST_DATE',
        'LIST_BOOL',
    ],
};

export const boolOptions = () => [{title: 'Да', value: '1'}, {title: 'Нет', value: '0'}];

/**
 * Вид отображения блока Значение
 * @param options
 * @param operation
 * @param type
 * @returns {string|boolean}
 */
export const getValueTemplateView = (options, operation, type) => {
    // Вид отображения блока Значение
    // Зависит от трёх параметров: Тип показателя, Операция сравнения и Наличия списка опций у Показателя
    // const viewTypes = [
    //     'INPUT',
    //     'INPUT_GROUP',
    //     'INPUT_BETWEEN',
    //     // 'INPUT_DATE',
    //     // 'INPUT_DATE_GROUP',
    //     // 'INPUT_DATE_BETWEEN',
    //     'DROPDOWN',
    //     'DROPDOWN_GROUP',
    //     'DROPDOWN_BETWEEN',
    //     'MULTI_DROPDOWN',
    //     'BOOLEAN',
    //     'EMPTY_VALUE',
    // ];

    const hasOneFieldByOperation = o => ['EQUAL', 'NOT_EQUAL', 'MORE', 'EQUAL_MORE', 'LESS', 'EQUAL_LESS'].includes(o);
    const hasTwoFields = o => ['BETWEEN', 'NOT_BETWEEN'].includes(o);
    const extendableFieldsCountByOperation = o => ['IN', 'NOT_IN'].includes(o);
    const isBoolean = (o, t) => t === 'BOOL' && ['EQUAL', 'NOT_EQUAL'].includes(o);
    // const extendableFieldsCountByType = t => ['LIST_INT', 'LIST_STRING', 'LIST_DECIMAL', 'LIST_DATE', 'LIST_BOOL'].includes(t);

    const hasOptions = options !== null;


    switch (true) {
        case operation === 'EMPTY_VALUE': return 'EMPTY_VALUE';
        case isBoolean(operation, type): return 'BOOLEAN';
        case hasOneFieldByOperation(operation) && !hasOptions: return 'INPUT';
        case hasOneFieldByOperation(operation) && hasOptions: return 'DROPDOWN';
        case hasTwoFields(operation) && !hasOptions: return 'INPUT_BETWEEN';
        case hasTwoFields(operation) && hasOptions: return 'DROPDOWN_BETWEEN';
        case extendableFieldsCountByOperation(operation)
        // && extendableFieldsCountByType(typeEdited)
        && !hasOptions: return /*'INPUT_GROUP'*/'MULTI_DROPDOWN';
        case extendableFieldsCountByOperation(operation)
        // && extendableFieldsCountByType(typeEdited)
        && hasOptions: return /*'DROPDOWN_GROUP'*/'MULTI_DROPDOWN';

        default: return false;
    }
};
