<template src="../templates/edit-variable-value.html"></template>
<script>

import Error from '@c/Error';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import AddLine from '@c/AddLine';
import {
    VALUE_TYPE_BOOL,
    VALUE_TYPE_DECIMAL,
    VALUE_TYPE_STRING,
    VALUE_TYPES
} from '@page/VariableEditor/config';

const defaultValues = {
    name: '',
    description: '',
    value: '',
};

export default {
    name: 'EditVariableValue',
    components: {
        Error,
        FormInput,
        FormButton,
        AddLine,
    },
    props: {
        values: {
            type: Array,
            default() {
                return [];
            }
        },
        type: {
            type: String,
            default: null,
            required: true,
        },
        errors: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    emits: ['update:values', 'update:errors'],
    data() {
        return {
            editValues: [
                ...this.values,
            ],
            constants: Object.freeze({
                VALUE_TYPE_STRING,
                VALUE_TYPE_DECIMAL,
                VALUE_TYPE_BOOL,
                VALUE_TYPES,
            }),
        };
    },
    computed: {
        inputType() {
            return this.constants.VALUE_TYPES.find(type => type.value === this.type).inputType;
        },
    },
    watch: {
        type() {
            if (this.type === this.constants.VALUE_TYPE_BOOL) {
                this.editValues = [];
                this.updateValues();
            }
            this.validate();
        },
        editValues: {
            handler() {
                this.validate();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        add(after = 0) {
            const newArr = [];
            switch (true) {
                case this.editValues.length === 0:
                    newArr.push({...defaultValues});
                    break;
                case after < 0:
                    newArr.push({...defaultValues});
                    newArr.push(...this.editValues);
                    break;
                default:
                    this.editValues.forEach((element, i) => {
                        newArr.push(element);
                        if (i === after) {
                            newArr.push({...defaultValues});
                        }
                    });
            }
            
            this.editValues = newArr;
            this.updateValues();
        },
        remove(key) {
            this.editValues = this.editValues.filter((_, i) => i !== key);
            this.updateValues();
        },
        updateValues() {
            this.$emit('update:values', this.editValues);
        },
        validate() {
            const errors = [];
            if (this.type === this.constants.VALUE_TYPE_STRING || this.type === this.constants.VALUE_TYPE_DECIMAL) {
                for (let i = 0; i < this.editValues.length; i++) {
                    if (!this.editValues[i].value) {
                        errors.push('Необходимо заполнить значение.');
                        break;
                    }
                }
            }
            this.$emit('update:errors', errors);
        },
    }
};
</script>
