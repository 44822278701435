import eventBus from '@/entities/eventBus';
import Filter from '@/entities/filter';

export default {
    selectPartnerGlobal: (partner, contactId = null, contactRef = null) => {
        const globalFilter = new Filter();
        const contacts = [];
        let selectedContact = null;
        if (partner.contactInformationPartners && partner.contactInformationPartners.length) {
            selectedContact = contactId || partner.contactInformationPartners.find(v => v.Ref_Key === contactRef)?.id;

            if (selectedContact) {
                for (let i = 0; i < partner.contactInformationPartners.length; i++) {
                    if (!selectedContact && i === 0) {
                        selectedContact = partner.contactInformationPartners[i].id;
                    }

                    contacts.push({
                        id: parseInt(partner.contactInformationPartners[i].id, 10),
                        name: partner.contactInformationPartners[i].name,
                        first_name: partner.contactInformationPartners[i].first_name,
                        middle_name: partner.contactInformationPartners[i].middle_name,
                        Ref_Key: partner.contactInformationPartners[i].Ref_Key,
                        position: partner.contactInformationPartners[i].position,
                    });
                }
            }
        }

        const partnerInfo = {
            id: partner.id,
            name: partner.name,
            external_id: partner.external_id,
            itn: partner.itn,
            area: partner.area?.name,
            selectedContact: parseInt(selectedContact, 10),
            contacts,
        };

        globalFilter.setGroup('partner', partnerInfo);
        eventBus.$emit('event-partner-selected', JSON.parse(JSON.stringify(partnerInfo)));

        // TODO: сделать ивентами обновление заказа мультиоферты
        // вынести в опросник либо в корзину мультиоферты
        const selectedContactData = contacts?.find(({id}) => id === parseInt(selectedContact, 10));
        const orderInfo = globalFilter.getGroup('multioffer_order');

        // контрагент изменился => сбрасываем заказ мультиоферты
        if (orderInfo.partner_code !== partnerInfo.external_id) {
            globalFilter.setGroup('multioffer_order', {});
            globalFilter.setGroup('multioffer_cart', []);
        } else {
            // контрагент прежний => обновляем информацию
            globalFilter.setGroup('multioffer_order', {
                ...orderInfo,
                ...{
                    // partner_code: partnerInfo.external_id,
                    // partner_name: partnerInfo.name,
                    partner_contact: `${selectedContactData?.first_name} ${selectedContactData?.middle_name}`,
                    partner_contact_ref: selectedContactData?.Ref_Key
                }
            });
        }
    },
    removeSelectedPartner: () => {
        const globalFilter = new Filter();
        globalFilter.setGroup('partner', {});
        eventBus.$emit('event-partner-selected', {});
    },
};
