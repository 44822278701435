<template src="./templates/tabs.html"></template>

<script>

import './styles/tabs.scss';

import FormButton from '@f/Button';

export default {
    name: 'Tabs',
    components: {
        FormButton
    },
    props: {
        columns: {
            type: Array,
            default() {
                return [
                    {
                        id: 'first',
                        label: 'First',
                        width: 'minmax(100px, 3.33fr)',
                    },
                    {
                        id: 'second',
                        label: 'Second long name',
                        width: 'minmax(120px, 3.33fr)',
                    },
                    {
                        id: 'third',
                        label: 'Third',
                        width: 'minmax(100px, 3.33fr)',
                    },
                    {
                        id: 'fourth',
                        label: 'Fourth',
                        slot_name: 'some-slot-name',
                        width: 'minmax(150px, 3.33fr)',
                    },
                ];
            }
        },
        data: {
            type: Object,
            default() {
                return {
                    first: {
                        content: 'First content'
                    },
                    second: {
                        content: 'Second content'
                    },
                    third: {
                        content: 'Third content'
                    },
                    fourth: {
                        content: 'Fourth content'
                    }
                };
            }
        },
        formatters: {
            type: Object,
            default() {
                return {
                    second: value => `<u>${value}</u>`
                };
            }
        },
        selected: {
            type: String,
            default: ''
        },
        headerClass: {
            type: String,
            default: ''
        },
        contentClass: {
            type: String,
            default: ''
        },
    },
    emits: ['selected', 'update:selected'],
    data() {
        return {
            selectedTab: this.selected
        };
    },
    computed: {
        headerClassPrep() {
            return `${(this.headerClass) ? ` ${this.headerClass}` : ''}`;
        },
        contentClassPrep() {
            return `${(this.contentClass) ? ` ${this.contentClass}` : ''}`;
        },
    },
    watch: {
        columns() {
            if (this.selectedTab && !this.columns.filter(itm => itm.id === this.selectedTab).length) {
                this.selectedTab = null;
            }
        },
    },
    methods: {
        selectTab(id) {
            this.selectedTab = id;
            this.$emit('update:selected', id);
        },
        format(format_type, value) {
            let result = value;

            if (typeof this.formatters[format_type] !== 'undefined') {
                result = this.formatters[format_type](result);
            }

            return result;
        }
    }
};
</script>
