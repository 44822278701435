const LeftMenuStore = {
    state: {
        menu_items: {}
    },
    mutations: {
        updateMenu(state, payload) {
            state.menu_items = payload;
        }
    },
    actions: {
        updateMenu({commit}, menu_items) {

            commit('updateMenu', menu_items);
        }
    }
};

export {
    LeftMenuStore
};
