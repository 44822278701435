<table class="grid-table" :style="columnsStyle">
    <thead v-show="!hideHeader">
        <tr>
            <GridCell
                v-for="(headerCell, i) in visibleColumns"
                :width="headerCell.width"
                class="cell-clickable"
                :class="{active: i === 0, 'grid-cell-header': true}"
                tag="th"
                :title="headerCell.label"
            >
                <span class="icon--wrapper mr-1">
                    <span class="icon bg-60" :class="{'expand-sm-down': i === 0, 'expand-sm': i !== 0}"></span>
                </span>
                <slot name="headerColumnLeftSlot" :data="headerCell"></slot>
                {{headerCell.label}}
            </GridCell>
        </tr>
    </thead>
    <tbody>
        <tr v-for="row in preparedData" class="grid-row">
            <template v-if="isRowVisible(row)">
                <GridCell
                        v-for="(headerCell, index) in visibleColumns"
                        :id="row[idField]"
                        :width="headerCell.width"
                        :buttonEdit="onEdit && (index === (visibleColumns.length - 1)) && !!row[idField]"
                        :buttonRemove="onRemove && (index === (visibleColumns.length - 1))"
                        @onLink="onLink"
                        @onEdit="onEdit"
                        @onRemove="onRemove"
                        :linkButtons="row.questions_complete === 'Не пройден' && !!row.questions && row.questions.length > 0 && (index === (visibleColumns.length - 1))"
                        :questions="row.questions"

                >
                    <div class="d-flex"
                        :class="{
                            [`grid-cell_level-${row.level}`]: treeGrid && index === 0 && row.level > 0,
                            'pointer': treeGrid && index === 0 && row.hasChildren,
                        }"
                        @click="expandCollapse(row, index)"
                    >
                        <span
                            v-if="treeGrid && index === 0"
                            class="grid-cell__tree-node icon--wrapper mr-1"
                            :class="{
                                'grid-cell__tree-node_closed': row.hasChildren && !treeGridExpandedIds.includes(row[idField]),
                            }"
                        >
                            <span v-if="row.hasChildren" class="icon bg-60 arrow"></span>
                        </span>
                        <slot
                            v-if="headerCell.slot_name"
                            :name="headerCell.slot_name"
                            :data="row"
                        ></slot>
                        <div v-else v-html="format(headerCell.name, row[headerCell.name])"></div>
                    </div>
                </GridCell>
            </template>
        </tr>
    </tbody>
</table>
