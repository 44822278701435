<div id="edit-variable-popup" class="p-2">
    <div class="row-col">
        <div class="col p-3">
            <form-input
                class="change-category w-50"
                :model-value="selectedParentCategoryTitle"
                label="Родитель"
                label-class="d-block"
                @click.prevent="showParentCategoryChangePopup = true"
                :attributes="{class:['btn-gray', 'pointer']}"
            />
        </div>
    </div>
    <div class="row-col mw-2">
        <div class="col-11 ph-3">
            <form-input
                class="w-100"
                v-model="editData.name"
                label="Имя свойства"
            />
        </div>
        <div class="col-1">
            <form-input
                class="w-100"
                v-if="editData.id"
                v-model="editData.id"
                disabled label="ID"
            />
        </div>
    </div>
    <div class="row-col">
        <div class="col p-3">
            <FormTextarea v-model="editData.description" label="Примечание"/>
        </div>
    </div>
    <div class="row-col">
        <div class="col p-3">
            <div class="row-col">
                <div class="col-3">
                    <form-input
                        type="number"
                        v-model="editData.livetime"
                        label="Актуальность данных"
                        label-class="d-block"
                        help-text="Пример подсказки"
                    />
                </div>
                <div class="col-2">
                    <form-select
                        group-class="bg-white"
                        v-model="editData.livetime_type"
                        :options="constants.LIVETIME_TYPES"
                        label="-"
                        label-class="d-block text-white"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="row-col">
        <div class="col">
            <tabs
                ref="variableType"
                v-model:selected="selectedTab"
                content-class="p-2 border rounded"
                :columns="[
                    {
                        id: 'constantProps',
                        label: 'Постоянное',
                        slot_name: 'constantProps',
                        width: 'minmax(100px, 3.33fr)',
                    },
                    {
                        id: 'dynamicProps',
                        label: 'Условия',
                        slot_name: 'dynamicProps',
                        width: 'minmax(120px, 3.33fr)',
                    },
                    {
                        id: 'calcProps',
                        label: 'Формула',
                        slot_name: 'calcProps',
                        width: 'minmax(100px, 3.33fr)',
                    },
                ]"
            >
                <template v-slot:tab="row">
                    <form-radio
                        class="p-1 w-1 mr-1 d-inline-flex"
                        :label="row.data.tab.label"
                        name="changeTab"
                        :style="'width:' + row.data.tab.width"
                        :attributes="{
                            checked: row.data.tab.id === selectedTab
                        }"
                        @change="changeTab(row.data.tab.id)"
                    />
                </template>

                <template v-slot:constantProps="row">
                    <form-select
                        label="Тип данных"
                        group-class="w-25 mb-1"
                        :disable-first="true"
                        v-model="editData.value_type"
                        :options="constants.VALUE_TYPES"
                    />
                    <div class="form-group">
                        <EditValue
                            v-model:values="editData.values"
                            v-model:errors="errors.editValueErrors"
                            :type="editData.value_type"
                            class="p-2"
                        />
                    </div>
                </template>
                <template v-slot:dynamicProps="row">
                    <ConditionEditor
                        v-if="true"
                        v-model:items="editData.pools"
                        :conditionPropertySource="`PARTNER`"
                    />
                </template>
                <template v-slot:calcProps="row">В разработке</template>
            </tabs>
        </div>
    </div>


    <div v-if="editData.type === constants.VARIABLE_TYPE_CONSTANT" class="row-col">
        <div class="col p-3">
            <form-checkbox label="Дополнительные настройки" v-model="additionalSettings" />
        </div>
    </div>
    <template v-if="additionalSettings">
        <div class="row-col">
            <div class="col ph-3">
                <h6>Источник данных</h6>
            </div>
        </div>
        <div class="row-col">
            <div class="col p-3">
                <classifier class="border rounded p-2" :settings="editData.settings.dataGenerators.classifier" />
            </div>
        </div>
    </template>

    <div class="row-col">
        <div class="col p-3">
            <div class="position-sticky bottom-n80 action-panel pv-1 mb-4">
                <div class="ml-2">
                    <FormButton
                            :disabled="!validated"
                            label="Сохранить"
                            :attributes="{class: ['btn-green', 'mr-1']}"
                            @click="save"
                    />
                    <FormButton label="Отмена" @click="onCancel" :attributes="{class: ['btn-gray']}"/>
                </div>
            </div>
        </div>
    </div>

    <teleport to="body" v-if="showParentCategoryChangePopup">
        <VariableTreeSelectDialog
            v-model:showDialog="showParentCategoryChangePopup"
            v-model:selected-variable-id="editData.parent_id"
        />
    </teleport>
</div>
