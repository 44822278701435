<template src="./templates/variable-tree-select-dialog.html"></template>

<script>
import VariableTree from '@c/Scenarios/VariableTree';
import VariableTreeFilter from '@c/Scenarios/VariableTreeFilter';
import Modal from '@c/Modal';

export default {
    name: 'VariableTreeSelectDialog',
    components: {
        Modal,
        VariableTree,
        VariableTreeFilter,
    },
    props: {
        // Показать/скрыть диалог выбора свойства
        showDialog: {
            type: Boolean,
            required: true,
            default: false,
        },
        // Выбранное свойство
        selectedVariable: {
            type: Object,
            default: null,
        },
        // Идентификатор выбранного свойства
        selectedVariableId: {
            type: [Number, String],
            default: null,
        },
    },
    emits: ['update:showDialog', 'update:selectedVariable', 'update:selectedVariableId'],
    data() {
        return {
            showModalVariableTree: this.showDialog,
            variable: this.selectedVariable,
            variableId: this.selectedVariableId,
            filter: '',
            showRemoved: false,
        };
    },
    watch: {
        showDialog() {
            this.showModalVariableTree = this.showDialog;
        },
        variable() {
            this.$emit('update:selectedVariable', this.variable);
        },
        variableId() {
            this.$emit('update:selectedVariableId', this.variableId);
        },
    },
    methods: {
        closeModal() {
            this.$emit('update:showDialog', false);
        },
    }
};
</script>
