<template v-if="controlView === false">
Вид отображения неопределён
</template>

<template v-else-if="controlView === 'INPUT'">
    <FormInput
        label="Значение"
        v-model="values[0]"
        required
        :type="controlHTMLType"
        :step="type === 'DECIMAL' ? '.01' : ''"
        :disabled="disabled"
    />
</template>

<template v-else-if="controlView === 'INPUT_BETWEEN'">
    <FormInput
        label="Значение 1"
        v-model="values[0]"
        required
        :type="controlHTMLType"
        :step="type === 'DECIMAL' ? '.01' : ''"
        :disabled="disabled"
    />

    <FormInput
        label="Значение 2"
        v-model="values[1]"
        required
        :type="controlHTMLType"
        :step="type === 'DECIMAL' ? '.01' : ''"
        :disabled="disabled"
    />
</template>

<template v-else-if="controlView === 'DROPDOWN'">
    <FormSelect
        label="Значение"
        v-model="values[0]"
        :options="optionsAdapted"
        required
        :attributes="{disabled}"
    />
</template>

<template v-else-if="controlView === 'DROPDOWN_BETWEEN'">
    <FormSelect
        label="Значение 1"
        v-model="values[0]"
        :options="optionsAdapted"
        required
        disableFirst
        :attributes="{disabled}"
    />

    <FormSelect
        label="Значение 2"
        v-model="values[1]"
        :options="optionsAdapted"
        required
        disableFirst
        :attributes="{disabled}"
    />
</template>

<template v-else-if="controlView === 'INPUT_GROUP'">
    <template v-for="(v, i) in values">
        <FormInput
            :label="`Значение ${+i+1}`"
            v-model="values[i]"
            required
            :type="controlHTMLType"
            :step="type === 'DECIMAL' ? '.01' : ''"
            :disabled="disabled"
        />
    </template>

    <FormButton
        label="Добавить значение"
        @click="addValue"
        :attributes="{class: ['btn-gray']}"
        :disabled="disabled"
    />
</template>

<template v-else-if="controlView === 'DROPDOWN_GROUP'">
    <template v-for="(v, i) in values">
        <FormSelect
            :label="`Значение ${+i+1}`"
            v-model="values[i]"
            :options="optionsAdapted"
            required
            disableFirst
            :attributes="{disabled}"
        />
    </template>

    <FormButton
        label="Добавить значение"
        @click="addValue"
        :attributes="{class: ['btn-gray']}"
        :disabled="disabled"
    />
</template>

<template v-else-if="controlView === 'BOOLEAN'">
    <FormSelect
        label="Значение"
        v-model="values[0]"
        :options="boolOptions"
        required
        disableFirst
        :attributes="{disabled}"
    />
</template>

<template v-else-if="controlView === 'MULTI_DROPDOWN'">
    <label class="h6">Значение</label>
    <Multiselect
        v-model="values"
        :options="optionsAdapted"
        :label="'title'"
        valueProp="value"
        trackBy="value"
        mode="tags"
        :searchable="false"
        required
        :disabled="disabled"
    />
</template>

<template v-else-if="controlView === 'EMPTY_VALUE'">
    <FormInput
        label="Значение"
        model-value="null"
        disabled
    />
</template>
