<div class="expansion-item">
    <div class="expansion-item__header">
        <slot name="header">
            <div class="expansion-item__header__layout" :class="headerClass" @click="$emit('header-click')">
                <i v-if="!expandedIcon && !expandIcon" class="icon no-icon ml-1 mr-1"/>
                <i v-else class="icon ml-1 mr-1 pointer" :class="showing ? expandedIcon : expandIcon" @click.stop="toggle"/>
                <i v-if="icon" class="icon mr-1" :class="icon" />
                <div class="expansion-item__label">
                    <slot name="label">
                        {{label}}
                    </slot>
                </div>
                <template v-if="labelRight || !!$slots['label-right']">
                    <div class="expansion-item__space"></div>
                    <span class="expansion-item__label_muted mr-1">
                        <slot name="label-right">
                            {{ labelRight }}
                        </slot>
                    </span>
                </template>
            </div>
        </slot>
    </div>
    <transition name="expansion-item__fade">
        <div class="expansion-item__content" v-show="showing">
            <slot></slot>
        </div>
    </transition>
</div>
