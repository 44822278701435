<template src="../templates/tree.html"></template>
<script>

import helpers from '@/tools/helpers';
import FormButton from '@f/Button';
import Item from './item';

export default {
    name: 'Tree',
    components: {
        FormButton,
        Item,
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    emits: ['update:items'],

    data() {
        return {
            editedItems: [...helpers.deepCopy(this.items)]
        };
    },
    watch: {
        editedItems: {
            handler(val) {
                this.$emit('update:items', [...val]);
            },
            deep: true,
        },
    },
};

</script>
