<template src="../../templates/partner-cart-technology-park-add.html"></template>

<script>

import Multiselect from '@vueform/multiselect';
import FormButton from '@f/Button';
import Error from '@c/Error';
import {
    getProductMachineCategories,
    getManufacturers,
    getModelLines,
    getModels
} from '@api/technology-park';

export default {
    name: 'TechnologyParkAdd',
    components: {
        Multiselect,
        FormButton,
        Error,
    },
    props: {
        technologyPark: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
    },
    emits: ['add', 'update:additionalData'],
    data() {
        return {
            loading: false,
            categoryId: null,
            manufacturerId: null,
            modelLineId: null,
            modelId: null,
            categories: [],
            manufacturers: [],
            modelLines: [],
            models: [],
            errors: [],
        };
    },
    computed: {
        model() {
            return this.models.find(model => model.id === this.modelId);
        },
        modelLine() {
            return this.modelLines.find(modelLine => modelLine.id === this.modelLineId);
        },
        modelLineCategory() {
            if (!this.modelLine) {
                return null;
            }
            return this.categories.find(category => category.id === this.modelLine.categoryId);
        },
        modelLineManufacturer() {
            if (!this.modelLine) {
                return null;
            }
            return this.manufacturers.find(manufacturer => manufacturer.id === this.modelLine.manufacturerId);
        },
    },
    watch: {
        categoryId() {
            this.loadOptions();
        },
        manufacturerId() {
            this.loadOptions();
        },
        modelLineId() {
            if (!this.modelLineId) {
                this.modelId = null;
            }
            this.loadOptions();
            this.validate();
        },
        modelId() {
            this.validate();
        }
    },
    mounted() {
        this.loadOptions();
    },
    methods: {
        loadOptions() {
            this.loading = true;

            const categoryIds = this.categoryId ? [this.categoryId] : null;
            const manufacturerIds = this.manufacturerId ? [this.manufacturerId] : null;
            const modelLineIds = this.modelLineId ? [this.modelLineId] : null;

            const promises = [
                getProductMachineCategories(manufacturerIds, modelLineIds)
                    .then(response => {
                        this.categories = response;
                    }),
                getManufacturers(categoryIds, modelLineIds)
                    .then(response => {
                        this.manufacturers = response;
                    }),
            ];
            if (categoryIds || manufacturerIds) {
                promises.push(
                    getModelLines(categoryIds, manufacturerIds)
                        .then(response => {
                            this.modelLines = response;
                        })
                );
            }
            if (modelLineIds) {
                promises.push(
                    getModels(modelLineIds)
                        .then(response => {
                            this.models = response;
                        })
                );
            }

            Promise.all(promises)
                .finally(() => {
                    this.loading = false;
                });
        },
        validate() {
            this.errors = [];

            if (this.modelLineId && !this.modelId) {
                const foundItem = this.technologyPark.find(
                    item => !item.modelId && item.modelLineId === this.modelLineId
                );
                if (foundItem) {
                    this.errors.push('Модельный ряд есть в парке техники.');
                }
            }
        },
        add() {
            if (this.errors.length) {
                return;
            }
            const newItem = this.getNewItem();
            const additionalData = this.getAdditionalData();
            this.$emit('add', {newItems: [newItem], additionalData});
            this.clear();
        },
        getAdditionalData() {
            return {
                categories: [this.modelLineCategory],
                manufacturers: [this.modelLineManufacturer],
                modelLines: [this.modelLine],
                models: this.model ? [this.model] : [],
            };
        },
        getNewItem() {
            return {
                id: null,
                count: 1,
                modelId: this.modelId,
                modelLineId: this.modelLineId,
                manufacturerId: this.modelLineManufacturer.id,
                categoryId: this.modelLineCategory.id,
                serialNumber: null,
                year: null,
                note: '',
            };
        },
        clear() {
            this.categoryId = null;
            this.manufacturerId = null;
            this.modelLineId = null;
            this.modelId = null;
            this.categories = [];
            this.manufacturers = [];
            this.modelLines = [];
            this.models = [];
        },
    }
};
</script>
