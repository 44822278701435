<template src="./templates/error.html"></template>
<script>

import Button from '@f/Button';

export default {
    name: 'Error',
    components: {
        Button
    },
    props: {
        message: {
            type: [String, Array],
            default() {
                return null;
            }
        },
        boxClass: {
            type: String,
            default() {
                return '';
            }
        },
        onClose: {
            type: [Function, null],
            default() {
                return null;
            }
        },
    }
};
</script>
