<template src="./templates/select.html"></template>
<script>
    import Help from '@c/Help';

	const default_values = {
		id: null,
		class: '',
	};

	export default {
		name: 'FormSelect',
        components: {
            Help,
        },
		props: {
            errors: {
                type: Array,
                default() {
                    return [];
                }
            },
            preloader: {
                type: Boolean,
                default() {
                    return false;
                }
            },
			label: {
				type: String,
				default() {
					return null;
				}
			},
			placeholder: {
				type: String,
				default() {
					return '';
				}
			},
			attributes: {
				type: Object,
				default() {
					return default_values;
				}
			},
			labelClass: {
				type: String,
				default() {
					return '';
				}
			},
			groupClass: {
				type: String,
				default() {
					return '';
				}
			},
			options: {
				type: Array,
				default() {
					return [];
				}
			},
			modelValue: {
				required: false,
				type: [String, Number],
				default() {
					return '';
				}
			},
            disableFirst: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            helpText: {
                type: String,
                default() {
                    return '';
                },
            },
            required: {
                type: Boolean,
                default() {
                    return false;
                },
            },
		},
		emits: ['update:modelValue', 'change'],
		data() {
			return {
				selected: null,
			};
		},
        computed: {
            validBorder() {
                return (this.errors.length) ? 'red-border-valid' : '';
            },
			preparedOptions() {
				return this.options.map((item) => {
					if (typeof item === 'object') {
						return item;
					}

					return {title: item, value: item};
				});
			},
			attributesPrep() {
				const attrs = {
                    ...default_values,
                    ...this.attributes,
                    ...(this.required ? {required: true} : {})
                };

				if (!attrs.id) {
					attrs.id = `form-select-${Math.floor(Math.random() * 100000)}`;
				}
				if (!attrs.name) {
					attrs.name = `form-check-${attrs.id}`;
				}

				let css_classes = attrs.class;

				if (typeof css_classes === 'string') {
					css_classes = css_classes.split(' ');
				}

				css_classes.push('form-control');
				css_classes = css_classes.join(' ');

				attrs.class = css_classes;

				return attrs;
			},
			labelClassPrep() {
				return `${this.labelClass} h6`;
			}
		},
        methods: {
			selectedOption(option) {
				if (this.modelValue) {
					return option.value === this.modelValue;
				}
				return false;
			},
			change(e) {
                this.$emit('change', e.target.value, this.modelValue);
                this.$emit('update:modelValue', e.target.value);
			},
		}
	};
</script>
