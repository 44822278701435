import api from '../tools/api';

export const sendReportAction = (params) => api.request(
    'report',
    'send-report-action',
    {...params}
);
 export const getRequestServices = () => api.request(
     'report',
     'get-request-services'
 );
