import api from '../tools/api';

export const getTechnologyPark = (
    partnerId,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-technology-park',
    {partnerId},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);

export const getProductMachineCategories = (
    manufacturerIds = null,
    modelLineIds = null,
    ids = null,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-product-machine-categories',
    {manufacturerIds, modelLineIds, ids},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);

export const getModelLines = (
    categoryIds = null,
    manufacturerIds = null,
    ids = null,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-model-lines',
    {categoryIds, manufacturerIds, ids},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);

export const getManufacturers = (
    categoryIds = null,
    modelLineIds = null,
    ids = null,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-manufacturers',
    {categoryIds, modelLineIds, ids},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);

export const getModels = (
    modelLineIds = null,
    ids = null,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-models',
    {modelLineIds, ids},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);

export const getEstimatedModelLines = (
    partnerId,
    successHandler = () => {},
    errorHandler = () => {}
) => api.request(
    'technology-park',
    'get-estimated-model-lines',
    {partnerId},
    {},
    {
        resolve: successHandler,
        reject: errorHandler,
    }
);
