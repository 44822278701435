export default {
    deepCopy: obj => JSON.parse(JSON.stringify(obj)),

    /**
     * Возвращает функцию, которая выполняется не чаще, чем ms
     * @param f
     * @param ms
     * @returns {function(...[*]=): undefined}
     */
    debounce(f, ms) {
        let working = false;

        // eslint-disable-next-line func-names
        return function (...args) {
            if (working) return;

            f.apply(this, args);
            working = true;

            setTimeout(() => {
                working = false;
            }, ms);
        };
    },

    /**
     * возвращает функцию, которая выполняется только после заданной паузы в ms
     * @param f
     * @param ms
     * @returns {function(...[*]=): void}
     */
    debounceBuffer(f, ms) {
        let timer = null;

        // eslint-disable-next-line func-names
        return function (...args) {
            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
                timer = null;
                f.apply(this, args);
            }, ms);
        };
    },


    /**
     * Склонение слова, расположенного рядом с числом
     *
     * Примеры использования:
     * wordAfterNumberDeclension(number, ['товар', 'товара', 'товаров']));
     * wordAfterNumberDeclension(number, ['штука', 'штуки', 'штук']));
     * wordAfterNumberDeclension(number, ['рубль', 'рубля', 'рублей']));
     *
     * @param {number} number число, от которого зависит форма нужного слова
     * @param {array} words массив склоняемого слова в трех вариантах:
     * ['Единственное число', 'Родительный падеж', 'Множественное число']
     * @returns string
     */
    wordAfterNumberDeclension(number, words) {
        const choices = [2, 0, 1, 1, 1, 2];
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : choices[(number % 10 < 5) ? number % 10 : 5]];
    },

    unixTimestampToLocaleDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString();
    },

    /**
     * Метод возвращает фамилию и инициалы.
     * Пример: Иванов Иван Иванович => Иванов И. И.
     *
     * @param {string} surname фамилия
     * @param {string|null} name имя
     * @param {string|null} patronymic отчество
     * @returns string|null
     */
    getSurnameWithInitials(surname, name, patronymic) {
        let surnameWithInitials = surname;
        if (name) {
            surnameWithInitials = `${surnameWithInitials} ${name[0]}.`;
        }
        if (patronymic) {
            surnameWithInitials = `${surnameWithInitials} ${patronymic[0]}.`;
        }

        return surnameWithInitials;
    },

    randomKey() {
        return (Math.random() + 1).toString(36).substring(7);
    },

    /**
     * Возвращает первый найденный объект в дереве с подобной структурой:
     * [
     *   {
     *     id: 1,
     *     children: [
     *       {
     *         id: 2,
     *         children: [],
     *       }
     *     ]
     *   },
     *   {
     *     id: 4,
     *     children: [],
     *   }
     * ]
     *
     * Если ни чего не найдено — возвращает null.
     *
     * Пример использования:
     * node = findNodeInTree(nodes, item => item.id === 5);
     *
     * @param {object[]} nodes дерево
     * @param {function} callback функция для поиска, в качестве аргумента передаётся проверяемый объект
     * @param {string} childrenKey наименование свойства с массивом детей
     * @returns object|null
     */
    findNodeInTree(nodes, callback, childrenKey = 'children') {
        let result = null;
        for (let i = 0; i < nodes.length; i++) {
            if (callback(nodes[i])) {
                result = nodes[i];
                break;
            } else if (nodes[i][childrenKey]) {
                result = this.findNodeInTree(nodes[i].children, callback, childrenKey);
                if (result) {
                    break;
                }
            }
        }
        return result;
    },

    /**
     * Функция добавляет параметры в строку, заменяя существующие и удаляя дубли
     * Для удаления параметра нужно в качестве значения передать undefined
     *
     * Пример использования:
     * helpers.urlSetParams(url, [['qr', 1], ['utm_source', 'direct'], ['manage', undefined]])
     *
     *
     * @param urlString
     * @param params
     * @returns {string}
     */
    urlSetParams(urlString, params = []) {
        const url = new URL(urlString);

        params.forEach((p, _) => {
            const name = p.shift();
            const value = p.shift();

            if (value === undefined) {
                url.searchParams.delete(name);
            } else {
                url.searchParams.set(name, value);
            }
        });

        return url.href;
    }
};
