<div>
    <div class="park-item">
        <div class="d-flex">
            <div class="park-item__section">
                <div class="d-flex">
                    <TechnologyParkClassificationBadge :classification="modelLine?.classificationByProfit" />
                    <div class="park-item__image">
                        <img v-if="image" :src="image" />
                    </div>
                </div>
            </div>
            <div class="park-item__section">
                <div class="d-flex">
                    <div class="park-item__title h5 mr-1">
                        <template v-if="item.modelId">{{ model?.name }}</template>
                        <template v-else>Модель не указана</template>
                    </div>
                    <div v-if="parseInt(item.countSale) > 0">
                        <i class="park-item__icon icon logo-lbr" v-tippy :content="`Продано ЛБР: ${item.countSale} шт.`" />
                    </div>
                </div>

                <div v-if="mode === 'display'" class="d-flex mt-1 partner-title">
                    <div class="mr-1">{{ item.count }} шт.</div>
                    <div class="mr-1">{{ modelLine?.name }}</div>
                    <div v-if="item.serialNumber" class="mr-1">s/n: {{ item.serialNumber }}</div>
                    <div v-if="item.year">г.в.: {{ item.year }}</div>
                </div>

                <div v-if="mode === 'edit' || mode === 'add'" class="d-flex mt-1 partner-title">
                    <div class="mr-2">Дата изменения: {{ lastUpdateDate }}</div>
                    <div>
                        Сотрудник:
                        <template v-if="item?.updatedByUser">
                            {{ item.updatedByUser }}
                        </template>
                        <template v-else-if="!item?.id">
                            {{ userSurnameWithInitials }}
                        </template>
                    </div>
                </div>

                <div v-if="mode === 'display'" class="d-flex partner-title mt-1">
                    <div>{{ manufacturer?.name }}</div>
                </div>
            </div>
            <div class="park-item__space"></div>
            <div class="park-item__section">
                <div class="d-flex">
                    <div v-if="mode === 'display' && item.note" class="park-item__note">{{ item.note }}</div>
                    <div class="ml-1" v-if="pathToPartsCatalog">
                        <a :href="pathToPartsCatalog" target="_blank" v-tippy content="Перейти в каталог">
                            <FormButton
                                :label="`<span class='icon settings m-0'></span>`"
                                :attributes="{'class': ['btn-sm', 'btn-yellow', 'p-1']}"
                            />
                        </a>
                    </div>
                    <div v-if="mode === 'edit' || mode === 'add'" class="ml-1">
                        <FormButton
                            :label="`<span class='icon delete m-0'></span>`"
                            :attributes="{'class': ['btn-sm', 'btn-red', 'p-1']}"
                            @click="deleteItem"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="mode === 'edit' || mode === 'add'" class="park-form mt-1 mb-2">
        <div class="row-col mb-2">
            <div class="col-2">
                <div class="park-form__title mb-1">Количество</div>
                <div>
                    <FormInput
                        v-model="item.count"
                        type="number"
                        :attributes="{
                            min: 1,
                            step: 1,
                        }"
                    />
                </div>
            </div>
            <div class="col-4">
                <div class="park-form__title mb-1">Серийный номер</div>
                <div>
                    <FormInput
                        :disabled="!isNewItem"
                        v-model="item.serialNumber"
                        :attributes="{maxlength: 64}"
                    />
                </div>
            </div>
            <div class="col-2">
                <div class="park-form__title mb-1">Год выпуска</div>
                <div>
                    <FormInput
                        :disabled="!isNewItem"
                        v-model="item.year"
                        type="number"
                    />
                </div>
            </div>
        </div>
        <div class="row-col">
            <div class="col">
                <div class="park-form__title mb-1">Примечание</div>
                <div>
                    <FormInput
                        v-model="item.note"
                        :attributes="{maxlength: 100}"
                    />
                </div>
            </div>
        </div>
    </div>

    <div v-if="componentErrors.length" class="mb-2">
        <template v-for="error in componentErrors">
            <Error v-if="error.length" :message="error" />
        </template>
    </div>
</div>
