<template src="./templates/add-line.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'AddLine',
    components: {
        FormButton,
    },
    props: {
        iconClass: {
            type: String,
            default: () => '', // white?
        }
    },
};
</script>
<style src="./styles/add-line.scss" lang="scss" />
