import api from './api';
import cookie from './cookie';

export default {
	installed: null,
	install(app) {

		if (this.installed) {
			return;
		}

		app.config.globalProperties.$tools = {
			api: api,
			cookie: cookie
		};
	}
};
