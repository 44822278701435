<template src="./templates/toggle-button.html"></template>

<script>
import './toggle-button.scss';

export default {
    props: {
        modelValue: {
            type: [String, Number],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // Отображать только активное положение переключателя
        activeOnly: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => [
                {
                    label: 'On',
                    value: 1,
                },
                {
                    label: 'Off',
                    value: 0,
                },
            ]
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {

        };
    },

    computed: {

    },

    created() {
        if (!this.modelValue) {
            this.$emit('update:modelValue', [...this.options].shift().value);
        }
    },

    methods: {
        updateValue(value) {
            if (!this.disabled) {
                this.$emit('update:modelValue', value);
            }
        },
    }
};
</script>

