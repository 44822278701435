<template src="./templates/variable-tree.html"></template>
<script>

import Tree from '@c/Tree';

import {
    getVariables
} from '@api/scenarios';

export default {
    name: 'VariableTree',
    components: {
        Tree,
    },
    props: {
        selectedCategoryId: {
            type: [Number, String],
            default: null,
        },
        selectedCategory: {
            type: Object,
            default: null,
        },
        filterText: {
            type: String,
            default: '',
        },
        filterFunc: {
            type: Function,
            default: null,
        },
        showRemoved: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:selectedCategory', 'update:selectedCategoryId'],
    data() {
        return {
            variables: [],
            variableTree: [],
            changeCategory: null,
        };
    },
    computed: {
        variablesFiltered() {
            return this.filterFunc !== null
                ? Object.values(this.variables).filter(this.filterFunc)
                : this.variables;
        },
    },
    watch: {
        changeCategory() {
            this.$emit('update:selectedCategoryId', this.changeCategory);

            const category = Object.values(this.variables).filter(
                item => parseInt(item.id, 10) === parseInt(this.changeCategory, 10)
            )[0];
            this.$emit('update:selectedCategory', category);
        },
        showRemoved() {
            this.onUpdateTree();
        }
    },
    created() {
        this.onUpdateTree();
    },
    methods: {
        initComponent(callback = () => {
        }) {
            this.variables = [];
            const dataFilter = {};
            if (!this.showRemoved) {
                dataFilter.removed = 0;
            }
            getVariables(
                dataFilter,
                r => {
                    this.variables = r;
                    callback();
                }
            );
        },
        buildTree(parentId = 0) {

            const values = Object.values(this.variablesFiltered)
                .filter(item => parseInt(item.parent_id, 10) === parseInt(`${parentId}`, 10))
                .sort(this.compare);

            values.forEach((item, i) => {
                values[i].children = this.buildTree(item.id);
            });

            return values;
        },
        compare(a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        },
        onUpdateTree() {
            this.initComponent(() => {
                this.variableTree = this.buildTree();

                if (this.selectedCategoryId) {
                    this.changeCategory = this.selectedCategoryId;
                } else if (this.selectedCategory && this.selectedCategory.id) {
                    this.changeCategory = this.selectedCategory.id;
                }
            });
        }
    }
};
</script>
