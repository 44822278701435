<template src="./templates/textarea.html"></template>
<script>

import Help from '@c/Help';

const default_values = {
    id: null,
    class: ''
};

export default {
    name: 'FormTextarea',
    components: {
        Help,
    },
    props: {
        errors: {
            type: Array,
            default() {
                return [];
            }
        },
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        modelValue: {
            required: false,
            type: String,
            default() {
                return '';
            },
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return null;
            }
        },
        groupClass: {
            type: String,
            default() {
                return '';
            }
        },
        labelClass: {
            type: String,
            default() {
                return '';
            }
        },
        flexible: {
            type: Boolean,
            default() {
                return false;
            }
        },
        helpText: {
            type: String,
            default() {
                return '';
            },
        },
    },
    emits: ['update:modelValue'],
    computed: {
        labelClassPrep() {
            const cl = `${(this.labelClass) ? `${this.labelClass} ` : ''}`;
            return `${cl}h6`;
        },
        attributesPrep() {
            const attrs = {...default_values, ...this.attributes};

            if (!attrs.id) {
                attrs.id = `form-textarea-${Math.floor(Math.random() * 100000)}`;
            }

            return attrs;
        }
    },
    mounted() {
        this.resize();
    },
    methods: {
        resize(e) {
            if (this.flexible === true) {
                if (e !== undefined) {
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                } else {
                    this.$refs.txarea.style.height = `${this.$refs.txarea.scrollHeight}px`;
                }
            }
        }
    }
};
</script>
