<template src="./templates/checkbox.html"></template>
<script>

import Help from '@c/Help';

const default_values = {
    id: null,
    type: 'checkbox',
    class: ''
};

export default {
    name: 'FormCheckbox',
    components: {
        Help,
    },
    props: {
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        modelValue: {
            type: [Number, Boolean, Array],
            default() {
                return 0;
            }
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return null;
            }
        },
        wrapperClass: {
            type: String,
            default() {
                return '';
            }
        },
        value: {
            type: [String, Number, Boolean],
            default() {
                return null;
            }
        },
        helpText: {
            type: String,
            default() {
                return '';
            },
        },
    },
    emits: ['update:modelValue'],
    computed: {
        wrapperClassPrep() {

            let css_classes = this.wrapperClass;

            if (typeof css_classes === 'string') {
                css_classes = css_classes.split(' ');
            }

            css_classes.push('form-check');
            css_classes = css_classes.join(' ');

            return css_classes;
        },
        attributesPrep() {
            const attrs = {...default_values, ...this.attributes};

            if (this.value) {
                attrs.value = this.value;
            }
            
            if (!attrs.id) {
                attrs.id = `form-checkbox-${Math.floor(Math.random() * 100000)}`;
            }
            if (!attrs.name) {
                attrs.name = `form-check-${attrs.id}`;
            }

            let css_classes = attrs.class;

            if (typeof css_classes === 'string') {
                css_classes = css_classes.split(' ');
            }

            css_classes.push('hide');
            css_classes = css_classes.join(' ');

            attrs.class = css_classes;

            return attrs;
        }
    },
    methods: {
        changeToParent(event) {
            if (Array.isArray(this.modelValue)) {
                
                if (event.target.checked) {
                    const v = [...this.modelValue];
                    v.push(this.value);
                    this.$emit('update:modelValue', v);
                } else {
                    this.$emit('update:modelValue', [...this.modelValue].filter(i => i !== this.value));
                }
                
            } else {
                this.$emit('update:modelValue', event.target.checked ? 1 : 0);
            }
        },
        
        isChecked() {
            if (Array.isArray(this.modelValue)) {
                return this.modelValue.includes(this.value);
            }
            
            return !!this.modelValue;
        },
    }

};
</script>
