<template src="../templates/item.html"></template>
<script>

import helpers from '@/tools/helpers';
import FormButton from '@f/Button';
import ToggleButton from '@c/ToggleButton';
import {conditionTemplate, itemTemplate} from '@c/ConditionEditor/config';
import AddLine from '@c/AddLine';
import Condition from './condition';

export default {
    name: 'Item',
    components: {
        FormButton,
        ToggleButton,
        Condition,
        AddLine,
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        itemIndex: {
            type: Number,
            default() {
                return 0;
            },
        },
        removeHandler: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },

    emits: ['update:item'],

    data() {
        return {
            editedItem: {...this.item},
        };
    },
    watch: {
        'editedItem.operator'(val) {
            this.emitChanges({operator: val});
        },
    },
    methods: {
        emitChanges(changes = {}) {
            this.$nextTick(() => this.$emit('update:item', {...this.editedItem, ...changes}));
        },
        addItemCondition(editorItemConditionIndex) {
            this.editedItem.conditions.splice(
                editorItemConditionIndex + 1,
                0,
                {
                    ...helpers.deepCopy(conditionTemplate),
                    tmpKey: helpers.randomKey()
                }
            );
            this.emitChanges();
        },
        removeItemCondition(editorItemConditionIndex) {
            this.editedItem.conditions.splice(editorItemConditionIndex, 1);
            this.emitChanges();
        },
        addChildItem() {
            this.editedItem.pools.splice(
                0,
                0,
                {
                    ...helpers.deepCopy(itemTemplate),
                    tmpKey: helpers.randomKey()
                }
            );
            this.emitChanges();
        },
        removeItem(childItemIndex) {
            this.editedItem.pools.splice(childItemIndex, 1);
            this.emitChanges();
        },
    },
};

</script>
