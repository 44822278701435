<div v-if="partner">
	<div class="row-col m-0">
		<div class="col-3 bg-gray p-3">
			<Details
				v-if="this.mode === 'display'"
				:partner="partner"
				:mode="mode"
			/>
			<Details
				v-else-if="mode==='edit' || mode==='add'"
                :mode="mode"
				v-model:partner="detailsForEdit"
				v-model:errors="errors.validation.details"
			/>
		</div>

		<div class="col-9 p-0">
			<Tabs
				:columns="[
					{
						id: 'contacts',
						label: 'Контакты',
						slot_name: 'contacts',
					},
                    {
                        id: 'classifier',
                        label: 'Классификатор',
                        slot_name: 'classifier',
                    },
                    {
                        id: 'technologyPark',
                        label: 'Мехдвор',
                        slot_name: 'technologyPark',
                    },
					{
						id: 'addresses',
						label: 'Адреса' + ((addresses) ? ' (' + addresses.length + ')' : ''),
						slot_name: 'addresses'
					},
					{
                        id: 'legalDetails',
                        label: 'Реквизиты',
                        slot_name: 'legalDetails',
					},
					{
						id: 'variables',
						label: 'Свойства',
						slot_name: 'variables',
					},
					{
						id: 'scenarios',
						label: 'Сценарии',
						slot_name: 'scenarios',
					},
					{
						id: 'scoring',
						label: 'Скоринг',
						slot_name: 'scoring',
					}
				]"
				:data="{
					contacts: {
						content: ''
					},
                    classifier: {
                        content: ''
                    },
                    technologyPark: {
                        content: ''
                    },
					addresses: {
						content: ''
					},
					legal_details: {
						content: ''
					},
					variables: {
						content: ''
					},
					scenarios: {
						content: ''
					},
					scoring: {
						content: ''
					}
				}"
			>
				<template v-slot:contacts="row">
					<Contacts v-if="this.mode === 'display'" :mode="mode"
						:contacts="contacts"
						:contact-types="contactTypes"
						:contact-positions="contactPositions"
                        :actions="actions.client"
                        :on-action="callAction"
					/>
					<Contacts v-else-if="mode==='edit' || mode==='add'" :mode="mode"
						v-model:contacts="contactsForEdit"
                        v-model:errors="errors.validation.contacts"
						:contact-types="contactTypes"
						:contact-positions="contactPositions"
					/>
				</template>

                <template v-slot:classifier="row">
                    <Classifier
                        v-if="mode === 'display'"
                        :mode="mode"
                        :classifier="classifier"
                    />
                    <Classifier
                        v-if="mode === 'edit' || mode==='add'"
                        :mode="mode"
                        v-model:classifier="classifierForEdit"
                    />
                </template>

                <template v-slot:technologyPark="row">
                    <TechnologyPark
                            v-if="mode === 'display'"
                            :partner-id="id"
                            :mode="mode"
                            :technology-park="technologyPark"
                    />
                    <TechnologyPark
                            v-if="mode === 'edit' || mode==='add'"
                            :partner-id="id"
                            :mode="mode"
                            v-model:errors="errors.validation.technologyPark"
                            v-model:technology-park="technologyParkForEdit"
                    />
                </template>

				<template v-slot:addresses="row">
					<Addresses v-if="this.mode === 'display'" :mode="mode"
						:addresses="addresses"
					   	:address-types="addressTypes"
					   	:partner-address-types="partnerAddressTypes"
					/>
					<Addresses v-else-if="mode==='edit' || mode==='add'" :mode="mode"
						v-model:addresses="addressesForEdit"
					   	:address-types="addressTypes"
					   	:partner-address-types="partnerAddressTypes"
					/>
				</template>

                <template v-slot:legalDetails="row">
                    <LegalDetails v-if="this.mode === 'display'"
                        :mode="mode"
                        :legal-details="legalDetails"
                    />
                    <LegalDetails v-else-if="mode==='edit' || mode==='add'"
                        :mode="mode"
                        v-model:legal-details="legalDetailsForEdit"
                        v-model:errors="errors.validation.details"
                    />
                </template>

                <template v-slot:variables="row">
                    <Properties
                        :properties="variablesValues"
                        :points="variablesPoints[id]"
                        :partner-id="id"
                    />
                </template>

                <template v-slot:scenarios="row">
                    <Scenarios :partner-id="id" />
                </template>

                <template v-slot:scoring="row">
                    <Scoring
                        :mode="mode"
                        :scoring="scoring"
                        :scoringTranscription="scoringTranscription"
                    />
                </template>
			</Tabs>

			<div class="position-sticky bottom-n80 action-panel pv-1 mb-4">
                <Error
                    v-if="mode==='add' && displayPartnerPrompt && promptPartners"
                    :on-close="() => { displayPartnerPrompt = false; }"
                    message="Возможно вы создаете существующего котрагента!"
                    box-class="mh-2 border-yellow"
                >
                    <PromptPartners :prompt-partners="promptPartners" :on-select-partner="selectPartner" />
                </Error>
				<Error v-if="errors.alert" :message="errors.alert" box-class="mh-2" />
				<Error v-if="errors.system" :message="errors.system" box-class="mh-2" />
				<div class="ml-2" v-if="mode==='edit' || mode==='add'">
					<FormButton
                        :disabled="!validated"
						label="Сохранить"
						:preloader="preloader"
						:attributes="{class: ['btn-green', 'mr-1']}"
						@click="save"
					/>
					<FormButton label="Отмена" @click="cancel" :attributes="{class: ['btn-gray']}" />
				</div>
				<FormButton
					v-else-if="mode==='display'"
					label="✎ Редактировать"
					@click="enableEdit"
					:attributes="{class:['text-blue','btn-blue','ml-2']}"
				/>
			</div>
		</div>
	</div>
</div>
