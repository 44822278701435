<div class="tree">
    <ul class="tree__node-group first">
            <Item
                v-for="(editorItem, editorItemIndex) in editedItems"
                v-model:item="editedItems[editorItemIndex]"
                :item-index="editorItemIndex"
                :disabled="disabled"
            >
                <template v-slot:actions="itemData">
                    <slot name="actions" :data="itemData.data"></slot>
                </template>
                <template v-slot:extraConditionData="itemData">
                    <slot name="extraConditionData" :data="itemData.data"></slot>
                </template>
                <template v-slot:extraPoolData="poolData">
                    <slot name="extraPoolData" :data="poolData.data"></slot>
                </template>
            </Item>
    </ul>
</div>
