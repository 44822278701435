<template src="./templates/variable-filter.html"></template>
<script>

import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';

export default {
    name: 'VariableTreeFilter',
    components: {
        FormInput,
        FormCheckbox,
    },
    props: {
        filter: {
            type: String,
            default: '',
            required: true,
        },
        showRemoved: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:filter', 'update:showRemoved'],
    data() {
        return {
            filterValue: this.modelValue,
            showRemovedValue: this.showRemoved,
        };
    },
    mounted() {
        this.$refs['variable-search-form-input'].setFocus();
    },
    watch: {
        filterValue() {
            this.$emit('update:filter', this.filterValue);
        },
        showRemovedValue() {
            this.$emit('update:showRemoved', this.showRemovedValue);
        },
    },
};
</script>
