<div v-if="!filteredNodes.length">Ничего не найдено...</div>
<TreeItem
    v-for="node in filteredNodes"
    :key="node[nodeKey]"
    :node="node"
    :node-key="nodeKey"
    :label-key="labelKey"
    :children-key="childrenKey"
    :selected="modelValue"
    :expanded="filter.length > 0"
    :expand-icon="expandIcon"
    :expanded-icon="expandedIcon"
    @select="onSelect"
>
    <template v-slot:label="row" >
        <slot name="label" :data="row.data"></slot>
    </template>
</TreeItem>
