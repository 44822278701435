<template src="../templates/condition-value.html"></template>
<script>

import {boolOptions, getValueTemplateView} from '@c/ConditionEditor/config';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import Multiselect from '@vueform/multiselect';

export default {
    name: 'ConditionValue',
    components: {
        FormButton,
        FormInput,
        FormSelect,
        Multiselect,
    },
    props: {
        valuesData: {
            type: [Array, String, Number],
            default() {
                return [];
            },
        },
        options: {
            type: Array,
            default() {
                return null;
            },
        },
        type: {
            type: String,
            default() {
                return 'INT';
            },
        },
        operation: {
            type: String,
            default() {
                return 'EQUAL';
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        propertyType: {
            type: String,
            default() {
                return null;
            },
        },
    },

    emits: ['update:valuesData', 'update:type'],

    data() {
        return {
            values: Array.isArray(this.valuesData) ? [...this.valuesData] : [this.valuesData],
            typeEdited: this.type,
            boolOptions: boolOptions(),
            singleValueViews: ['INPUT', 'DROPDOWN', 'BOOLEAN'],
        };
    },
    computed: {
        controlView() {
            return getValueTemplateView(this.options, this.operation, this.type);
        },
        controlHTMLType() {
            switch (true) {
                case ['INT', 'DECIMAL', 'LIST_INT', 'LIST_DECIMAL'].includes(this.typeEdited):
                    return 'number';
                default:
                    return 'string';
            }
        },
        optionsAdapted() {
            let result = [];

            switch (this.propertyType) {
                case 'SETOFNUMBER':
                case 'RANGE':
                    result = this.options;
                    break;
                default:
                    result = ['INT', 'DECIMAL', 'LIST_INT', 'LIST_DECIMAL'].includes(this.typeEdited)
                ? this.options.map(v => ({title: v.title, value: parseFloat(v.value)}))
                : this.options;
            }

            return result;
        }
    },
    watch: {
        valuesData: {
            handler(val) {
                this.values = val;//Array.isArray(val) ? [...val] : [val];
            },
            deep: true,
        },
        values: {
            handler(val) {
                this.$emit('update:valuesData', val);
            },
            deep: true,
            immediate: true,
        },
        controlView: {
            handler(val, oldVal) {
                if (this.singleValueViews.includes(val)) {
                    this.values = this.values.filter((v, i) => i === 0);
                }

                if (val === 'EMPTY_VALUE') {
                    this.values = [null];
                } else if (val === 'MULTI_DROPDOWN' || oldVal === 'MULTI_DROPDOWN' || oldVal === 'EMPTY_VALUE') {
                    this.values = [];
                }
            },
        },
        operation: {
            handler(val) {
                if (['BETWEEN', 'NOT_BETWEEN', 'IN', 'NOT_IN'].includes(val)) {
                    this.typeEdited = `LIST_${this.type.replace('LIST_', '')}`;
                } else {
                    this.typeEdited = this.type.replace('LIST_', '');
                }

                this.$emit('update:type', this.typeEdited);
            },
        },
    },
    mounted() {

    },
    methods: {
        addValue() {
            this.values.push('');
        },
    },
};

</script>
