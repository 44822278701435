<template src="../../templates/partner-cart-details.html"></template>
<script>

import FormInput from '@f/Input';
import FormButton from '@f/Button';
import Multiselect from '@vueform/multiselect';
import Error from '@c/Error';

import {
    getAreas,
    getPartnerTypes,
    getPartnerLegalTypes
} from '@api/partners';

export default {
    name: 'Details',
    components: {
        FormInput,
        FormButton,
        Multiselect,
        Error,
    },
    props: {
        mode: { // display | edit | add
            type: String,
            default() {
                return 'display';
            }
        },
        partner: {
            type: Object,
            default() {
                return null;
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['update:errors', 'update:partner'],
    data() {
        return {
            fields: [
                {
                    type: 'text',
                    key: 'name',
                    title: 'Название'
                },
                {
                    type: 'number',
                    key: 'itn',
                    title: 'ИНН'
                },
                {
                    type: 'text',
                    key: 'external_id',
                    title: 'Номер 1С'
                },
            ],
            fieldsRequired: ['name'],
            disableFields: ['external_id'],
            rootAreas: null,
            districtAreas: null,
            selectedRootArea: null,
            selectedArea: null,
            partnerTypes: null,
            partnerLegalTypes: null,
            detailsErrors: {},
        };
    },
    watch: {
        async mode() {
            if ((this.mode === 'edit' || this.mode === 'add') && !this.rootAreas) {
                await this.initAreas();
                await this.initPartnerTypes();
            }
        },
        async selectedRootArea() {
            this.districtAreas = null;
            if (this.selectedRootArea) {
                this.districtAreas = await getAreas({
                    parent_id: this.selectedRootArea,
                    level: 2
                });
                if (!this.districtAreas.find(area => Number(area.id) === Number(this.selectedArea))) {
                    // Если район не относится к выбранной области - очищаем его
                    this.selectedArea = null;
                }
            } else {
                this.selectedArea = null;
            }
            this.validateAreas();
        },
        selectedArea() {
            this.$emit('update:partner', {...this.partner, ...{area_id: this.selectedArea}});
            this.validateAreas();
        },
        detailsErrors: {
            handler(v) {
                const newErrors = {};
                Object.keys(v).forEach(
                    k => {
                        if (v[k].length) {
                            newErrors[k] = [...v[k]];
                        }
                    }
                );
                this.$emit('update:errors', newErrors);
            },
            deep: true,
        }
    },
    mounted() {
        this.fields.forEach(f => { this.detailsErrors[f.key] = []; });
        this.initPartnerTypes();
        if ((this.mode === 'edit' || this.mode === 'add') && !this.rootAreas) {
            this.initAreas();
        }
    },
    methods: {
        async initAreas() {
            this.rootAreas = await getAreas({
                level: 1
            });
            if (this.partner.area_id) {
                this.selectedArea = this.partner.area_id;
                const district = await getAreas({
                    id: this.partner.area_id,
                    one: true
                });
                this.selectedRootArea = district.root;
            }
            this.validateAreas();
        },
        async initPartnerTypes() {
            this.partnerTypes = await getPartnerTypes();
            this.partnerLegalTypes = await getPartnerLegalTypes();
        },
        validateAreas() {
            this.detailsErrors.area_id = [];
            if (!this.selectedRootArea) {
                this.detailsErrors.area_id.push('Выберите область');
            } else if (!this.selectedArea) {
                this.detailsErrors.area_id.push('Выберите район');
            }
        },
    }
};
</script>
