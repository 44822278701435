<FormInput
    label="Показатель"
    :model-value="property.title"
    @click.prevent="propertyClickHandler"
    :attributes="{class: ['btn-gray'], readonly: true}"
    required
/>
<teleport to="body" v-if="showPropertyModal">
    <VariableTreeSelectDialog
        v-if="source === 'PARTNER'"
        v-model:showDialog="showPropertyModal"
        v-model:selected-variable="propertyRaw"
        v-model:selected-variable-id="propertyRawId"
    />
    <ProductFeatureSelectDialog
        v-if="source === 'PRODUCT_FEATURE'"
        v-model:showDialog="showPropertyModal"
        v-model:selected-variable="propertyRaw"
        v-model:selected-variable-id="propertyRawId"
    />
</teleport>
