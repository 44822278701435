<template src="../../templates/partner-cart-contact.html"></template>
<script>

import FormButton from '@f/Button';
import FormInput from '@f/Input';
import Multiselect from '@vueform/multiselect';

import ContactField from './contact-field';
import ContactActions from './contact-actions';

export default {
    name: 'Contact',
    components: {
        FormButton,
        FormInput,
        Multiselect,
        ContactField,
        ContactActions,
    },
    props: {
        contact: {
            type: Object,
            default() {
                return {
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                };
            }
        },
        contactTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        contactPositions: {
            type: Object,
            default() {
                return {};
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
        onDelete: {
            type: Function,
            default() {
                return () => {};
            }
        },
        errors: {
            type: Object,
            default() {
                return {};
            }
        },
        actions: {
            type: Object,
            default() {
                return {};
            }
        },
        onAction: {
            type: Function,
            default() {
                return () => {};
            }
        }
    },
    emits: ['update:contact', 'update:errors'],
    data() {
        return {
            componentsErrors: {}
        };
    },
    watch: {
        componentsErrors: {
            deep: true,
            handler(v) {
                this.$emit('update:errors', this.prepErrors(v));
            }
        }
    },
    methods: {
        onActionHandler(fn) {
            this.onAction(fn, this.contact.id);
        },
        addContactField() {
            const {contact} = this;
            if (!contact.contactDetails) {
                contact.contactDetails = [];
            }
            contact.contactDetails.push({key: contact.contactDetails.length});
            this.$emit('update:contact', contact);
        },
        deleteContactField(key) {
            
            const {contact} = this;
            
            contact.contactDetails = contact.contactDetails.filter((value, index) => {
                if (index === key) {
                    delete this.componentsErrors[index];
                    return false;
                }
                return true;
            });
            
            this.$emit('update:contact', contact);
        },
        prepErrors(v) {
            const errors = {};
            Object.keys(v).forEach(k => {
                if (v[k].length > 0) {
                    errors[k] = [...v[k]];
                }
            });
            return errors;
        }
    }
};
</script>
