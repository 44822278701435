<li class="tree__node-item condition">
    <div
        class="tree__level row"
    >
        <div class="cell">
            <ConditionProperty
                v-model:property="conditionEdited.property"
                :disabled="disabled"
            />
        </div>
        <div class="cell">
            <FormSelect
                label="Операция"
                :options="comparisonOperators"
                v-model="conditionEdited.condition_type"
                required
                disableFirst
                :attributes="{disabled}"
            />
        </div>
        <div class="cell" v-if="typeof conditionEdited != 'undefined'">
            <ConditionValue
                v-model:values-data="conditionEdited.value"
                v-model:type="conditionEdited.value_type"
                :property-type="conditionEdited.property.type"
                :options="conditionEdited.property.options || null"
                :operation="conditionEdited.condition_type"
                :disabled="disabled"
            />
        </div>
        <div class="cell">
            <FormInput
                v-model="conditionEdited.points"
                label="Баллы"
                type="number"
                :disabled="disabled"
                :attributes="{class:'points-input'}"
            />
        </div>
        <div class="cell">
            <slot></slot>
        </div>
    </div>
    <span><slot name="addSlot"></slot></span>

</li>
