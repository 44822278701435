<template src="./templates/variable-tree-select-dialog.html"></template>

<script>
import ProductFeaturesList from '@c/Scenarios/ProductFeaturesList';
import FeaturesFilter from '@c/Scenarios/ProductFeaturesList/components/features-filter';
import Modal from '@c/Modal';
import {getFeatureFilterTemplate} from '@c/Scenarios/ProductFeaturesList/config';

export default {
    name: 'ProductFeatureSelectDialog',
    components: {
        Modal,
        FeaturesFilter,
        ProductFeaturesList,
    },
    inject: ['scenarioProductCategories'],
    props: {
        // Показать/скрыть диалог выбора свойства
        showDialog: {
            type: Boolean,
            required: true,
            default: false,
        },
        // Выбранное свойство
        selectedVariable: {
            type: Object,
            default: null,
        },
        // Идентификатор выбранного свойства
        selectedVariableId: {
            type: [Number, String],
            default: null,
        },
    },
    emits: ['update:showDialog', 'update:selectedVariable', 'update:selectedVariableId'],
    data() {
        return {
            showModalVariableTree: this.showDialog,
            variable: this.selectedVariable,
            variableId: this.selectedVariableId,
            filter: '',
            dataFilter: {
                ...getFeatureFilterTemplate(),
                categoryIds: this.scenarioProductCategories.map(c => c.id),
            },
            productCategories: this.scenarioProductCategories.map(v => ({title: v.name, value: v.id})),
            showRemoved: false,
        };
    },
    watch: {
        showDialog() {
            this.showModalVariableTree = this.showDialog;
        },
        variable() {
            this.$emit('update:selectedVariable', this.variable);
        },
        variableId() {
            this.$emit('update:selectedVariableId', this.variableId);
        },
    },
    methods: {
        closeModal() {
            this.$emit('update:showDialog', false);
        },
    }
};
</script>
