<template src="./templates/expansion-item.html"></template>

<script>
export default {
    name: 'ExpansionItem',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },

        // behavior
        defaultOpened: {
            type: Boolean,
            default: false,
        },

        // content
        icon: {
            type: String,
            default: '',
        },
        expandIcon: {
            type: String,
            default: 'chevron',
        },
        expandedIcon: {
            type: String,
            default: 'chevron chevron_rotate-down',
        },
        label: {
            type: String,
            default: '',
        },
        labelRight: {
            type: String,
            default: '',
        },

        // style
        headerClass: {
            type: [String, Array, Object],
            default: 'bg-dark-gray-1',
        },
    },
    emits: ['update:modelValue', 'header-click'],
    data() {
        return {
            showing: this.modelValue || this.defaultOpened,
        };
    },
    watch: {
        modelValue() {
            this.showing = this.modelValue;
        },
        showing() {
            this.$emit('update:modelValue', this.showing);
        }
    },
    methods: {
        show() {
            this.showing = true;
        },
        hide() {
            this.showing = false;
        },
        toggle() {
            this.showing = !this.showing;
        },
    }
};
</script>

<style scoped lang="scss">
@import './styles/expansion-item';
</style>
