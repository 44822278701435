<template src="../../templates/partner-cart-classifier.html"></template>

<script>
import Grid from '@c/Grid';
import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import helpers from '@/tools/helpers';
import Loader from '@c/Loader';
import Error from '@c/Error';

export default {
    name: 'Classifier',
    components: {
        Grid,
        FormInput,
        FormCheckbox,
        Loader,
        Error,
    },
    props: {
        classifier: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        mode: { // display | edit | add
            type: String,
            required: true,
            default() {
                return 'display';
            }
        },
    },
    emits: ['update:classifier'],
    data() {
        return {
            constants: Object.freeze({
                BOOL_TYPE: 'BOOL',
                BOOL_VALUES: {
                    true: 'Да',
                    false: ''
                },
            }),
            columns: [
                {
                    name: 'name',
                    label: 'Показатель',
                },
                {
                    slot_name: 'value',
                    name: 'value',
                    label: 'Значение',
                },
            ],
        };
    },
    computed: {
        classifierData() {
            if (this.mode === 'display') {
                // Отображаем только показатели с заполненными значениями
                const tree = this.filterTree(
                    this.classifier,
                    item => !!item.value
                );
                return this.expandTree(tree);
            }

            return this.classifier;
        },
        lastUpdateDate() {
            const lastDate = this.getLastUpdateDate(this.classifier);
            if (!lastDate) {
                return null;
            }

            return helpers.unixTimestampToLocaleDate(lastDate);
        },
    },
    methods: {
        getLastUpdateDate(items, date = 0) {
            let maxDate = date;
            items.forEach(item => {
                maxDate = Math.max(
                    maxDate,
                    item.updatedAt,
                    this.getLastUpdateDate(item.children, maxDate)
                );
            });

            return maxDate;
        },
        getValue(item) {
            if (item.valueType === this.constants.BOOL_TYPE) {
                return this.constants.BOOL_VALUES[Boolean(item.value)];
            }
            if (item.value === null || Number.isNaN(item.value)) {
                return '';
            }

            return item.value.toLocaleString();
        },
        getValueInfo(item) {
            const date = item.updatedAt ? helpers.unixTimestampToLocaleDate(item.updatedAt) : '';
            const user = item.updatedByUser ? item.updatedByUser : '';

            return `${date} ${user}`.trim();
        },
        updateClassifier(changedItem) {
            const changedClassifier = helpers.deepCopy(this.classifier);

            // находим показатель в дереве по списку всех его родителей,
            // меняем значение и эмитим изменённое дерево
            let children = changedClassifier;
            for (let i = 0; i < changedItem.allParents.length; i++) {
                children = children.find(
                    item => item.itemId === changedItem.allParents[i]
                ).children;
                if (i === changedItem.allParents.length - 1) {
                    children.find(
                        item => item.itemId === changedItem.itemId
                    ).value = changedItem.value;
                }
            }

            this.$emit('update:classifier', changedClassifier);
        },
        filterTree(tree, callback) {
            const getChildren = (result, item) => {
                const children = item.children.reduce(getChildren, []);
                if (callback(item) || children.length) {
                    result.push({...item, children});
                }

                return result;
            };

            return tree.reduce(getChildren, []);
        },
        expandTree(tree) {
            return tree.map(item => {
                const result = {...item, isExpanded: true};
                result.children = this.expandTree(result.children);
                return result;
            });
        },
    },
};
</script>
