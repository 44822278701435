<div>
	<div v-if="addresses && addresses.length" v-for="(address, key) in addresses" class="border-dashed mb-2 p-2">
		<Address
			:mode="mode"
			v-model:address="addresses[key]"
			:address-types="addressTypes"
			:partner-address-types="partnerAddressTypes"
			:rootAreas="rootAreas"
			:districtAreas="districtAreas"
			:on-change-root-address="changeRootAddress"
		/>
	</div>

	<template v-if="mode==='edit' || mode==='add'">
		<div class="border-dashed mb-2 p-2 text-gray partner-add-contact text-center" @click="addAddress()">
			+ Добавить адрес
		</div>
	</template>
</div>