<template src="../../templates/partner-cart-addresses.html"></template>
<script>

import {getAreas} from '@api/partners';
import Address from './address';

export default {
    name: 'Addresses',
    components: {
        Address,
    },
    props: {
        addresses: {
            type: Array,
            default() {
                return [];
            }
        },
        addressTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        partnerAddressTypes: {
            type: Object,
            default() {
                return {};
            }
        },
        mode: { // display | edit
            type: String,
            default() {
                return 'display';
            }
        },
    },
    emits: ['update:addresses'],
    data() {
        return {
            rootAreas: null,
            districtAreas: {},
        };
    },
    mounted() {
        if ((this.mode === 'edit' || this.mode === 'add')) {
            this.initAreas();
        } else {
            this.initCurrentAreas();
        }
    },
    methods: {
        addAddress() {
            const {addresses} = this;
            addresses.push({country: 'Россия'});
            this.$emit('update:addresses', addresses);
        },
        deleteAddress(key) {
            let {addresses} = this;
            addresses = addresses.filter((value, index) => index !== key);
            this.$emit('update:addresses', addresses);
        },

        async initAreas() {
            this.rootAreas = await getAreas({
                level: 1
            });

            const regionIds = this.addresses.map(adr => adr.region_id);
            if (regionIds.length) {
                const district = await getAreas({
                    root: regionIds
                });
                this.applyDistrict(district);
            }
        },
        async initCurrentAreas() {
            const rootIds = this.addresses.map(adr => adr.region_id);
            this.rootAreas = await getAreas({
                id: rootIds
            });
            const districtIds = this.addresses.map(adr => adr.district_id);
            const district = await getAreas({
                id: districtIds
            });
            this.applyDistrict(district);
        },
        applyDistrict(district) {
            if (district && district.length) {
                const districtResult = {};
                for (let i = 0; i < district.length; i++) {
                    if (typeof districtResult[district[i].root] === 'undefined') {
                        districtResult[district[i].root] = [];
                    }
                    districtResult[district[i].root].push(district[i]);
                }
                this.districtAreas = districtResult;
            }
        },
        async changeRootAddress(dId) {
            if (typeof this.districtAreas[dId] === 'undefined') {
                this.districtAreas[dId] = await getAreas({
                    root: dId
                });
            }
        }
    }
};
</script>
