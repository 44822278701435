<div class="condition-editor">
    <template v-if="editedItems.length === 0">
        <FormButton
            v-if="!disabled"
            label="Добавить условие +"
            :attributes="{class: ['btn-green', 'w10']}"
            @click.prevent="initTree"
        />
    </template>
    <Tree v-else v-model:items="editedItems" :disabled="disabled">
        <template v-if="!disabled" v-slot:actions="slotData">
            <FormButton
                label="Добавить условие +"
                :attributes="{class: ['btn-green', 'w10']}"
                @click.prevent="slotData.data.addChildItem"
            />
            <FormButton
                label="<span class='icon delete red m-auto'></span>"
                :attributes="{class: ['btn-red', 'text-center']}"
                @click.prevent="slotData.data.removeHandler"
            />
        </template>

        <template v-slot:extraPoolData="poolData">
            <slot name="extraPoolData" :data="poolData.data"></slot>
        </template>

        <template v-slot:extraConditionData="itemData">
            <slot name="extraConditionData" :data="itemData.data"></slot>
        </template>
    </Tree>
</div>
