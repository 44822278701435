<div :class="((paramsPrep.isSub) ? 'sub ' : 'list ' ) + params.class">
    <template v-for="(item, key) in itemsPrep">

        <div :class="'list--wrapper--item ' + item.wrapperClass">

            <input v-if="item.children.length"
                   :name="'list--show-sub-' + uniq_key"
                   class="list--show-sub"
                   :type="paramsPrep.type"
                   :id="'li-' + uniq_key + '_'+ key"
            />

            <label
                class="list--item"
                @click="handleClick(item.id, $event)"
                v-bind="listItemAttributes"
                :for="'li-' + uniq_key + '_'+ key"
            >

                <Chevron v-if="!paramsPrep.isSub && item.children.length && paramsPrep.isChevron"/>

                <Icon
                    v-if="!paramsPrep.isSub"
                    :icon="item.icon"
                    :params="paramsPrep"
                    :attributes="{
							...iconItemAttributes,
							...{
								'data-id': (typeof item.id !== 'undefined') ? item.id : '',
								type:'group'
							}
						}"
                    @click.stop="paramsPrep.isEditor && handleClick(item.id, paramsPrep.isSub ? 'sub-icon-click' : 'icon-click')"
                />

                <span
                    v-if="item.children.length || !item.url"
                    :class="'list--item--name ' + paramsPrep.labelClass">
                    {{ item.title }}
                </span>

                <router-link
                    v-else
                    :to="formatUrl(item.url)"
                    :class="'list--item--name  ' + paramsPrep.labelClass"
                    v-bind="item.attributes">
                    {{ item.title }}
                </router-link>

                <router-link
                    v-if="paramsPrep.isEditor && paramsPrep.isSub"
                    v-for="(action) in customActions"
                    :to="action.url(item.id)"
                    class="list--item--edit"
                >
                    {{action.title}}<span class="icon--wrapper"></span>
                </router-link>

                <a
                    @click.stop="handleClick(item.id,paramsPrep.isSub ? 'edit-sub' : 'edit')"
                    v-if="paramsPrep.isEditor"
                    href="javascript:void(0);"
                    class="list--item--edit">
                    Изменить<span class="icon--wrapper"></span>
                </a>

                <a @click.stop="handleClick(item.id, paramsPrep.isSub ? 'del-sub' : 'del')"
                   v-if="paramsPrep.isEditor"
                   href="javascript:void(0);"
                   class="list--item--edit"
                   style="margin-left: 0">
                    <span class="icon--wrapper"><span class="icon delete"></span></span>
                </a>

                <span
                    v-if="typeof item.parser !== 'undefined' && item.parser.name === 'badge' && item.parser.type === 'count'"
                    class="badge">
					{{ item.parser.item.title }}
				</span>

                <Icon v-if="item.iconRight" :icon="item.iconRight" wrapper-class="right"/>
            </label>

            <List
                v-if="item.children.length"
                :params="{'class': `${getSubListClass(item.children)}`,'type':'checkbox','isSub': true,isEditor:paramsPrep.isEditor}"
                :items="item.children"
                :icon-item-attributes="{type:'element'}"
                @click="handleClick"
                @icon-click="handleClick"
                @delete="handleClick"
                :customActions="customActions"
            />
        </div>
    </template>
</div>
