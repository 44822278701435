<template src="./templates/radio.html"></template>
<script>

import Help from '@c/Help';

const default_values = {
    id: null,
    type: 'radio',
    class: ''
};

export default {
    name: 'FormRadio',
    components: {
        Help,
    },
    props: {
        title: {
            type: String,
            default() {
                return '';
            }
        },
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        name: {
            type: String,
            default() {
                return null;
            }
        },
        value: {
            type: [String, Number],
            default() {
                return null;
            }
        },
        modelValue: {
            type: [Number, String, Boolean],
            default() {
                return 0;
            }
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return null;
            }
        },
        wrapperClass: {
            type: String,
            default() {
                return '';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        helpText: {
            type: String,
            default() {
                return '';
            },
        },
    },
    emits: ['update:modelValue'],
    computed: {
        isChecked() {

            let checked;
            if (this.value) {
                checked = this.value === this.modelValue;
            } else {
                checked = !!this.modelValue;
            }

            return checked;
        },
        wrapperClassPrep() {

            let css_classes = this.wrapperClass;

            if (typeof css_classes === 'string') {
                css_classes = css_classes.split(' ');
            }

            css_classes.push('form-check');
            css_classes = css_classes.join(' ');

            return css_classes;
        },
        attributesPrep() {
            const attrs = {
                ...default_values,
                ...this.attributes,
                ...(this.name ? {name: this.name} : {}),
                ...(this.value ? {value: this.value} : {}),
                ...(this.disabled ? {disabled: true} : {}),
            };

            if (attrs.checked) {
                attrs.checked = 'checked="checked"';
            }

            if (!attrs.id) {
                attrs.id = `form-checkbox-${Math.floor(Math.random() * 100000)}`;
            }
            if (!attrs.name) {
                attrs.name = `form-check-${attrs.id}`;
            }

            let css_classes = attrs.class;

            if (typeof css_classes === 'string') {
                css_classes = css_classes.split(' ');
            }

            css_classes.push('hide');
            css_classes = css_classes.join(' ');

            attrs.class = css_classes;

            return attrs;
        }
    },
    methods: {
        changeToParent(event) {

            let value;
            if (this.value) {
                value = this.value;
            } else if (event.target.checked) {
                value = 1;
            } else {
                value = 0;
            }

            this.$emit('update:modelValue', value);
        }
    }

};
</script>
