<template v-if="promptPartners" v-for="(filter, fk) in promptPartners.filters">
    <div v-if="filter.ids.length > 0" class="mb-1">
        <div><u>{{filter.title}}:</u></div>
        <div v-for="(partner, i) in filterPartners(filter.ids)">
            <tippy
                :key="`promptPartners-${fk}-${partner.id}`"
                :content="generateInfo(partner)"
                allowHTML
                arrow
                theme="big"
            >
                <FormButton
                    :label="getPartnerNameWithArea(partner)"
                    @click="onSelectPartner(partner.id)"
                    :attributes="{class: 'text-blue btn-link no-bg'}"
                />
            </tippy>
        </div>

    </div>
</template>
