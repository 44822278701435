<template src="../../templates/partner-cart-technology-park-recommended.html"></template>

<script>
import Error from '@c/Error';
import Tabs from '@c/Tabs';
import {getEstimatedModelLines} from '@api/technology-park';
import Loader from '@c/Loader';
import TechnologyParkEstimated from './technology-park-estimated';

export default {
    name: 'TechnologyParkRecommended',
    components: {
        Error,
        Tabs,
        TechnologyParkEstimated,
        Loader,
    },
    props: {
        mode: { // display | edit | add
            type: String,
            required: true,
            default() {
                return 'display';
            }
        },
        partnerId: {
            type: [Number, String],
            required: true,
            default() {
                return null;
            },
        },
        technologyPark: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    emits: ['add'],
    data() {
        return {
            estimatedModelLines: [],
            errors: {},
            loading: false,
        };
    },
    computed: {
        columns() {
            const tabs = [];
            if (this.estimatedModelLines.length) {
                tabs.push({
                    id: 'estimatedPark',
                    label: 'Расчетный мехдвор',
                    slot_name: 'estimatedPark',
                });
            }
            return tabs;
        },
        filteredEstimatedModelLines() {
            // Модельные ряды, которых ещё нет в парке техники
            return this.estimatedModelLines.filter(
                modelLine => !this.technologyPark.find(parkItem => parkItem.modelLineId === modelLine.id)
            );
        }
    },
    async mounted() {
        if (this.partnerId) {
            this.loading = true;
            await getEstimatedModelLines(
                this.partnerId,
                response => {
                    this.estimatedModelLines = response;
                },
                response => {
                    this.errors = response;
                }
            )
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    methods: {
        add({newItems, additionalData}) {
            this.$emit('add', {newItems, additionalData});
        },
    },
};
</script>
