<template src="../templates/condition-property.html"></template>
<script>

import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import {catalogs} from '@c/ConditionEditor/config';
import VariableTreeSelectDialog from '@c/Scenarios/VariableTreeSelectDialog';
import ProductFeatureSelectDialog from '@c/Scenarios/ProductFeatureSelectDialog';
import ConditionValue from './condition-value';

export default {
    name: 'ConditionProperty',
    components: {
        FormButton,
        FormInput,
        FormSelect,
        ConditionValue,
        VariableTreeSelectDialog,
        ProductFeatureSelectDialog,
    },
    inject: ['conditionPropertySource'],
    props: {
        property: {
            type: Object,
            default() {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    emits: ['update:property'],

    data() {
        return {
            source: this.conditionPropertySource, // injected
            catalogs: {...catalogs},
            propertyRaw: null,
            propertyRawId: null,
            showPropertyModal: false,
        };
    },
    computed: {

    },
    watch: {
        propertyRaw: {
            handler(val, oldVal) {
                //TODO: сделать форматтер или изменить структуру
                if (JSON.stringify(val) !== JSON.stringify(oldVal) && !this.disabled) {
                    const property = {
                        key: `${this.source}-${val.id}`,
                        title: val.name,
                        type: val.value_type,
                        options: val.values.length
                            ? val.values.map(v => ({title: v.name, value: v.value}))
                            : null,
                    };

                    this.$emit('update:property', property);
                }

            },
            deep: true,
        },
    },
    methods: {
        propertyClickHandler() {
            this.showPropertyModal = !this.disabled && true;
        },
    },
};

</script>
