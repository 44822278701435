<div class="row-col">
    <Loader v-if="loading" :label="`Получаем расчетный парк техники...`" />

    <Tabs :columns="columns" v-if="filteredEstimatedModelLines.length">
        <template v-slot:estimatedPark="row">
            <TechnologyParkEstimated
                :model-lines="filteredEstimatedModelLines"
                :mode="mode"
                @add="add"
            />
        </template>
    </Tabs>

    <div>
        <Error v-if="errors.alert" :message="errors.alert" box-class="mh-2" />
        <Error v-if="errors.system" :message="errors.system" box-class="mh-2" />
    </div>
</div>
