/* eslint-disable */
// временно задизэйблил линтер на этом файле. нужно разбираться, почему вдруг столько ошибок


// eslint-disable-next-line import/no-cycle
import store from '@store';
// eslint-disable-next-line no-unused-vars
import Response from '../entities/ws_response';

const error = {
    OK: 200,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SERVICE_UNAVAILABLE: 503,
};

// eslint-disable-next-line no-unused-vars
const WS_CONNECT_ATTEMPTS_LIMIT = 1;


class Api {

    /**
     * Очередь на отправку на сервер
     * @type {*[]}
     */
    #request_queue = [];

    #preloaderId = null;

    #useAjax=false;

    /**
     * Функции для обработки кэлбэков
     * @type {{}}
     */
    #response_handlers = {};

    constructor() {

        const work = () => {

            this.#worker();
        };

        clearInterval(this.#worker_interval);

        console.log('Init worker...');
        this.#worker_interval = setInterval(work, 100);
    }

    #worker_interval = null;

    #worker() {

        // Коннект есть, начинаем обрабатывать экшены
        if (this.#checkConnect()) {

            if (this.#useAjax) {
                this.#useAjax = false;
                console.log('CRM AJAX transport is disable.');
            }

            if (this.#request_queue.length > 0) {

                let process_item = this.#request_queue.shift();
                if(process_item) {
                    this.#response_handlers[process_item['uuid']] = {
                        callback: process_item['callback'],
                        callback_error: process_item['callback_error'],
                        preloader_id: process_item['preloader_id'],
                    };

                    this.#connection.send(JSON.stringify(process_item['data']));
                }
            }
        } else {
            if (this.#reconnection_status.attempts > WS_CONNECT_ATTEMPTS_LIMIT || this.#useAjax) {

                if (!this.#useAjax) {
                    console.log('Start CRM AJAX transport...');
                    this.#useAjax = true;
                }

                let process_item = this.#request_queue.shift();

                if(process_item) {

                    const options = {...process_item.data.options};
                    delete process_item.data.options;

                    const va = document.getElementById('vue-access');
					this.ajax(
						`/application/api/${process_item.data.subject}/${process_item.data.action}`,
						'POST',
                        process_item.data,
						{...options, ...{
                            'vue-access': va.value
						}}
					)
                        .then(response => {
                            switch (response.status) {

                                case Response.STATUS_SUCCESS:

                                    process_item.callback(response.data, response);

                                    store.dispatch('updatePreloader', {
                                        name: process_item.preloader_id,
                                        status: false
                                    });

                                    break;

                                case Response.STATUS_ERROR:

                                    process_item.callback_error(response.data, response);
                                    this.errorHandler(response);

                                    break;

                                default:

                                    console.error(
                                        'Error 10445: Undefined ajax response status'
                                    );
                                    console.log(
                                        response
                                    );
                            }


                        })
                        .catch(
                            e => {
                                console.warn('Error 10760: CRM AJAX error:')
                                console.warn(e)
                            }
                        );
                }
            }
        }

        //region WS Connect
        if (
            !this.#checkConnect() // нет коннекта
            && !this.#reconnection_status.connect // В данный момент не соединяемся
            && ( // После последней попытки не прошло достаточно времени
                !this.#settings
                || (this.#reconnection_status.delay + parseInt(this.#settings.reconnect_delay)) < Date.now()
            )
        ) {

            if (
                !this.#settings
                || (
                    // Если 0, то будет пытаться подключиться постоянно
                    parseInt(this.#reconnection_status.attempts) === 0
                    || this.#reconnection_status.attempts < parseInt(this.#settings.reconnection_attempts)
                )
            ) {

                this.#reconnection_status.delay = Date.now();
                this.#reconnection_status.connect = true;

                console.log('WS Connect attempt: ' + (this.#reconnection_status.attempts + 1) + '...');
                this.#reconnection_status.attempts++
                this.#connect();

            } else {

                clearInterval(this.#worker_interval);
                console.error('Error 6440: Невозможно подключиться к WS серверу...');
            }
        }
        //endregion
    }

    //region WS interactions
    /**
     * @type {WebSocket}
     */
    #connection = null;

    #reconnection_status = {
        connect: false,
        attempts: 0,
        delay: 0,
    };

    #checkConnect() {

        return this.#connection && this.#connection.readyState === 1;
    }

    #connect() {

        if (!this.#settings) {
            this.#updateSettings()
                .then((response) => {
                    this.#settings = response.data.WS;
                    this.#openWS();
                })
        } else {
            this.#openWS();
        }
    }

    #openWS() {

        let set = this.#settings;
        let connectionString = `ws://${set.host}:${set.port}`;

        if (set.wss === 'true') {
            connectionString = `wss://${set.host}/wss2/`;
        }

        this.#connection = new WebSocket(connectionString);
        this.#connection.onopen = () => {

            this.#connectionOpen();
        };
        this.#connection.onmessage = (e) => {
            this.#requestProcessing(e);
        };
        this.#connection.onerror = (e) => {

            this.connectionError(e);
        };
        this.#connection.onclose = (e) => {

            this.#connection = null;
            this.#reconnection_status.connect = false;
        };
    }

    // глобальные хэндлеры. будут срабатывать для каждого запроса
    // т.к. объект API у нас один
    successHandler = () => {

    }

    errorHandler = ({error_id, data}) => {
        const errors = [error.FORBIDDEN, error.NOT_FOUND];

        if (errors.includes(error_id)) {
            app.$router.push('/404');
        } else {
            let response = data;
            try {
                response = JSON.parse(data);
            } catch (e) {
                if (!(e instanceof SyntaxError)) {
                    throw e;
                }
            }

            let alertStr = '';
            if (typeof response === 'object' && response.constructor === Object) {
                for (const prop in response )
                alertStr = `${alertStr}\n${prop}: ${response[prop]}`;
            } else {
                alertStr = response;
            }

            alert(alertStr)
            console.error('Default error handler: ', alertStr);
        }
    }

    #requestProcessing(event) {

        const response = new Response(JSON.parse(event.data));

        if (typeof this.#response_handlers[response.uuid] !== "undefined") {

            switch (response.status) {

                case Response.STATUS_SUCCESS:

                    this.#response_handlers[response.uuid].callback(response.data, response);
                    this.successHandler(response);

                    store.dispatch('updatePreloader', {
                        name: this.#response_handlers[response.uuid].preloader_id,
                        status: false
                    });

                    break;

                case Response.STATUS_ERROR:

                    this.#response_handlers[response.uuid].callback_error(response.data, response);
                    this.errorHandler(response);

                    break;

                default:

                    console.error('Error 15045: Undefined response status', response);
            }

        } else {

            console.error('Error 13837: Undefined response handler...', response);
        }
    }

    #connectionOpen() {

        console.log('WS connected...');

        this.#reconnection_status.attempts = 0;
        this.#reconnection_status.delay = 0;
        this.#reconnection_status.connect = false;
    }

    #connectionError() {
        this.#reconnection_status.connect = false;
    }

    //endregion

    //region Settings interactions
    #settings = null;

    #updateSettings() {

        const va = document.getElementById('vue-access');

        return this.ajax(
            '/application/get-settings',
            'POST',
            {},
            {
                'vue-access': va.value
            }
        );
    }

    //endregion

    uploadImage(blob, path) {
        const va = document.getElementById('vue-access');

        return new Promise((resolve, reject = () => {
        }) => {
            const xhttp = new XMLHttpRequest();
            xhttp.open('POST', `${process.env.VUE_APP_API_URL}/application/upload-image`, true);
            // xhttp.setRequestHeader('Content-Type', 'application/json');
            xhttp.setRequestHeader('vue-access', va.value);

            xhttp.withCredentials = true;
            xhttp.responseType = 'json';
            xhttp.onload = () => {
                resolve(xhttp.response);
            };
            xhttp.onerror = e => {
                console.error('Error xhttp:');
                reject(e);
            };

            const fd = new FormData();
            fd.append('file', blob.blob());
            fd.append('url', process.env.VUE_APP_HOST_URL);
            fd.append('path', path);
            xhttp.send(fd);
        });
    }

    request(
        subject,
        action,
        data = {},
        options = {},
        callbacks = {}
    ) {
        const preloaderId = `${subject}_${action}`;
        store.dispatch('updatePreloader', {name: preloaderId, status: true});

        return new Promise((resolve, reject) => {

            const uuid = `${Date.now()}_${Math.floor(Math.random() * 10000)}`;
            const userKey = window.localStorage.getItem('user-key');

            this.#request_queue.push(
                {
                    uuid: uuid,
                    data: {
                        subject: subject,
                        action: action,
                        data: data,
                        options: {...{'user-key': userKey}, ...options},
                        uuid: uuid,
                    },
                    callback: (typeof callbacks.resolve !== 'undefined') ? (responseData, response) => {
                        callbacks.resolve(responseData, response);
                        resolve(responseData, response);
                    } : resolve,
                    callback_error: (typeof callbacks.reject !== 'undefined') ? (responseData, response) => {
                        callbacks.reject(responseData, response);
                        reject(responseData, response);
                    } : reject,
                    preloader_id: preloaderId
                }
            );
        });
    }

    ajax(url, method = 'GET', data = {}, headers = {}) {

        return new Promise((resolve, reject = () => {
        }) => {

            const xhttp = new XMLHttpRequest();
            xhttp.open(method, process.env.VUE_APP_API_URL + url, true);
            xhttp.setRequestHeader('Content-Type', 'application/json');
            Object.keys(headers).forEach(name => {
                xhttp.setRequestHeader(name, headers[name]);
            });
            xhttp.withCredentials = true;
            xhttp.responseType = 'json';
            xhttp.onload = () => {
                resolve(xhttp.response);
            };
            xhttp.onerror = e => {

                console.error('Error xhttp:');
                reject(e);
            };
            xhttp.send(JSON.stringify(data));
        });
    }
}

export {Api as ApiClass, error};
export default new Api();
