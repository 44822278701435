// eslint-disable-next-line import/no-cycle
import api from '../tools/api';

export const getBranches = () => api.request(
    'layout',
    'get-branches'
);

export const getLeftMenu = () => api.request(
    'layout',
    'get-left-menu'
);
