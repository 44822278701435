<div>
    <div class="border-dashed mb-2 p-2">
        <Loader v-if="!classifier.length" />

        <Error v-if="classifier.length && !classifierData.length" message="Классификатор не заполнен." />
        <template v-else>
            <div v-if="lastUpdateDate !== null" class="mb-2">
                <div class="partner-title">Дата последнего обновления: {{ lastUpdateDate }}</div>
            </div>
        </template>

        <Grid
            v-if="classifierData.length"
            tree-grid
            id-field="itemId"
            :columns="columns"
            :data="classifierData"
            :onEdit="undefined"
            :onRemove="undefined"
            :formatters="{
                updatedAt: updatedAt => (updatedAt) ? unixTimestampToLocaleDate(updatedAt) : ''
            }"
        >
            <template #value="{ data: item }">
                <template v-if="this.mode === 'display'">
                    {{ getValue(item) }}
                    <span v-if="item.value !== null" v-tippy :content="getValueInfo(item)">
                        <span class="icon info mr-0 ml-1 inline"></span>
                    </span>
                </template>
                <template v-if="mode !== 'display' && item.isEditable">
                    <FormCheckbox
                        v-if="item.valueType === constants.BOOL_TYPE"
                        v-model="item.value"
                        @change="updateClassifier(item)"
                    />
                    <FormInput
                        v-else
                        type="number"
                        v-model="item.value"
                        :attributes="{
                            min: 0,
                            step: 1,
                        }"
                        @change="updateClassifier(item)"
                    />
                </template>
            </template>
        </Grid>
    </div>
</div>
