<div>
    <tree :nodes="variableTree" v-model="changeCategory" :filter="filterText">
        <template v-slot:label="row">
            <slot name="variableName" :data="row.data">
                <template v-if="parseInt(row.data.removed, 10) === 0">
                    {{row.data.name}}
                </template>
                <template v-else>
                    <s>{{row.data.name}}</s>
                </template>
            </slot>
            <slot name="line" :data="row.data"></slot>
        </template>
    </tree>
</div>
