<template>
    <i
        class="icon help-circle cursor-help"
        :content="text" v-tippy="{trigger: 'click'}"
        @click.prevent.stop
    />
</template>

<script>
export default {
    name: 'Help',
    props: {
        text: {
            type: String,
            default: '',
            required: true,
        },
    }
};
</script>
