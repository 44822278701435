<template src="../../templates/partner-cart-scoring-value.html"></template>
<script>

export default {
    name: 'ScoringValue',
    props: {
        name: {
            type: String,
            default() {
                return 'Не определено';
            }
        },
        value: {
            type: [String, Number, Object, Boolean, Array],
            default() {
                return null;
            }
        },
        code: {
            type: String,
            default() {
                return null;
            }
        },
        formatValue: {
            type: Boolean,
            default() {
                return true;
            }
        },
        type: {
            type: String,
            default() {
                return 'string';
            }
        }
    },
    data() {
        return {
            fields: {
                a1002: [
                    'regionName.topoValue',
                    'regionName.topoFullName',
                    'district.topoValue',
                    'district.topoFullName',
                    'settlement.topoFullName',
                    'settlement.topoValue',
                    'street.topoFullName',
                    'street.topoValue',
                    'house.topoFullName',
                    'house.topoValue',
                    'zipCode'
                ],
                // a1024: {
                //     fio: 'ФИО',
                //     innfl: 'ИНН',
                //     position: 'Должность'
                // },
                // c1075: {
                //     fio: 'ФИО',
                //     innfl: 'ИНН',
                //     'share.sum': 'Сумма доли компании',
                //     'share.percentagePlain': 'Доля компании'
                // }
            }
        };
    },
    computed: {
        getFormattedValue() {
            const {value, type} = this;

            if (value) {
                if (type === 'number') {
                    return value.toLocaleString();
                }

                if (type === 'boolean') {
                    return value ? 'Да' : 'Нет';
                }

                if (value === 'false') {
                    return 'Нет';
                }

                if (['object', 'array'].includes(type)) {
                    return 'Данные будут выведены в ближайшее время...';
                }
            }

            return value || 'Нет данных';
        }
    },
    methods: {}
};
</script>
